// Owner: Aaron Stubbs

export const Team__Thunder = {
  name: "Thunder",
  logoImage: null,
  teamImage: null,
  teamLogoColor: "#253F6D",
  teamJerseyColor: "#253F6D",

  // Stats
  gamesWon: 2,
  gamesLost: 2,
  gamesTied: 0,
  gamesTiedWon: 0,

  ownGoalsInFavor: 0,
  // goalsScoredAgainst: 29 + 38 + 40 + 31,
  goalsScoredAgainst: 43 + 29,
  defaultGoals: 0,

  isChampion: false,
  isEliminated: false,

  players: [
    {
      // goalsScored: 50 + 33 + 27 + 40,
      goalsScored: 27 + 40,
    },
  ],
};
