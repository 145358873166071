import { Team__Nogaleras } from "../teams/Nogaleras";
import { Team__NogalerasJR } from "../teams/NogalerasJR";
import { Team__Panthers } from "../teams/Panthers";
import { Team__Raptors } from "../teams/Raptors";

const Panthers = {
  team: Team__Panthers,
  playingPlayers: [],

  goals: 18,
  penaltyGoals: 0,
  isWinner: false,
};

const NogalerasJR = {
  team: Team__NogalerasJR,
  playingPlayers: [],

  goals: 35,
  penaltyGoals: 0,
  isWinner: false,
};

const Raptors = {
  team: Team__Raptors,
  playingPlayers: [],

  goals: 16,
  penaltyGoals: 0,
  isWinner: false,
};

const Nogaleras = {
  team: Team__Nogaleras,
  playingPlayers: [],

  goals: 27,
  penaltyGoals: 0,
  isWinner: false,
};

export const Jornada3 = {
  jornadaPosition: 3,
  matches: [
    {
      team1: Nogaleras,
      team2: Raptors,

      isCompleted: true,
      isPlaying: false,
      day: "Miercoles",
      date: "2024-07-31",
      time: "17:00:00",
      location: "Centro Recreativo Lebaron",
    },
    {
      team1: Panthers,
      team2: Nogaleras,

      isCompleted: true,
      isPlaying: false,
      day: "Miercoles",
      date: "2024-07-31",
      time: "19:00:00",
      location: "Centro Recreativo Lebaron",
    },
    {
      team1: Raptors,
      team2: NogalerasJR,

      isCompleted: true,
      isPlaying: false,
      day: "Miercoles",
      date: "2024-07-31",
      time: "20:00:00",
      location: "Centro Recreativo Lebaron",
    },
  ],
};
