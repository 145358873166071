import { Team__Leonas } from "../teams/Leonas";
import { Team__Pumbas } from "../teams/Pumbas";
import { Team__SuperNovas } from "../teams/SuperNovas";
import { Team__Valleras } from "../teams/Valleras";
import { Team__Vaqueras } from "../teams/Vaqueras";

const Leonas = {
  team: Team__Leonas,
  playingPlayers: [
    {
      player: {
        firstName: "Johana",
        lastName: "Murillo",
        nickName: null,
        yearBorn: 2002,
        number: null,
      },

      goals: 1,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Julissa",
        lastName: "Arreola",
        nickName: null,
        yearBorn: 2007,
        number: null,
      },

      goals: 1,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Reyna",
        lastName: "Macario",
        nickName: null,
        yearBorn: 1990,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Citlali",
        lastName: "Hernández",
        nickName: null,
        yearBorn: 2007,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Jaqueline",
        lastName: "Arreola",
        nickName: "Jacky",
        yearBorn: 2010,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Julia",
        lastName: "Carbajal", // Romero
        nickName: null,
        yearBorn: 2007,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Miranda",
        lastName: "Rodríguez",
        nickName: null,
        yearBorn: 2011,
        number: null,
      },

      goals: 0,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 2,
  penaltyGoals: 1,
  isWinner: false,
};

const Pumbas = {
  team: Team__Pumbas,
  playingPlayers: [
    {
      player: {
        firstName: null,
        lastName: null,
        nickName: null,
        image: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 0,
  penaltyGoals: 0,
  isWinner: false,
};

const SuperNovas = {
  team: Team__SuperNovas,
  playingPlayers: [
    {
      player: {
        firstName: "Diana",
        lastName: "Gonzalez",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Yadira",
        lastName: "Ontiveros",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 2,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Idania",
        lastName: "Palacio",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Lupita",
        lastName: "Ontiveros",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Brenda",
        lastName: "Acevedo",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Minerva",
        lastName: "Macias",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Camila",
        lastName: "Erives",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 2,
  penaltyGoals: 0,
  isWinner: false,
};

const Valleras = {
  team: Team__Valleras,
  playingPlayers: [
    {
      player: {
        firstName: "Claudia",
        lastName: "Marquez",
        nickName: null,
        yearBorn: 1984,
        number: 9,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Marce",
        lastName: "Chalaca",
        nickName: null,
        yearBorn: null,
        number: 19,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Adriana",
        lastName: "Avaloz",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 2,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Leslie",
        lastName: "Vega", // Ochoa
        nickName: null,
        yearBorn: null,
        number: 11,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Luisa", // Fernanda
        lastName: "Salas",
        nickName: null,
        yearBorn: 2006,
        number: 20,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Maricela",
        lastName: "Rodríguez",
        nickName: null,
        yearBorn: 1987,
        number: 13,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Anaricka",
        lastName: "Delgado",
        nickName: null,
        yearBorn: 1989,
        number: 4,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Miriam",
        lastName: "Serrano",
        nickName: null,
        yearBorn: null,
        number: 1,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Idali",
        lastName: "Córdova",
        nickName: null,
        yearBorn: null,
        number: 7,
      },

      goals: 0,
      fouls_commited: 2,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Alexa",
        lastName: "Quezada",
        nickName: null,
        yearBorn: 2007,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 2,
  penaltyGoals: 0,
  isWinner: false,
};

const Vaqueras = {
  team: Team__Vaqueras,
  playingPlayers: [
    {
      player: {
        firstName: "Mayte",
        lastName: "Xalate",
        nickName: null,
        yearBorn: 2009,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Iris",
        lastName: "Macario",
        nickName: null,
        yearBorn: 2008,
        number: null,
      },

      goals: 1,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Elisa",
        lastName: "Erives",
        nickName: null,
        yearBorn: 2008,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Lucy",
        lastName: "Xalate",
        nickName: null,
        yearBorn: 2007,
        number: null,
      },

      goals: 1,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Gabriela",
        lastName: "Rosario",
        nickName: "Gaby",
        yearBorn: 1991,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Fernanda",
        lastName: "Núñez",
        nickName: null,
        yearBorn: 2008,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Fatima",
        lastName: "Miramontes",
        nickName: null,
        yearBorn: 2009,
        number: null,
      },

      goals: 3,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Elena",
        lastName: "Lujan",
        nickName: null,
        yearBorn: 2010,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 5,
  penaltyGoals: 0,
  isWinner: false,
};

export const Jornada4 = {
  jornadaPosition: 4,
  matches: [
    {
      team1: SuperNovas,
      team2: Leonas,

      isCompleted: true,
      isPlaying: false,
      day: "Martes",
      date: "2024-07-30",
      time: "20:00:00",
      location: "Cancha Gerardo Gonzales",
    },
    {
      team1: Valleras,
      team2: Vaqueras,

      isCompleted: true,
      isPlaying: false,
      day: "Miercoles",
      date: "2024-07-31",
      time: "20:00:00",
      location: "Cancha Gerardo Gonzales",
    },
  ],
};
