import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// SCSS
import "../../styles/components/tables/TeamRankingsTable.scss";
import TeamLogoIcon from "../TeamLogoIcon";

export function New_TeamRankingsTable({ tournament, teams, loading }) {
  const [activeStats, setActiveStats] = useState("Points");

  const renderSkeletonAmount = [1, 2, 3, 4, 5, 6, 7, 8];

  const winPoints = tournament.pointsPerWin;
  const drawWinPoints = tournament.pointsPerTieWin;
  const drawPoints = tournament.pointsPerTie;
  const lossPoints = tournament.pointPerLoss;

  const sortedTeams = teams.sort(
    (a, b) => b.gamesWon * winPoints - a.gamesWon * winPoints
  );

  const totalTeams = sortedTeams.length;
  const teamsToHighlight = sortedTeams.slice(
    totalTeams - tournament.teamsToBeEliminated
  );

  const handleActiveCategory = (category) => {
    if (category === activeStats) {
      return "activeCategory";
    }
  };

  const handleCategoryClicked = (category) => {
    setActiveStats(category);
  };

  return (
    <>
      <div
        className={`teamRankingsTable teamRankingsTable__${tournament.pageColorVersion}`}
      >
        <div className="tableContainer">
          <table className="newTable">
            <thead>
              <th
                className={`teamTableHeader ${handleActiveCategory(
                  "Alpabetical"
                )}`}
                onClick={() => handleCategoryClicked("Alpabetical")}
              >
                Equipos
              </th>
              <th
                className={handleActiveCategory("Matches Played")}
                onClick={() => handleCategoryClicked("Matches Played")}
              >
                Partidos
                <div>Jugados</div>
              </th>
              <th
                className={handleActiveCategory("Points")}
                onClick={() => handleCategoryClicked("Points")}
              >
                Puntos
              </th>
              <th
                className={handleActiveCategory("Wins")}
                onClick={() => handleCategoryClicked("Wins")}
              >
                Ganados
              </th>
              <th
                className={handleActiveCategory("Draws")}
                onClick={() => handleCategoryClicked("Draws")}
              >
                Empates
              </th>
              <th
                className={handleActiveCategory("Loses")}
                onClick={() => handleCategoryClicked("Loses")}
              >
                Perdidas
              </th>

              <th
                className={handleActiveCategory("Goal Dif")}
                onClick={() => handleCategoryClicked("Goal Dif")}
              >
                <div>Dif.</div>
                Goles
              </th>
            </thead>
            {loading ? (
              <tbody className="statsSection statsSection__skeleton">
                {renderSkeletonAmount.map((_, index) => {
                  return (
                    <tr key={index} className={`tableTeamRow`}>
                      <td className="tableBox-team">
                        <div className="teamLink">
                          <span className="teamPlacement">{index + 1}</span>
                          <div className="teamLogoIcon__skeleton"></div>
                          <span className="teamName"></span>
                        </div>
                      </td>
                      <td className="tablebox-numbers tableBox-matchesPlayed">
                        <div className="skeletonTextPlaceholder"></div>
                      </td>
                      <td className="tablebox-numbers tableBox-points">
                        <div className="skeletonTextPlaceholder"></div>
                      </td>
                      <td className="tablebox-numbers tableBox-winsOrLoses">
                        <div className="skeletonTextPlaceholder"></div>
                      </td>
                      <td className="tablebox-numbers tableBox-winsOrLoses tableBox-tiedMatches">
                        <div className="skeletonTextPlaceholder"></div>|
                        <div className="skeletonTextPlaceholder"></div>
                      </td>
                      <td className="tablebox-numbers tableBox-winsOrLoses">
                        <div className="skeletonTextPlaceholder"></div>
                      </td>
                      <td className="tablebox-numbers tableBox-goals">
                        <div className="skeletonTextPlaceholder"></div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <tbody className="statsSection">
                {sortedTeams.map((team, index) => {
                  const isHighlighted = teamsToHighlight.includes(team);

                  return (
                    <tr
                      key={team.id}
                      className={`tableTeamRow ${
                        isHighlighted ? "highlightedRow" : ""
                      }`}
                    >
                      <td className="tableBox-team">
                        {isHighlighted && (
                          <div className="eliminationZone"></div>
                        )}

                        <Link
                          to={`/torneo/${tournament.URL_name}/equipos/${team.id}`}
                          className="teamLink"
                        >
                          <span className="teamPlacement">{index + 1}</span>
                          <TeamLogoIcon
                            team={team}
                            iconSize={40}
                            letterSize={25}
                          />
                          <span className="teamName">{team.name}</span>
                        </Link>
                      </td>
                      <td className="tablebox-numbers tableBox-matchesPlayed">
                        {team.gamesWon + team.gamesTied + team.gamesLost}
                      </td>
                      <td className="tablebox-numbers tableBox-points">
                        {team.gamesWon * winPoints +
                          team.gamesWon * drawWinPoints +
                          (team.gamesTied - team.gamesTiedWon * drawPoints)}
                      </td>
                      <td className="tablebox-numbers tableBox-winsOrLoses">
                        {team.gamesWon}
                      </td>
                      <td className="tablebox-numbers tableBox-winsOrLoses">
                        {team.gamesTiedWon} |{" "}
                        {team.gamesTied - team.gamesTiedWon}
                      </td>
                      <td className="tablebox-numbers tableBox-winsOrLoses">
                        {team.gamesLost}
                      </td>
                      <td className="tablebox-numbers tableBox-goals">???</td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </>
  );
}

export function BetaTeamRankingsTable({ tournament, teams }) {
  const [activeStats, setActiveStats] = useState("Points");

  const renderSkeletonAmount = [1, 2, 3, 4, 5, 6, 7, 8];

  const winPoints = tournament.pointsPerWin;
  const drawWinPoints = tournament.pointsPerDrawWin;
  const drawPoints = tournament.pointsPerDraw;
  const lossPoints = tournament.pointPerLoss;

  const sortedTeams = teams.sort((a, b) => {
    const aTotalPoints =
      a.gamesWon * winPoints +
      a.gamesTied * drawPoints +
      a.gamesTiedWon * drawWinPoints;
    const bTotalPoints =
      b.gamesWon * winPoints +
      b.gamesTied * drawPoints +
      b.gamesTiedWon * drawWinPoints;

    const aTotalGoalsScored =
      a.players.reduce(
        (total, player) => total + player.goalsScored + a.ownGoalsInFavor,
        0
      ) +
      a.ownGoalsInFavor +
      a.defaultGoals;

    const bTotalGoalsScored =
      b.players.reduce(
        (total, player) => total + player.goalsScored + b.ownGoalsInFavor,
        0
      ) +
      b.ownGoalsInFavor +
      b.defaultGoals;

    if (bTotalPoints != aTotalPoints) {
      return bTotalPoints - aTotalPoints;
    } else {
      return (
        bTotalGoalsScored -
        b.goalsScoredAgainst -
        (aTotalGoalsScored - a.goalsScoredAgainst)
      );
    }
  });

  const totalTeams = sortedTeams.length;
  const teamsToHighlight = sortedTeams.slice(tournament.teamsToSurviveGroup);

  const handleActiveCategory = (category) => {
    if (category === activeStats) {
      return "activeCategory";
    }
  };

  const handleCategoryClicked = (category) => {
    setActiveStats(category);
  };

  const teamTotalGoals = (team) => {
    const playersTotalGoals = team.players.reduce((total, player) => {
      return total + player.goalsScored;
    }, 0);

    const totalGoals =
      playersTotalGoals +
      team.ownGoalsInFavor +
      (team.defaultGoals ? team.defaultGoals : 0);

    return { totalGoals };
  };

  return (
    <>
      <div
        className={`teamRankingsTable teamRankingsTable__${tournament.pageColorVersion}`}
      >
        <div className="tableContainer">
          <table className="newTable">
            <thead>
              <th
                className={`teamTableHeader ${handleActiveCategory(
                  "Alpabetical"
                )}`}
                onClick={() => handleCategoryClicked("Alpabetical")}
              >
                Equipos
              </th>
              <th
                className={handleActiveCategory("Matches Played")}
                onClick={() => handleCategoryClicked("Matches Played")}
              >
                Partidos
                <div>Jugados</div>
              </th>
              <th
                className={handleActiveCategory("Points")}
                onClick={() => handleCategoryClicked("Points")}
              >
                Puntos
              </th>
              <th
                className={handleActiveCategory("Wins")}
                onClick={() => handleCategoryClicked("Wins")}
              >
                Ganados
              </th>
              <th
                className={handleActiveCategory("Draws")}
                onClick={() => handleCategoryClicked("Draws")}
              >
                Empates
              </th>
              <th
                className={handleActiveCategory("Loses")}
                onClick={() => handleCategoryClicked("Loses")}
              >
                Perdidos
              </th>

              <th
                className={handleActiveCategory("Goal Dif")}
                onClick={() => handleCategoryClicked("Goal Dif")}
              >
                <div>Dif.</div>
                Goles
              </th>
            </thead>

            <tbody className="statsSection">
              {sortedTeams.map((team, index) => {
                const isNotHighlighted = teamsToHighlight.includes(team);

                return (
                  <tr
                    key={team.id}
                    className={`tableTeamRow ${
                      isNotHighlighted ? "highlightedRow" : ""
                    }`}
                  >
                    <td className="tableBox-team">
                      {isNotHighlighted ? null : (
                        <div className="eliminationZone"></div>
                      )}

                      <Link
                        to={`/torneo-beta/${tournament.URL_name}/equipos/${team.name}`}
                        className="teamLink"
                      >
                        <span className="teamPlacement">{index + 1}</span>
                        <TeamLogoIcon
                          team={team}
                          iconSize={40}
                          letterSize={25}
                        />
                        <span className="teamName">{team.name}</span>
                      </Link>
                    </td>
                    <td className="tablebox-numbers tableBox-matchesPlayed">
                      {team.gamesWon + team.gamesTied + team.gamesLost}
                    </td>
                    <td className="tablebox-numbers tableBox-points">
                      {team.gamesWon * winPoints +
                        team.gamesTiedWon * drawWinPoints +
                        team.gamesTied * drawPoints}
                    </td>
                    <td className="tablebox-numbers tableBox-winsOrLoses">
                      {team.gamesWon}
                    </td>
                    <td className="tablebox-numbers tableBox-winsOrLoses">
                      {team.gamesTiedWon} | {team.gamesTied - team.gamesTiedWon}
                    </td>
                    <td className="tablebox-numbers tableBox-winsOrLoses">
                      {team.gamesLost}
                    </td>
                    <td className="tablebox-numbers tableBox-goals">
                      {teamTotalGoals(team).totalGoals -
                        team.goalsScoredAgainst}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export function BetaGroupTable({ tournament, group, index, isBasketball }) {
  const [activeStats, setActiveStats] = useState("points");

  const [sortedTable, setSortedTable] = useState([]);

  const winPoints = tournament.pointsPerWin;
  const drawWinPoints = tournament.pointsPerDrawWin;
  const drawPoints = tournament.pointsPerDraw;
  const lossPoints = tournament.pointPerLoss;

  const sortedTeams = group.sort((a, b) => {
    const aTotalPoints = a.gamesWon * winPoints + a.gamesTied * drawPoints;
    const bTotalPoints = b.gamesWon * winPoints + b.gamesTied * drawPoints;

    const aTotalGoalsScored =
      a.players.reduce(
        (total, player) => total + player.goalsScored + a.ownGoalsInFavor,
        0
      ) +
      a.ownGoalsInFavor +
      a.defaultGoals;

    const bTotalGoalsScored =
      b.players.reduce(
        (total, player) => total + player.goalsScored + b.ownGoalsInFavor,
        0
      ) +
      b.ownGoalsInFavor +
      b.defaultGoals;

    if (bTotalPoints != aTotalPoints) {
      return bTotalPoints - aTotalPoints;
    } else {
      return (
        bTotalGoalsScored -
        b.goalsScoredAgainst -
        (aTotalGoalsScored - a.goalsScoredAgainst)
      );
    }
  });

  useEffect(() => {
    setSortedTable(sortedTeams[0]);
  }, [sortedTeams]);

  const teamsToHighlight = sortedTeams.slice(tournament.teamsToSurviveGroup);

  const handleActiveCategory = (category) => {
    if (category === activeStats) {
      return "activeCategory";
    }
  };

  const handleCategoryClicked = (category) => {
    // setActiveStats(category);
  };

  const teamTotalGoals = (team) => {
    const playersTotalGoals = team.players.reduce((total, player) => {
      return total + player.goalsScored;
    }, 0);

    const totalGoals =
      playersTotalGoals + team.ownGoalsInFavor + team.defaultGoals;

    return { totalGoals };
  };

  return (
    <div className={`groupTable groupTable${tournament.pageColorVersion}`}>
      <div className="tableContainer">
        <div className="tableAndTitle">
          {/* <div className="groupTitle">Group { index + 1}</div> */}
          <div className="groupTitle">
            Grupo {String.fromCharCode(65 + index)}
          </div>

          <table className="newTable">
            <thead>
              <td
                className={`teamTableHeader ${handleActiveCategory(
                  "Alpabetical"
                )}`}
                onClick={() => handleCategoryClicked("Alpabetical")}
              >
                Equipos
              </td>
              <td
                className={handleActiveCategory("Matches Played")}
                onClick={() => handleCategoryClicked("Matches Played")}
              >
                Partidos
                <div>Jugados</div>
              </td>
              {!isBasketball && (
                <td
                  className={handleActiveCategory("Points")}
                  onClick={() => handleCategoryClicked("Points")}
                >
                  Puntos
                </td>
              )}
              <td
                className={handleActiveCategory("Wins")}
                onClick={() => handleCategoryClicked("Wins")}
              >
                Ganados
              </td>
              {!isBasketball && (
                <td
                  className={handleActiveCategory("Draws")}
                  onClick={() => handleCategoryClicked("Draws")}
                >
                  Empates
                </td>
              )}
              <td
                className={handleActiveCategory("Loses")}
                onClick={() => handleCategoryClicked("Loses")}
              >
                Perdidos
              </td>

              <td
                className={handleActiveCategory("Goal Dif")}
                onClick={() => handleCategoryClicked("Goal Dif")}
              >
                {isBasketball ? (
                  <>
                    <div>Dif.</div>
                    Puntos
                  </>
                ) : (
                  <>
                    <div>Dif.</div>
                    Goles
                  </>
                )}
              </td>
            </thead>

            <tbody className="statsSection">
              {sortedTeams.map((team, index) => {
                const isNotHighlighted = teamsToHighlight.includes(team);

                return (
                  <tr
                    key={team.index}
                    className={`tableTeamRow ${
                      isNotHighlighted ? "highlightedRow" : ""
                    }`}
                  >
                    <td className="tableBox-team">
                      {isNotHighlighted ? null : (
                        <div className="eliminationZone"></div>
                      )}

                      <Link
                        to={
                          isBasketball
                            ? ""
                            : `/torneo-beta/${tournament.URL_name}/equipos/${team.name}`
                        }
                        className="teamLink"
                      >
                        <span className="teamPlacement">{index + 1}</span>
                        <TeamLogoIcon
                          team={team}
                          iconSize={40}
                          letterSize={25}
                        />
                        <span className="teamName">{team.name}</span>
                      </Link>
                    </td>
                    <td className="tablebox-numbers tableBox-matchesPlayed">
                      {team.gamesWon + team.gamesTied + team.gamesLost}
                    </td>
                    {!isBasketball && (
                      <td className="tablebox-numbers tableBox-points">
                        {team.gamesWon * winPoints +
                          team.gamesTiedWon * drawWinPoints +
                          (team.gamesTied - team.gamesTiedWon * drawPoints)}
                      </td>
                    )}
                    <td className="tablebox-numbers tableBox-winsOrLoses">
                      {team.gamesWon}
                    </td>
                    {!isBasketball && (
                      <td className="tablebox-numbers tableBox-winsOrLoses">
                        {team.gamesTied}
                      </td>
                    )}
                    <td className="tablebox-numbers tableBox-winsOrLoses">
                      {team.gamesLost}
                    </td>
                    <td className="tablebox-numbers tableBox-goals">
                      {!team.dontShowPointDif && (
                        <>
                          {teamTotalGoals(team).totalGoals -
                            team.goalsScoredAgainst}
                        </>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
