import IMSBall_Thumbnail from "../images/ProductImages/IMS_Ball/IMG_BALL_LONG.png";
import IMSBall_Certificate from "../images/ProductImages/IMS_Ball/IMS_Icon.jpg";
import IMSBall_2 from "../images/ProductImages/IMS_Ball/IMS_AerowSculpt.png";
import IMSBall_Grass from "../images/ProductImages/IMS_Ball/IMS_BALL_ongrass.png";

import YellowPLAcademyBall from "../images/ProductImages/Yellow_PL_Academy_Ball/Yellow_PL_Academy_Ball.png";

import FemaleAcademyBall from "../images/ProductImages/AcademyBall_Female/NikeAcademy_Female.png";
import FemaleAcademyBall_3 from "../images/ProductImages/AcademyBall_Female/NikeAcademy_Female-3.png";
import FemaleAcademyBall_4 from "../images/ProductImages/AcademyBall_Female/NikeAcademy_Female-4.png";

import {
  Nike_Ball_Pump__Black,
  Nike_Ball_Pump__Blue,
  Nike_Ball_Pump__Lime,
  Quest__SafetyWhistle,
  Size_4__Adidas__Messi_Club,
  Size_4__Adidas__UCL_LGE,
  Size_4__Nike_Academy__Circles__Gold,
  Size_4__Nike_Academy__Circles__Pink,
  Size_4__Nike_Academy__Stars,
  Size_4__Nike_Pitch_Training__Green,
  Size_4__Nike_Pitch_Training__Pink,
  Size_5__Nike_Club_Elite,
} from "./imagesImporter";

export const products = [
  {
    name: "Balon Adidas Champions League Londres",
    image: Size_4__Adidas__UCL_LGE.img_1,
    brand: "Adidas",
    size: "Tamaño 4",
    price: 880,

    amount: 1,

    extraImages: [
      {
        img: Size_4__Adidas__UCL_LGE.img_2,
      },
      {
        img: Size_4__Adidas__UCL_LGE.img_3,
      },
    ],
  },

  {
    name: "Bomba de balón esencial Nike - Negro",

    image: Nike_Ball_Pump__Black.img_1,
    brand: "Nike",
    size: "",

    price: 299,

    amount: 1,

    extraImages: [
      {
        img: Nike_Ball_Pump__Black.img_2,
      },
    ],
  },
  {
    name: "Bomba de balón esencial Nike - Azul",

    image: Nike_Ball_Pump__Blue.img_1,
    brand: "Nike",
    size: "",
    price: 299,

    amount: 1,

    extraImages: [],
  },

  {
    name: "Balon Nike Pitch Training - Volt",

    image: Size_4__Nike_Pitch_Training__Green.img_1,
    brand: "Nike",
    size: "Tamaño 4",
    price: 399,

    amount: 1,

    extraImages: [],
  },

  {
    name: "Balon Nike Academy 2023-2024 - Rosa",

    image: Size_4__Nike_Academy__Circles__Pink.img_1,
    brand: "Nike",
    size: "Tamaño 4",
    price: 720,

    amount: 1,

    extraImages: [
      {
        img: Size_4__Nike_Academy__Circles__Pink.img_2,
      },
      {
        img: Size_4__Nike_Academy__Circles__Pink.img_3,
      },
      {
        img: Size_4__Nike_Academy__Circles__Pink.img_4,
      },
    ],
  },
  {
    name: "Balon Nike Pitch Training - Rosa",

    image: Size_4__Nike_Pitch_Training__Pink.img_1,
    brand: "Nike",
    size: "Tamaño 4",
    price: 399,

    amount: 1,

    extraImages: [
      {
        img: Size_4__Nike_Pitch_Training__Pink.img_2,
      },
    ],
  },
  {
    name: "Balon Messi Club",
    image: Size_4__Adidas__Messi_Club.img_1,
    brand: "Adidas",
    size: "Tamaño 4",
    price: 680,

    amount: 1,

    extraImages: [
      {
        img: Size_4__Adidas__Messi_Club.img_2,
      },
      {
        img: Size_4__Adidas__Messi_Club.img_3,
      },
      {
        img: Size_4__Adidas__Messi_Club.img_4,
      },
    ],
  },
  {
    name: "Silbato con cordón",

    image: Quest__SafetyWhistle.img_1,
    brand: "Quest",
    size: "",
    price: 60,

    amount: 3,

    extraImages: [],
  },
  {
    name: "Balon Nike Academy 2023-2024 - Morado",

    image: Size_4__Nike_Academy__Stars.img_1,
    brand: "Nike",
    size: "Tamaño 4",
    price: 820,

    amount: 0,

    extraImages: [
      {
        img: Size_4__Nike_Academy__Stars.img_2,
      },
    ],
  },
];
