// Owner: Aaron Stubbs

export const Team__NogalerasJR = {
  name: "Nogaleras JR",
  logoImage: null,
  teamImage: null,
  teamLogoColor: null,
  teamJerseyColor: "#248121",

  // Stats
  gamesWon: 1,
  gamesLost: 2,
  gamesTied: 0,
  gamesTiedWon: 0,

  ownGoalsInFavor: 0,
  goalsScoredAgainst: 32 + 34 + 16,
  defaultGoals: 0,

  isChampion: false,
  isEliminated: false,

  players: [
    {
      goalsScored: 27 + 22 + 35,
    },
  ],
};
