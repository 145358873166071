import React from "react";
import { Link } from "react-router-dom";

// scss
import "../../../styles/components/NavigationComponents/Tournament/MobileFooter.scss";

function MobileFooter({ tournament, currentPageUrl }) {
  return (
    <div
      className={`tournamentMobileFooter tournamentMobileFooter__${tournament.pageColorVersion} `}
    >
      <Link
        to={`/torneo/${tournament.URL_name}/`}
        className={currentPageUrl("/")}
      >
        <span class="material-symbols-rounded">home</span>
        <div className="linkText">Principal</div>
      </Link>
      <Link
        to={`/torneo/${tournament.URL_name}/equipos`}
        className={currentPageUrl("/equipos")}
      >
        {/* <span class="material-symbols-rounded">groups</span> */}
        <span class="material-symbols-rounded">group</span>

        <div className="linkText">Equipos</div>
      </Link>
      <Link
        to={`/torneo/${tournament.URL_name}/jornadas`}
        className={currentPageUrl("/jornadas")}
      >
        {/* <i className="fa-solid fa-calendar-days"></i> */}
        <span class="material-symbols-rounded">calendar_month</span>

        <div className="linkText">Jornadas</div>
      </Link>
      <Link
        to={`/torneo/${tournament.URL_name}/estadisticas`}
        className={currentPageUrl("/estadisticas")}
      >
        {/* <i className="fa-solid fa-chart-column"></i> */}

        <span class="material-symbols-rounded">insert_chart</span>

        <div className="linkText">Estadisticas</div>
      </Link>
    </div>
  );
}

export default MobileFooter;
