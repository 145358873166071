import React, { useState } from "react";

import "../../styles/components/Matches/Match.scss";

import TeamLogoIcon from "../TeamLogoIcon";
import convertTo12Hour from "../Helpers/convertTo12h";
import { formatDate } from "../Helpers/formatDate";

function Match({ match, index, shouldDisplayTime, shouldDisplayFieldName }) {
  const [matchModalOpen, setMatchModalOpen] = useState(false);

  function handleOpenModal() {
    setMatchModalOpen(!matchModalOpen);
    // document.body.style.overflow = "hidden";
  }

  function handleCloseModal() {
    setMatchModalOpen(!matchModalOpen);
    // document.body.style.overflow = "auto";
  }

  return (
    <div className="match_v2" onClick={() => handleOpenModal()}>
      <TeamContainer team={match.team1.team} />

      <div className="matchInfoContainer">
        <div className="timeAndDate">
          <span className="date">
            {match?.day}, {match.date && formatDate(match.date)}
          </span>
          <span className="time">
            {match.time && convertTo12Hour(match.time)}
          </span>
        </div>
        <div className="scoreContainer">
          <CheckScoreAndForWinner
            a={match.team1}
            b={match.team2}
            match={match}
          />

          {match.isCompleted ? (
            <span className="vsText finalText">FINAL</span>
          ) : match.isPlaying ? (
            <span className="vsText liveText">VIVO</span>
          ) : (
            <span className="vsText">vs</span>
          )}

          <CheckScoreAndForWinner
            a={match.team2}
            b={match.team1}
            match={match}
          />
        </div>
        <div className="stadiumName">{match.location}</div>
      </div>

      <TeamContainer team={match.team2.team} />

      {matchModalOpen && (
        <div className="matchInfoModal">
          <div className="background" onClick={() => handleCloseModal()}></div>

          <div className="modal">
            <div className="timeAndDate">
              <div className="stadiumName">{match.location}</div>

              <span className="date">
                {match.date && formatDate(match.date)}
              </span>
              <span className="time">
                {match.time && " - " + convertTo12Hour(match.time)}
              </span>
            </div>

            <div className="teamsContainer">
              <TeamContainer team={match.team1.team} />

              <div className="matchInfoContainer">
                <div className="scoreContainer">
                  <CheckScoreAndForWinner
                    a={match.team1}
                    b={match.team2}
                    match={match}
                  />

                  {match.isCompleted ? (
                    <span className="vsText finalText">FINAL</span>
                  ) : match.isPlaying ? (
                    <span className="vsText liveText">VIVO</span>
                  ) : (
                    <span className="vsText">vs</span>
                  )}

                  <CheckScoreAndForWinner
                    a={match.team2}
                    b={match.team1}
                    match={match}
                  />
                </div>
              </div>

              <TeamContainer team={match.team2.team} />
            </div>

            {match.isPlaying || match.isCompleted ? (
              <>
                <ModalTeamPlayersSection team={match.team1} />
                <ModalTeamPlayersSection team={match.team2} />
              </>
            ) : (
              <center>
                <h3>Todovia no se a jugado este partido</h3>
              </center>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Match;

const TeamContainer = (team) => {
  return (
    <div className="teamContainer">
      <TeamLogoIcon team={team.team} iconSize={60} />
      <div className="teamNameContainer">{team.team.name}</div>
    </div>
  );
};

const CheckScoreAndForWinner = ({ a, b, match }) => {
  if (match.isCompleted || match.isPlaying) {
    if (a.goals != b.goals) {
      return <div className="scoreNumber">{a.goals}</div>;
    } else {
      return (
        <div className="scoreNumber">
          {a.goals}
          <span className="penaltiesScore">({a.penaltyGoals})</span>
        </div>
      );
    }
  }
};

const ModalTeamPlayersSection = ({ team }) => {
  function renderStatAmount(statAmount, icon) {
    const icons = [];
    for (let i = 0; i < statAmount; i++) {
      icons.push(<span className="statIcon">{icon}</span>);
    }

    return icons;
  }

  return (
    <div className="teamPlayersSection">
      <div className="teamLogoContainer">
        <TeamLogoIcon team={team.team} iconSize={30} />
        {team.team.name}
      </div>

      <div className="teamPlayersContainer">
        <div className="playerContainer playerContainer__tableHeader">
          <span className="playerNumber">#</span>
          <span className="playerName">Nombre</span>
        </div>
        {team.playingPlayers.map((playerInfo, index) => (
          <div className="playerContainer" key={index}>
            <span className="playerNumber">
              {playerInfo.player.number ? playerInfo.player.number : "__"}
            </span>
            <span className="playerName">
              {playerInfo.player.firstName} {playerInfo.player.lastName}
              {playerInfo.player.nickName && (
                <div className="playerNickname">
                  "{playerInfo.player.nickName}"
                </div>
              )}
            </span>
            <div className="statsSection">
              <div className="iconsContainer playerGoalsScored">
                {renderStatAmount(
                  playerInfo.goals,
                  <span className="material-symbols-rounded">
                    sports_and_outdoors
                  </span>
                )}

                {renderStatAmount(
                  playerInfo.fouls_commited,
                  <span className="matchCard matchCard__fouls">F</span>
                )}

                {renderStatAmount(
                  playerInfo.yellow_card,
                  <span className="matchCard matchCard__yellow"></span>
                )}

                {renderStatAmount(
                  playerInfo.red_card,
                  <span className="matchCard matchCard__red"></span>
                )}
              </div>
            </div>
          </div>
        ))}
        {team.ownGoalsInFavor > 0 && (
          <div className="playerContainer">
            <span className="playerNumber"></span>
            <span className="playerName">Autogol</span>

            <div className="statsSection">
              <div className="iconsContainer playerGoalsScored">
                {renderStatAmount(
                  team.ownGoalsInFavor,
                  <span className="material-symbols-rounded">
                    sports_and_outdoors
                  </span>
                )}
              </div>
            </div>
          </div>
        )}
        {/* <div className="playerContainer">
          <span className="playerNumber">63</span>
          <span className="playerName">Aaron Stubbs</span>
        </div>
        <div className="playerContainer">
          <span className="playerNumber">1</span>
          <span className="playerName">
            Martin Arreola
            <div className="playerNickname">"El Programmador"</div>
          </span>
        </div>
        <div className="playerContainer">
          <span className="playerNumber">???</span>
          <span className="playerName">Beto Gonzales</span>
        </div>
        <div className="playerContainer">
          <span className="playerNumber">1</span>
          <span className="playerName">Rolando Trevizo</span>
        </div> */}
      </div>
    </div>
  );
};
