// Owner: Edgar Ramos (Cuatli said it was Angel)

import Logo from "./images/teamLogos/brothers.png";

export const Team__Brothers = {
  name: "Los Brothers",
  logoImage: Logo,
  teamImage: null,
  teamLogoColor: "#63E55C",
  teamJerseyColor: "#ffffff",

  // Stats
  gamesWon: 0,
  gamesLost: 4,
  gamesTied: 0,
  gamesTiedWon: 0,

  ownGoalsInFavor: 0,
  goalsScoredAgainst: 15 + 2 + 2 + 2,
  defaultGoals: 0,

  isChampion: false,
  isEliminated: false,

  players: [
    {
      firstName: "Edgar",
      lastName: "Ramos",
      nickName: null,
      yearBorn: 2006,
      number: null,
      image: null,
      isCaptain: true,

      // Stats
      matchesPlayed: 0,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Angel",
      lastName: "Ramirez",
      nickName: null,
      yearBorn: 2008,
      number: null,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Angel",
      lastName: "Lopez",
      nickName: null,
      yearBorn: 2006,
      number: null,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 0,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Jose",
      lastName: "Cruz",
      nickName: null,
      yearBorn: 2003,
      number: null,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Hector",
      lastName: "Nuñes",
      nickName: null,
      yearBorn: 2001,
      number: null,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Edgar",
      lastName: "Lopez",
      nickName: null,
      yearBorn: 2006,
      number: null,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Jesús",
      lastName: "Ramos",
      nickName: null,
      yearBorn: 2009,
      number: null,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 0,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Alejandro",
      lastName: "Casarrubias",
      nickName: null,
      yearBorn: 2004,
      number: null,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 0,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Carlos",
      lastName: "Casarrubias",
      nickName: null,
      yearBorn: 2008,
      number: null,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 0,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Rogelio",
      lastName: "Ríos",
      nickName: null,
      yearBorn: 2008,
      number: null,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 0,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Junior",
      lastName: "Gil",
      nickName: null,
      yearBorn: 2007,
      number: null,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    // ===========================================
    // ========= Non Registered Players ==========
    // ===========================================

    {
      firstName: "Daniel",
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Roy",
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 1,
      goalsScored: 4,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Nene",
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },
  ],
};
