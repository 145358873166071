import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

// scss
import "../../styles/pages/TournamentHomePage.scss";

// Components
import Bracket from "../../components/Bracket";
import TeamRankingsTable, {
  BetaGroupTable,
  BetaTeamRankingsTable,
} from "../../components/tables/TeamRankingsTable";

import WeekSchedule from "../../components/WeekSchedule";

import { tournaments_data } from "../../__FakeDatabase/Tournaments_data";
import BetaTournamentNavigationComponents from "../../components/BetaComponents/Tournament/TournamentNavigationComponents";
import { JornadaBox } from "./BetaSchedulePage";
import TeamLogoIcon from "../../components/TeamLogoIcon";
import convertTo12Hour from "../../components/Helpers/convertTo12h";
import { formatDate } from "../../components/Helpers/formatDate";

function getWindowDimensions() {
  const { innerWidth: screenWidth, innerHeight: screenHeight } = window;
  return {
    screenWidth,
    screenHeight,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

function BetaTournamentHomePage() {
  const tournamentParams = useParams();
  const tournament = tournaments_data.find(
    (p) => p.URL_name === tournamentParams.id
  );

  const { screenHeight, screenWidth } = useWindowDimensions();
  const [showPlayoffs, setShowPlayoffs] = useState(true);

  if (!tournament && !tournamentParams.isLocalData) {
    return (
      <>
        <h1 style={{ textAlign: "center" }}>Torneo No Encontrado</h1>
      </>
    );
  }

  return (
    <>
      <BetaTournamentNavigationComponents tournament={tournament}>
        <div className="tournamentHomePage">
          <div className="heroSectionAndTableContainer">
            <div
              className="heroSection"
              style={{
                backgroundImage: `${
                  tournament?.heroSectionImage
                    ? `url(${tournament?.heroSectionImage})`
                    : "none"
                }`,
              }}
            >
              {tournament?.heroSectionImage != null && (
                <div className="backgroundGradient"></div>
              )}
            </div>

            <div
              className="logoAndTableContainer"
              style={{ marginTop: -((screenHeight / 100) * 90) + 60 + 20 }}
            >
              <div className="logoAndNameContainer">
                <div className="logoAndName">
                  {tournament?.tournamentLogoImage && (
                    <div className="tournamentLogoContainer">
                      <img
                        src={tournament?.tournamentLogoImage}
                        alt=""
                        className="tournamentLogo"
                      />
                    </div>
                  )}
                  <div className="tournamentNameContainer">
                    {tournament?.name}
                  </div>
                </div>
              </div>

              <div className="statsPageNavbarContainer">
                <div className="statsPageNavbar">
                  <div
                    to={`/torneo-beta/${tournament.URL_name}/estadisticas`}
                    className={`statsPageNavbarLink ${
                      showPlayoffs && "statsPageNavbarLink__active"
                    }`}
                    onClick={() => setShowPlayoffs(true)}
                  >
                    Eliminatoria
                  </div>
                  <div
                    to={`/torneo-beta/${tournament.URL_name}/estadisticas/equipos`}
                    className={`statsPageNavbarLink ${
                      !showPlayoffs && "statsPageNavbarLink__active"
                    }`}
                    onClick={() => setShowPlayoffs(false)}
                  >
                    Grupos
                  </div>
                </div>
              </div>

              {showPlayoffs ? (
                <div className="playoffsSectionWrapper">
                  <div className="playoffsSectionContainer">
                    {tournament?.playoffs.map((playoff_faze, index) => (
                      <div className="playoffFazeContainer" key={index}>
                        {playoff_faze.matches.map((match, index) => (
                          <PlayoffCard
                            match={match}
                            playoff_faze={playoff_faze}
                            key={index}
                          />
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="tableTeamRankingsSection">
                  {tournament.tournamentType === "season_and_playoff" ? (
                    <BetaTeamRankingsTable
                      tournament={tournament}
                      teams={tournament?.teams}
                    />
                  ) : tournament.tournamentType === "groups_and_playoff" ? (
                    <>
                      {tournament.groups.map((group, index) => (
                        <BetaGroupTable
                          tournament={tournament}
                          group={group}
                          index={index}
                          key={index}
                        />
                      ))}
                    </>
                  ) : (
                    <BetaTeamRankingsTable
                      tournament={tournament}
                      teams={tournament?.teams}
                    />
                  )}
                </div>
              )}
            </div>
          </div>

          {!showPlayoffs && (
            <div className="jornadaSchedule">
              {tournament.activeJornadas.map((jornada, index) => (
                <JornadaBox
                  jornada={jornada}
                  index={index}
                  tournament={tournament}
                  key={index}
                />
              ))}
            </div>
          )}
        </div>
      </BetaTournamentNavigationComponents>
    </>
  );
}

export default BetaTournamentHomePage;

const PlayoffCard = ({ match, playoff_faze }) => {
  const [matchModalOpen, setMatchModalOpen] = useState(false);

  function handleOpenModal() {
    setMatchModalOpen(!matchModalOpen);
    // document.body.style.overflow = "hidden";
  }

  function handleCloseModal() {
    setMatchModalOpen(!matchModalOpen);
    // document.body.style.overflow = "auto";
  }

  return (
    <div className="playoffCard" onClick={() => handleOpenModal()}>
      <div className="topSection">
        <div className="playoffNameAndTimeContainer">
          {match.matchName ? match.matchName : playoff_faze.playoffName}

          {match.time && " - " + convertTo12Hour(match.time)}
        </div>
        <div className="dateContainer">
          {match?.day}, {match.date && formatDate(match.date)}
        </div>
      </div>
      <div className="teamCardsContainer">
        <div className="teamCardContainer">
          <TeamContainer team={match.team1} />
          <CheckScoreAndForWinner
            a={match.team1}
            b={match.team2}
            match={match}
          />
        </div>
        <div className="teamCardContainer">
          <TeamContainer team={match.team2} />
          <CheckScoreAndForWinner
            a={match.team2}
            b={match.team1}
            match={match}
          />
        </div>
      </div>

      {matchModalOpen && (
        <div className="matchInfoModal">
          <div className="background" onClick={() => handleCloseModal()}></div>

          <div className="modal">
            <div className="timeAndDate">
              <div className="stadiumName">{match.location}</div>

              <span className="date">
                {match.date && formatDate(match.date)}
              </span>
              <span className="time">
                {match.time && " - " + convertTo12Hour(match.time)}
              </span>
            </div>

            <div className="teamsContainer">
              <TeamContainer team={match.team1} iconSize={60} />

              <div className="matchInfoContainer">
                <div className="scoreContainer">
                  <CheckScoreAndForWinner
                    a={match.team1}
                    b={match.team2}
                    match={match}
                  />

                  {match.isCompleted ? (
                    <span className="vsText finalText">FINAL</span>
                  ) : match.isPlaying ? (
                    <span className="vsText liveText">VIVO</span>
                  ) : (
                    <span className="vsText">vs</span>
                  )}

                  <CheckScoreAndForWinner
                    a={match.team2}
                    b={match.team1}
                    match={match}
                  />
                </div>
              </div>

              <TeamContainer team={match.team2} iconSize={60} />
            </div>

            {match.isPlaying || match.isCompleted ? (
              <>
                <ModalTeamPlayersSection team={match.team1} />
                <ModalTeamPlayersSection team={match.team2} />
              </>
            ) : (
              <center>
                <h3>Todovia no se a jugado este partido</h3>
              </center>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const TeamContainer = ({ team, iconSize }) => {
  return (
    <div className="teamCard">
      <TeamLogoIcon team={team.team} iconSize={iconSize ? iconSize : 35} />
      <div className="teamName">{team.team.name}</div>
    </div>
  );
};

const CheckScoreAndForWinner = ({ a, b, match }) => {
  if (match.isCompleted || match.isPlaying) {
    if (a.goals != b.goals) {
      return <div className="teamScore">{a.goals}</div>;
    } else {
      return (
        <div className="teamScore">
          {a.goals}
          <span className="penaltiesScore">({a.penaltyGoals})</span>
        </div>
      );
    }
  }
};

const ModalTeamPlayersSection = ({ team }) => {
  function renderStatAmount(statAmount, icon) {
    const icons = [];
    for (let i = 0; i < statAmount; i++) {
      icons.push(<span className="statIcon">{icon}</span>);
    }

    return icons;
  }

  return (
    <div className="teamPlayersSection">
      <div className="teamLogoContainer">
        <TeamLogoIcon team={team.team} iconSize={30} />
        {team.team.name}
      </div>

      <div className="teamPlayersContainer">
        <div className="playerContainer playerContainer__tableHeader">
          <span className="playerNumber">#</span>
          <span className="playerName">Nombre</span>
        </div>
        {team.playingPlayers.map((playerInfo, index) => (
          <div className="playerContainer" key={index}>
            <span className="playerNumber">
              {playerInfo.player.number ? playerInfo.player.number : "__"}
            </span>
            <span className="playerName">
              {playerInfo.player.firstName} {playerInfo.player.lastName}
              {playerInfo.player.nickName && (
                <div className="playerNickname">
                  "{playerInfo.player.nickName}"
                </div>
              )}
            </span>
            <div className="statsSection">
              <div className="iconsContainer playerGoalsScored">
                {renderStatAmount(
                  playerInfo.goals,
                  <span className="material-symbols-rounded">
                    sports_and_outdoors
                  </span>
                )}

                {renderStatAmount(
                  playerInfo.fouls_commited,
                  <span className="matchCard matchCard__fouls">F</span>
                )}

                {renderStatAmount(
                  playerInfo.yellow_card,
                  <span className="matchCard matchCard__yellow"></span>
                )}

                {renderStatAmount(
                  playerInfo.red_card,
                  <span className="matchCard matchCard__red"></span>
                )}
              </div>
            </div>
          </div>
        ))}
        {team.ownGoalsInFavor > 0 && (
          <div className="playerContainer">
            <span className="playerNumber"></span>
            <span className="playerName">Autogol</span>

            <div className="statsSection">
              <div className="iconsContainer playerGoalsScored">
                {renderStatAmount(
                  team.ownGoalsInFavor,
                  <span className="material-symbols-rounded">
                    sports_and_outdoors
                  </span>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
