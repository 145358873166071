import { Jornada1 } from "./Jornadas/Jornada_1";
import { Jornada2 } from "./Jornadas/Jornada_2";
import { Jornada4 } from "./Jornadas/Jornada_4";
import { Jornada5 } from "./Jornadas/Jornada_5";
import { Jornada6 } from "./Jornadas/Jornada_6";

export const ActiveJornada_FIRST_DIVISION = [
  Jornada1,
  Jornada2,
  Jornada4,
  Jornada5,
  Jornada6,
];

export const JornadaSchedule = [Jornada1];
