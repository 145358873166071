import { Team__Leonas } from "./teams/Leonas";
import { Team__Pumbas } from "./teams/Pumbas";
import { Team__SuperNovas } from "./teams/SuperNovas";
import { Team__Valleras } from "./teams/Valleras";
import { Team__Vaqueras } from "./teams/Vaqueras";

export const teamList = [
  Team__Leonas,
  Team__Pumbas,
  Team__SuperNovas,
  Team__Valleras,
  Team__Vaqueras,
];
