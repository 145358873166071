// Owner: Aaron Stubbs

export const Team__Adrianes = {
  name: "Adrianes",
  logoImage: null,
  teamImage: null,
  teamLogoColor: null,
  teamJerseyColor: "#D61616",

  // Stats
  gamesWon: 1 + 1,
  gamesLost: 1 + 1,
  gamesTied: 0,
  gamesTiedWon: 0,

  ownGoalsInFavor: 0,
  // goalsScoredAgainst: 23 + 32 + 40 + 30,
  goalsScoredAgainst: 32 + 40,
  defaultGoals: 0,

  isChampion: false,
  isEliminated: false,

  players: [
    {
      // goalsScored: 34 + 34 + 31 + 29,
      goalsScored: 34 + 29,
    },
  ],
};
