import React from "react";
import { Link } from "react-router-dom";

import "../../styles/components/Cards/TournamentCard.scss";

function TournamentCard({ tournament, isBasketball }) {
  const NoTournament = () => {
    alert(`Ya no puedes entrar al torneo: ${tournament.name}`);
  };

  const TournamentOverlay = (tournament) => {
    if (tournament.isCompleted) {
      return "tournamentCompleted";
    } else {
      return "tournamentNotFinished";
    }
  };

  return (
    <>
      <Link
        to={
          isBasketball
            ? `/t-beta/basketball/${tournament.URL_name}/`
            : tournament.isLocalData
            ? `/torneo-beta/${tournament.URL_name}/`
            : `/torneo/${tournament.URL_name}/`
        }
        onClick={tournament._cantEnterAnymore ? NoTournament : null}
        className={`tournamentCard_v2`}
      >
        <div className="tournamentThumbnailContainer">
          <img
            src={tournament.thumbnailImage}
            alt=""
            className="tournamentThumbnail"
          />
        </div>

        <div className="infoSection">
          <div className="tournamentName">{tournament.name}</div>
          <div className="tournamentHost">Organizador: {tournament.host}</div>
          <div className={`tournamentSex tournamentSex__${tournament.sex}`}>
            {tournament.sex === "male"
              ? "Varonil"
              : tournament.sex === "female"
              ? "Feminil"
              : "Mixto"}
          </div>
          <div className="tournamentLocation">
            <i class="fa-solid fa-location-dot"></i> {tournament.location}
          </div>
        </div>
      </Link>
    </>
  );
}

export default TournamentCard;
