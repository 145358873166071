import axios from "axios";
import {
  JORNADA_LIST_FAIL,
  JORNADA_LIST_REQUEST,
  JORNADA_LIST_SUCCESS,
  JORNADA_MATCHES_LIST_REQUEST,
  JORNADA_MATCHES_LIST_SUCCESS,
  JORNADA_MATCHES_LIST_FAIL,
} from "../constants/jornadaConstants";

export const listJornadas = (tournament_URL_name) => async (dispatch) => {
  try {
    dispatch({ type: JORNADA_LIST_REQUEST });

    const { data } = await axios.get(
      `/api/tournaments/${tournament_URL_name}/jornadas/`
    );

    dispatch({
      type: JORNADA_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: JORNADA_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.message
          : error.message,
    });
  }
};

export const listJornadaMatches =
  (tournament_URL_name, jornada_placement) => async (dispatch) => {
    try {
      dispatch({ type: JORNADA_MATCHES_LIST_REQUEST });

      const { data } = await axios.get(
        `/api/tournaments/${tournament_URL_name}/jornadas/${jornada_placement}/matches/`
      );

      dispatch({
        type: JORNADA_MATCHES_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: JORNADA_MATCHES_LIST_FAIL,
        payload:
          error.response && error.response.data.message.detail
            ? error.response.message.detail
            : error.message,
      });
    }
  };
