import React from "react";
import { Link } from "react-router-dom";

// scss
import "../../styles/components/Cards/ShopProductCard.scss";

function ShopProductCard({ product }) {
  return (
    <Link to={`/comprar/${product.name}`} className="shopProductCard ">
      <div className="imageContainer">
        <img
          src={product.image}
          alt=""
          draggable="false"
          className="productImg"
        />
      </div>

      <div className="productInfoContainer">
        <div className="productName">{product.name}</div>
        <div className="productBrand">{product.brand}</div>

        <div className="productPrice">
          <span>$</span>
          {product.price}
        </div>

        <div className="productSize">{product.size}</div>

        {product.amount ? (
          <div className="isAvailable">Disponible: {product.amount}</div>
        ) : null}
        {product.amount <= 0 && <div className="soldOutText">Agotado</div>}
      </div>
    </Link>
  );
}

export default ShopProductCard;
