import { Team__Aliados } from "../teams/Aliados";
import { Team__Cerveceros_MAIN } from "../teams/Cerveceros_MAIN";
import { Team__Chiles } from "../teams/Chiles";
import { Team__MeanMachine } from "../teams/MeanMachine";
import { Team__Nogaleros } from "../teams/Nogaleros";
import { Team__Raptors } from "../teams/Raptors";
import { Team__Tec } from "../teams/Tec";
import { Team__XLobos } from "../teams/XLobos";

const Aliados = {
  team: Team__Aliados,
  playingPlayers: [],

  goals: 48,
  penaltyGoals: 0,
  isWinner: false,
};

const Cerveceros = {
  team: Team__Cerveceros_MAIN,
  playingPlayers: [],

  goals: 0,
  penaltyGoals: 0,
  isWinner: false,
};

const Chiles = {
  team: Team__Chiles,
  playingPlayers: [],

  goals: 0,
  penaltyGoals: 0,
  isWinner: false,
};

const MeanMachine = {
  team: Team__MeanMachine,
  playingPlayers: [],

  goals: 0,
  penaltyGoals: 0,
  isWinner: false,
};

const Nogaleros = {
  team: Team__Nogaleros,
  playingPlayers: [],

  goals: 57,
  penaltyGoals: 0,
  isWinner: false,
};

const Raptors = {
  team: Team__Raptors,
  playingPlayers: [],

  goals: 0,
  penaltyGoals: 0,
  isWinner: false,
};

const Tec = {
  team: Team__Tec,
  playingPlayers: [],

  goals: 0,
  penaltyGoals: 0,
  isWinner: false,
};

const XLobos = {
  team: Team__XLobos,
  playingPlayers: [],

  goals: 0,
  penaltyGoals: 0,
  isWinner: false,
};

const FINALIST_1 = {
  team: {
    name: "Finalista",
    logoImage: null,
    teamImage: null,
    teamLogoColor: null,
    teamJerseyColor: "#ffffff",
  },
  playingPlayers: [],

  goals: 0,
  penaltyGoals: 0,
  isWinner: false,
};

const FINALIST_2 = {
  team: {
    name: "Finalista",
    logoImage: null,
    teamImage: null,
    teamLogoColor: null,
    teamJerseyColor: "#ffffff",
  },
  playingPlayers: [],

  goals: 0,
  penaltyGoals: 0,
  isWinner: false,
};

export const Jornada6 = {
  jornadaPosition: 6,
  matches: [
    {
      team1: Nogaleros,
      team2: Aliados,

      isCompleted: true,
      isPlaying: false,
      day: "Sabado",
      date: "2024-08-03",
      time: "13:00:00",
      location: "Centro Recreativo Lebaron",
    },
  ],
};
