// Owner: Machis

import Logo from "./images/teamLogos/Valleras_Transparent.png";
import TeamImg from "./images/teamImages/Valleras.jpg";

// Player Images
import Player__AnaricaDelgado from "./images/playerImages/valleras/AnaricaDelgado.jpg";
import Player__AlexaQuezada from "./images/playerImages/valleras/AlexaQuezada.jpg";
import Player__ClaudiaMarquez from "./images/playerImages/valleras/ClaudiaMarquez.jpg";
import Player__IdaliCordova from "./images/playerImages/valleras/IdaliCordova.jpg";
import Player__MarceChalaca from "./images/playerImages/valleras/MarceChalaca.jpg";
import Player__MiriamSerrano from "./images/playerImages/valleras/MiriamSerrano.jpg";

export const Team__Valleras = {
  name: "Valleras",
  logoImage: Logo,
  teamImage: TeamImg,
  teamLogoColor: "#FA98C5",
  teamJerseyColor: "#FA98C5",

  // Stats
  gamesWon: 1,
  gamesLost: 2,
  gamesTied: 1,
  gamesTiedWon: 0,

  ownGoalsInFavor: 0,
  goalsScoredAgainst: 4 + 1 + 2 + 5,
  defaultGoals: 0,

  isChampion: false,
  isEliminated: false,

  players: [
    {
      firstName: "Idali",
      lastName: "Córdova",
      nickName: null,
      yearBorn: null,
      number: 7,
      image: Player__IdaliCordova,
      // Stats
      matchesPlayed: 1 + 1 + 1,
      goalsScored: 1,
      foulsCommited: 2 + 1 + 1 + 2,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Anaricka",
      lastName: "Delgado",
      nickName: null,
      yearBorn: 1989,
      number: 4,
      image: Player__AnaricaDelgado,
      isCaptain: true,

      // Stats
      matchesPlayed: 1 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 2,
      yellowCards: 0,
      redCards: 0,
    },
    {
      firstName: "Laura",
      lastName: "Chávez",
      nickName: null,
      yearBorn: null,
      number: 14,
      image: null,
      isCaptain: true,

      // Stats
      matchesPlayed: 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },
    {
      firstName: "Leslie",
      lastName: "Vega", // Ochoa
      nickName: null,
      yearBorn: null,
      number: 11,

      // Stats
      matchesPlayed: 1 + 1 + 1 + 1,
      goalsScored: 1,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },
    {
      firstName: "Maricela",
      lastName: "Rodríguez",
      nickName: null,
      yearBorn: 1987,
      number: 13,
      image: null,
      isCaptain: true,

      // Stats
      matchesPlayed: 1 + 1 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 1 + 1,
      yellowCards: 0,
      redCards: 0,
    },
    {
      firstName: "Miriam",
      lastName: "Serrano",
      nickName: null,
      yearBorn: null,
      number: 1,
      image: Player__MiriamSerrano,

      // Stats
      matchesPlayed: 1 + 1 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },
    {
      firstName: "Marce",
      lastName: "Chalaca",
      nickName: null,
      yearBorn: null,
      number: 19,
      image: Player__MarceChalaca,

      // Stats
      matchesPlayed: 1 + 1 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },
    {
      firstName: "Claudia",
      lastName: "Marquez",
      nickName: null,
      yearBorn: 1984,
      number: 9,
      image: Player__ClaudiaMarquez,

      // Stats
      matchesPlayed: 1 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Luisa", // Fernanda
      lastName: "Salas",
      nickName: null,
      yearBorn: 2006,
      number: 20,

      // Stats
      matchesPlayed: 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Alexa",
      lastName: "Quezada",
      nickName: null,
      yearBorn: 2007,
      number: null,
      image: Player__AlexaQuezada,

      // Stats
      matchesPlayed: 1 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Adriana",
      lastName: "Avaloz",
      nickName: null,
      yearBorn: null,
      number: null,

      // Stats
      matchesPlayed: 1 + 1 + 1 + 1,
      goalsScored: 2,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Sandra",
      lastName: "Kathryn",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 1,
      goalsScored: 5,
      foulsCommited: 3,
      yellowCards: 1,
      redCards: 0,
    },
  ],
};
