// Owner: Aaron Stubbs

export const Team__PecanBros = {
  name: "Pecan Bros",
  logoImage: null,
  teamImage: null,
  teamLogoColor: null,
  teamJerseyColor: "#005209",

  // Stats
  gamesWon: 1 + 1 + 1 + 1,
  gamesLost: 0,
  gamesTied: 0,
  gamesTiedWon: 0,

  ownGoalsInFavor: 0,
  goalsScoredAgainst: 22 + 17 + 23 + 38,
  defaultGoals: 0,

  isChampion: false,
  isEliminated: false,

  players: [
    {
      goalsScored: 33 + 30 + 26 + 42,
    },
  ],
};
