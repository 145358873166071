import size_4__adidas__messi_club__1 from "./images/Size 4 - Adidas - Messi Club/size-4--adidas--messi-club-1.webp";
import size_4__adidas__messi_club__2 from "./images/Size 4 - Adidas - Messi Club/size-4--adidas--messi-club-2.webp";
import size_4__adidas__messi_club__3 from "./images/Size 4 - Adidas - Messi Club/size-4--adidas--messi-club-3.png";
import size_4__adidas__messi_club__4 from "./images/Size 4 - Adidas - Messi Club/size-4--adidas--messi-club-4.webp";

import size_4__adidas__ucl_lge__1 from "./images/Size 4 - Adidas - UCL LGE/size-4--adidas--ucl-lge-1.webp";
import size_4__adidas__ucl_lge__2 from "./images/Size 4 - Adidas - UCL LGE/size-4--adidas--ucl-lge-2.webp";
import size_4__adidas__ucl_lge__3 from "./images/Size 4 - Adidas - UCL LGE/size-4--adidas--ucl-lge-3.webp";

import size_4__nike_academy__circles_1 from "./images/Size 4 - Nike Academy - Circles - Pink/size-4--nike-academy--circles--pink-1.jpg";
import size_4__nike_academy__circles_2 from "./images/Size 4 - Nike Academy - Circles - Pink/size-4--nike-academy--circles--pink-2.jpg";
import size_4__nike_academy__circles_3 from "./images/Size 4 - Nike Academy - Circles - Pink/size-4--nike-academy--circles--pink-3.jpg";
import size_4__nike_academy__circles_4 from "./images/Size 4 - Nike Academy - Circles - Pink/size-4--nike-academy--circles--pink-4.jpg";

import size_4__nike_academy__circles_black_and_gold_1 from "./images/Size 4 - Nike Academy - Black and Gold.webp";

import size_4__nike_academy__stars_1 from "./images/Size 4 - Nike Academy - Stars - Purple/size-4--nike-academy--stars--purple-1.webp";
import size_4__nike_academy__stars_2 from "./images/Size 4 - Nike Academy - Stars - Purple/size-4--nike-academy--stars--purple-2.webp";

import size_5__nike_club_elite from "./images/Size 5 - Nike Club Elite.webp";

import size_4__nike_pitch_training__green_1 from "./images/Size 4 - 702 Volt/size-4--nike-pitch-training--yellow-1.webp";

import size_4__nike_pitch_training__pink_1 from "./images/Size 4 - 629/size-4--nike-pitch-training--pink-1.webp";
import size_4__nike_pitch_training__pink_2 from "./images/Size 4 - 629/size-4--nike-pitch-training--pink-2.webp";

import nike_ball_pump__blue from "./images/Nike Essential Ball Pump/blue.webp";
import nike_ball_pump__black_package from "./images/Nike Essential Ball Pump/black_package.jpg";
import nike_ball_pump__black from "./images/Nike Essential Ball Pump/black.jpg";
import nike_ball_pump__lime from "./images/Nike Essential Ball Pump/Lima.jpg";

import quest__safetyWhistle from "./images/Whistle_Orange.png";

export const Quest__SafetyWhistle = {
  img_1: quest__safetyWhistle,
};

export const Nike_Ball_Pump__Black = {
  img_1: nike_ball_pump__black_package,
  img_2: nike_ball_pump__black,
};

export const Nike_Ball_Pump__Blue = {
  img_1: nike_ball_pump__blue,
};

export const Nike_Ball_Pump__Lime = {
  img_1: nike_ball_pump__lime,
};

export const Size_4__Adidas__Messi_Club = {
  img_1: size_4__adidas__messi_club__1,
  img_2: size_4__adidas__messi_club__2,
  img_3: size_4__adidas__messi_club__3,
  img_4: size_4__adidas__messi_club__4,
};

export const Size_4__Adidas__UCL_LGE = {
  img_1: size_4__adidas__ucl_lge__1,
  img_2: size_4__adidas__ucl_lge__2,
  img_3: size_4__adidas__ucl_lge__3,
};
export const Size_4__Nike_Academy__Circles__Gold = {
  img_1: size_4__nike_academy__circles_black_and_gold_1,
};

export const Size_4__Nike_Academy__Circles__Pink = {
  img_1: size_4__nike_academy__circles_2,
  img_2: size_4__nike_academy__circles_1,
  img_3: size_4__nike_academy__circles_3,
  img_4: size_4__nike_academy__circles_4,
};

export const Size_4__Nike_Academy__Stars = {
  img_1: size_4__nike_academy__stars_2,
  img_2: size_4__nike_academy__stars_1,
};

export const Size_5__Nike_Club_Elite = {
  img_1: size_5__nike_club_elite,
};

export const Size_4__Nike_Pitch_Training__Green = {
  img_1: size_4__nike_pitch_training__green_1,
};

export const Size_4__Nike_Pitch_Training__Pink = {
  img_1: size_4__nike_pitch_training__pink_1,
  img_2: size_4__nike_pitch_training__pink_2,
};
