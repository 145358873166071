// Owner: Aaron Stubbs

export const Team__Panthers = {
  name: "Panthers",
  logoImage: null,
  teamImage: null,
  teamLogoColor: null,
  teamJerseyColor: null,

  // Stats
  gamesWon: 2,
  gamesLost: 1,
  gamesTied: 0,
  gamesTiedWon: 0,

  ownGoalsInFavor: 0,
  goalsScoredAgainst: 27 + 20 + 27,
  defaultGoals: 0,

  isChampion: false,
  isEliminated: false,

  players: [
    {
      goalsScored: 32 + 23 + 18,
    },
  ],
};
