// Owner: Aaron Stubbs

export const Team__Cerveceros = {
  name: "Cerveceros 2.0",
  logoImage: null,
  teamImage: null,
  teamLogoColor: null,
  teamJerseyColor: "#394F75",

  // Stats
  gamesWon: 1,
  gamesLost: 1 + 1 + 1,
  gamesTied: 0,
  gamesTiedWon: 0,

  ownGoalsInFavor: 0,
  goalsScoredAgainst: 37 + 30 + 30 + 42,
  defaultGoals: 0,

  isChampion: false,
  isEliminated: false,

  players: [
    {
      goalsScored: 21 + 31 + 17 + 18,
    },
  ],
};
