import { Team__Astros } from "../teams/Astros";
import { Team__Brothers } from "../teams/Brothers";
import { Team__Cobras } from "../teams/Cobras";
import { Team__DeportivoMaster } from "../teams/DeportivoMaster";
import { Team__LagunitasFC } from "../teams/LagunitasFC";
import { Team__LeonesFC } from "../teams/LeonesFC";
import { Team__Piratas } from "../teams/Piratas";
import { Team__RSD } from "../teams/RSD";
import { Team__Vagos } from "../teams/Vagos";
import { Team__VeterinariaSabata } from "../teams/VeterinariaSabata";

const Astros = {
  team: Team__Astros,
  playingPlayers: [
    {
      player: {
        firstName: "Martin",
        lastName: "Arreola",
        nickName: "El Programador",
        yearBorn: 2007,
        number: 4,
      },
    },

    {
      player: {
        firstName: "Aaron",
        lastName: "Stubbs",
        nickName: null,
        yearBorn: 2006,
      },
    },

    {
      player: {
        firstName: "Rolando",
        lastName: "Trevizo",
        nickName: null,
        yearBorn: 2005,
      },
    },

    {
      player: {
        firstName: "Luis",
        lastName: "Zambrano",
        nickName: null,
        yearBorn: 1999,
      },
    },

    {
      player: {
        firstName: "Beto",
        lastName: "Gonzalez",
        nickName: null,
        yearBorn: 2002,
      },
    },

    {
      player: {
        firstName: "Octavio",
        lastName: "Carbajal",
        nickName: null,
        yearBorn: 2006,
      },
    },

    {
      player: {
        firstName: "Israel",
        lastName: "Sarabia",
        nickName: null,
        yearBorn: 1996,
        number: null,
      },
    },

    {
      player: {
        firstName: "Fausto",
        lastName: "Murillo",
        nickName: null,
        yearBorn: 2000,
      },
    },

    {
      player: {
        firstName: "Jonathan",
        lastName: "Stubbs",
        nickName: null,
        yearBorn: 2001,
      },
    },

    {
      player: {
        firstName: "Carlos",
        lastName: "Carrasco",
        nickName: "Charly",
        yearBorn: 2006,
      },
    },
  ],

  goals: 2,
  penaltyGoals: 0,
  isWinner: false,
};

const RSD = {
  team: Team__RSD,
  playingPlayers: [
    {
      player: {
        firstName: "Arath",
        lastName: "Acosta",
        nickName: null,
        image: null,
        number: null,
      },

      goals: 3,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },

    {
      player: {
        firstName: "Armando",
        lastName: "",
        nickName: null,
        image: null,
        number: null,
      },

      goals: 1,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },

    {
      player: {
        firstName: "Andres",
        lastName: "",
        nickName: null,
        image: null,
        number: null,
      },

      goals: 1,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Yair",
        lastName: "",
        nickName: null,
        image: null,
        number: null,
      },

      goals: 6,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Edwin",
        lastName: "",
        nickName: null,
        image: null,
        number: null,
      },

      goals: 3,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Diego",
        lastName: "",
        nickName: null,
        image: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Alexis",
        lastName: "",
        nickName: null,
        image: null,
        number: null,
      },

      goals: 1,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 15,
  penaltyGoals: 0,
  isWinner: false,
};

const LagunitasFC = {
  team: Team__LagunitasFC,
  playingPlayers: [
    {
      player: {
        firstName: "",
        lastName: "",
        nickName: null,
        image: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 0,
  penaltyGoals: 0,
  isWinner: false,
};

const Vagos = {
  team: Team__Vagos,
  playingPlayers: [
    {
      player: {
        firstName: "No se presento",
        lastName: "",
        nickName: null,
        image: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 0,
  penaltyGoals: 0,
  isWinner: false,
};

const Brothers = {
  team: Team__Brothers,
  playingPlayers: [
    {
      player: {
        firstName: "Daniel",
        lastName: "",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },

    {
      player: {
        firstName: "Angel",
        lastName: "Ramirez",
        nickName: null,
        yearBorn: 2008,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },

    {
      player: {
        firstName: "Jose",
        lastName: "Cruz",
        nickName: null,
        yearBorn: 2003,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },

    {
      player: {
        firstName: "Roy",
        lastName: "",
        nickName: null,
        image: null,
        number: null,
      },

      goals: 4,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },

    {
      player: {
        firstName: "Hector",
        lastName: "Nuñes",
        nickName: null,
        yearBorn: 2001,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },

    {
      player: {
        firstName: "Junior",
        lastName: "Gil",
        nickName: null,
        yearBorn: 2007,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },

    {
      player: {
        firstName: "Nene",
        lastName: "",
        nickName: null,
        image: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },

    {
      player: {
        firstName: "Edgar",
        lastName: "Lopez",
        nickName: null,
        yearBorn: 2006,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 4,
  penaltyGoals: 0,
  isWinner: false,
};

const VeterinariaSabata = {
  team: Team__VeterinariaSabata,
  playingPlayers: [
    {
      player: {
        firstName: "Ivan",
        lastName: "Stubbs",
        nickName: null,
        yearBorn: 2003,
        number: null,
      },

      goals: 3,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Aldo",
        lastName: "Soto",
        nickName: null,
        yearBorn: 2002,
        number: null,
      },

      goals: 5,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Luis",
        lastName: "Estrada",
        nickName: "Rojo",
        yearBorn: 2002,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Kevin",
        lastName: "Rodríguez",
        nickName: "Kevincito",
        yearBorn: 2004,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "",
        lastName: "Sosa",
        nickName: null,
        yearBorn: 2000,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Angel",
        lastName: "Vargas",
        nickName: null,
        yearBorn: 2002,
        number: null,
      },

      goals: 1,
      fouls_commited: 4,
      yellow_card: 0,
      red_card: 0,
    },

    {
      player: {
        firstName: "Gilbran",
        lastName: "Guerrero",
        nickName: null,
        yearBorn: 2006,
        number: null,
      },

      goals: 1,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },

    {
      player: {
        firstName: "Andrés",
        lastName: "Soto",
        nickName: null,
        yearBorn: 1996,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 10,
  penaltyGoals: 0,
  isWinner: false,
};

const Piratas = {
  team: Team__Piratas,
  playingPlayers: [
    {
      player: {
        firstName: "Jorge",
        lastName: "Esquivel",
        nickName: null,
        yearBorn: 1999,
        number: null,
        image: null,
        isCaptain: false,
      },

      goals: 10,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Samuel",
        lastName: "Esquivel",
        nickName: null,
        yearBorn: 2006,
        number: null,
        image: null,
        isCaptain: false,
      },

      goals: 1,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Luis",
        lastName: "Dominguez",
        nickName: null,
        yearBorn: 1990,
        number: null,
        image: null,
        isCaptain: false,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Alfredo",
        lastName: "Delgado",
        nickName: null,
        yearBorn: 1998,
        number: null,
        image: null,
        isCaptain: false,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Martin",
        lastName: "Castillo",
        nickName: null,
        yearBorn: 2001,
        number: null,
        image: null,
        isCaptain: false,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Hugo",
        lastName: "Díaz",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 12,
  ownGoalsInFavor: 1,
  penaltyGoals: 0,
  isWinner: false,
};

const Cobras = {
  team: Team__Cobras,
  playingPlayers: [
    {
      player: {
        firstName: "",
        lastName: "",
        nickName: null,
        image: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 0,
  penaltyGoals: 0,
  isWinner: false,
};

const LeonesFC = {
  team: Team__LeonesFC,
  playingPlayers: [
    {
      player: {
        firstName: "Gustavo",
        lastName: "Xalate",
        nickName: "Tavo",
        yearBorn: 2005,
        number: null,
      },

      goals: 5,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Luis",
        lastName: "Cardenas",
        nickName: "Grillo",
        yearBorn: 2005,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Marcelino",
        lastName: "Macario",
        nickName: "Machis",
        yearBorn: 1992,
        number: null,
      },

      goals: 0,
      fouls_commited: 2,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Brian",
        lastName: "Favela",
        nickName: "Chivo",
        yearBorn: 2007,
        number: null,
      },

      goals: 0,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Diego",
        lastName: "Polanco",
        nickName: null,
        yearBorn: 2003,
        number: null,
      },

      goals: 1,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "David",
        lastName: "Hernández",
        nickName: null,
        yearBorn: 2008,
        number: null,
      },

      goals: 0,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 6,
  penaltyGoals: 0,
  isWinner: false,
};

const DeportivoMaster = {
  team: Team__DeportivoMaster,
  playingPlayers: [
    {
      player: {
        firstName: "Edwin",
        lastName: "Mendoza",
        nickName: null,
        yearBorn: 2007,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },

    {
      player: {
        firstName: "Jose",
        lastName: "",
        nickName: null,
        image: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Jorge",
        lastName: "",
        nickName: null,
        image: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Tomas",
        lastName: "",
        nickName: null,
        image: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Memo",
        lastName: "",
        nickName: null,
        image: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Pedro",
        lastName: "Sanches",
        nickName: null,
        yearBorn: 2007,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Aldair",
        lastName: "Hernandez",
        nickName: null,
        yearBorn: 1998,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Omar",
        lastName: "Perez",
        nickName: null,
        yearBorn: 2008,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Pale",
        lastName: "",
        nickName: null,
        image: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Joel",
        lastName: "",
        nickName: null,
        image: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 0,
  penaltyGoals: 0,
  isWinner: false,
};

export const Jornada1 = {
  jornadaPosition: 1,
  matches: [
    {
      team1: Piratas,
      team2: DeportivoMaster,

      isCompleted: true,
      isPlaying: false,
      day: "Jueves",
      date: "2024-07-11",
      time: "21:00:00",
      location: "Cancha Gerardo Gonzales",
    },

    {
      team1: VeterinariaSabata,
      team2: LeonesFC,

      isCompleted: true,
      isPlaying: false,
      day: "Jueves",
      date: "2024-07-11",
      time: "22:00:00",
      location: "Cancha Gerardo Gonzales",
    },

    // =====================================
    // =====================================

    {
      team1: RSD,
      team2: Brothers,

      isCompleted: true,
      isPlaying: false,
      day: "Viernes",
      date: "2024-07-12",
      time: "21:00:00",
      location: "Cancha Gerardo Gonzales",
    },

    {
      team1: Astros,
      team2: Vagos,

      isCompleted: true,
      isPlaying: false,
      day: "Viernes",
      date: "2024-07-12",
      time: "22:00:00",
      location: "Cancha Gerardo Gonzales",
    },
  ],
};
