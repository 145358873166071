import { Team__NogalerasJR } from "../teams/NogalerasJR";
import { Team__Panthers } from "../teams/Panthers";

const Panthers = {
  team: Team__Panthers,
  playingPlayers: [],

  goals: 32,
  penaltyGoals: 0,
  isWinner: false,
};

const NogalerasJR = {
  team: Team__NogalerasJR,
  playingPlayers: [],

  goals: 27,
  penaltyGoals: 0,
  isWinner: false,
};

export const Jornada1 = {
  jornadaPosition: 1,
  matches: [
    {
      team1: Panthers,
      team2: NogalerasJR,

      isCompleted: true,
      isPlaying: false,
      day: "Lunes",
      date: "2024-07-29",
      time: "09:00:00",
      location: "Centro Recreativo Lebaron",
    },
  ],
};
