// Owner: Aaron Stubbs

export const Team__Tec = {
  name: "Tec",
  logoImage: null,
  teamImage: null,
  teamLogoColor: null,
  teamJerseyColor: "#FFBA00",

  // Stats
  gamesWon: 1 + 1,
  gamesLost: 1,
  gamesTied: 0,
  gamesTiedWon: 0,

  ownGoalsInFavor: 0,
  // goalsScoredAgainst: 26 + 48 + 37,
  goalsScoredAgainst: 48 + 37,
  defaultGoals: 0,

  isChampion: false,
  isEliminated: false,

  players: [
    {
      // goalsScored: 47 + 55 + 34,
      goalsScored: 55 + 34,
    },
  ],
};
