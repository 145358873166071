import React, { useEffect } from "react";

function GiveAdminID() {
  const adminDataPassId = JSON.parse(localStorage.getItem("adminDataPass"));

  useEffect(() => {
    localStorage.setItem("adminDataPass", '"124"');
  }, []);

  return <div className="giveAdminId">{adminDataPassId}:</div>;
}

export default GiveAdminID;
