import React from "react";
import { Link } from "react-router-dom";

import "../../../styles/components/NavigationComponents/Tournament/LargeSidebar.scss";

function LargeSidebar({ currentPageUrl }) {
  return (
    <div className="largeSidebar largeSidebar__MainPages">
      <div className="topLinksContainer">
        <Link to={`/`} className={currentPageUrl("/")}>
          <div className="iconContainer">
            <span class="material-symbols-rounded">trophy</span>
          </div>
          <div className="linkText">Torneos</div>
        </Link>
        <Link
          to={`https://deportesarreola.netlify.app/`}
          target="_blank"
          className={currentPageUrl("/comprar")}
        >
          <div className="iconContainer">
            <span class="material-symbols-rounded">local_mall</span>
          </div>
          <div className="linkText">Comprar</div>
        </Link>
      </div>

      <div className="extraInfoContainer">
        <div className="extraInfoBox">
          Para mas informacion puedes contactar:
          <div>
            <a
              href="https://api.whatsapp.com/send?phone=526361104576"
              target="_blank"
            >
              Martin Arreola jr.
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LargeSidebar;
