import { Team__Nogaleras } from "./teams/Nogaleras";
import { Team__NogalerasJR } from "./teams/NogalerasJR";
import { Team__Panthers } from "./teams/Panthers";
import { Team__Raptors } from "./teams/Raptors";

const GroupA = [
  Team__Nogaleras,
  Team__NogalerasJR,
  Team__Panthers,
  Team__Raptors,
];

export const Groups__WOMEN = [GroupA];
