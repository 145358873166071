import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { tournaments_data } from "../__FakeDatabase/Tournaments_data";

import "../styles/pages/SchedulePage.scss";

import TournamentNavigationComponents from "../components/NavigationComponents/Tournament/TournamentNavigationComponents";
import PromoteMySelf from "../components/PromoteMySelf";
import Match from "../components/Matches/Match";
import {
  listJornadaMatches,
  listJornadas,
} from "../redux/actions/jornadaActions";

function SchedulePage() {
  const dispatch = useDispatch();

  const tournamentDetails = useSelector((state) => state.tournamentDetails);
  const { tournament } = tournamentDetails;

  const jornadasDetails = useSelector((state) => state.jornadasList);
  const { loading, error, jornadas } = jornadasDetails;

  useEffect(() => {
    dispatch(listJornadas(tournament.URL_name));
  }, [dispatch]);

  // const name = useParams();
  // const tournament = tournaments_data.find((p) => p.URL_name === name.URL_name);

  // const [matchModalOpen, setMatchModalOpen] = useState(false);
  // const [selectedMatch, setSelectedMatch] = useState(null);

  // const handleSelectedMatch = (match) => {
  //   setMatchModalOpen(true);
  //   setSelectedMatch(match);

  //   document.body.style.overflow = "hidden";
  // };

  // const handleSelectedMatchClose = () => {
  //   setMatchModalOpen(false);
  //   setSelectedMatch(null);

  //   document.body.style.overflow = "auto";
  // };

  return (
    <TournamentNavigationComponents tournament={tournament}>
      <div className="schedulePage">
        {jornadas?.map((jornada, index) => {
          return (
            <Jornada jornada={jornada} index={index} tournament={tournament} />
          );
        })}

        {/* {tournament.jornadaSchedule.map((jornada, index) => {
          return (
            <div
              className={`jornadaContainer jornadaContainer__${tournament.color}`}
              key={index}
            >
              <div className="title">
                <div className="titleTxt">Jornada {index + 1}</div>
              </div>

              {jornada.matches.map((match, index) => {
                return <Match match={match} index={index} />;
              })}

              {jornada.doesntPlay ? (
                <div className="teamRest" key={index}>
                  Descansa: {jornada.doesntPlay}
                </div>
              ) : null}
            </div>
          );
        })} */}
      </div>

      <PromoteMySelf />
    </TournamentNavigationComponents>
  );
}

export default SchedulePage;

const Jornada = ({ jornada, index, tournament }) => {
  // const dispatch = useDispatch();

  // const jornadaMatchesDetails = useSelector(
  //   (state) => state.jornadaMatchesList.jornadaMatches[0]
  // );
  // const { loading, error, jornadaDetails } = jornadaMatchesDetails;

  // useEffect(() => {
  //   dispatch(listJornadaMatches(tournament.URL_name, index));
  // }, []);

  return (
    <div
      className={`jornadaContainer jornadaContainer__${tournament.pageColorVersion}`}
      key={index}
    >
      <div className="title">
        <div className="titleTxt">Jornada {index + 1}</div>
      </div>
      {jornada?.matches.map((match, index) => {
        return (
          <>
            <Match match={match} index={index} />
          </>
        );
      })}

      {jornada?.doesntPlay ? (
        <div className="teamRest" key={index}>
          Descansa: {jornada.doesntPlay}
        </div>
      ) : null}
    </div>
  );
};
