import axios from "axios";

import {
  TOURNAMENT_LIST_REQUEST,
  TOURNAMENT_LIST_SUCCESS,
  TOURNAMENT_LIST_FAIL,
  //
  TOURNAMENT_DETAILS_REQUEST,
  TOURNAMENT_DETAILS_SUCCESS,
  TOURNAMENT_DETAILS_FAIL,
  //
  MY_TOURNAMENTS_LIST_REQUEST,
  MY_TOURNAMENTS_LIST_SUCCESS,
  MY_TOURNAMENTS_LIST_FAIL,
} from "../constants/tournamentConstants";

export const listTournaments = () => async (dispatch) => {
  try {
    dispatch({ type: TOURNAMENT_LIST_REQUEST });

    const { data } = await axios.get("/api/tournaments/");

    dispatch({
      type: TOURNAMENT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TOURNAMENT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.reponse.data.message
          : error.message,
    });
  }
};

export const listTournamentDetails = (URL_name) => async (dispatch) => {
  try {
    dispatch({ type: TOURNAMENT_DETAILS_REQUEST });

    const { data } = await axios.get(`/api/tournaments/${URL_name}`);

    dispatch({
      type: TOURNAMENT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TOURNAMENT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.reponse.data.message.detail
          : error.message,
    });
  }
};

export const listMyTournaments = (user_id) => async (dispatch) => {
  try {
    dispatch({ type: MY_TOURNAMENTS_LIST_REQUEST });

    const { data } = await axios.get(`/api/tournaments/user/${user_id}`);

    dispatch({
      type: MY_TOURNAMENTS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MY_TOURNAMENTS_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.reponse.data.message
          : error.message,
    });
  }
};
