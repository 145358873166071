import { Team__Aliados } from "./teams/Aliados";
import { Team__Cerveceros_MAIN } from "./teams/Cerveceros_MAIN";
import { Team__Chiles } from "./teams/Chiles";

import { Team__MeanMachine } from "./teams/MeanMachine";

import { Team__Nogaleros } from "./teams/Nogaleros";

import { Team__Raptors } from "./teams/Raptors";
import { Team__Tec } from "./teams/Tec";
import { Team__XLobos } from "./teams/XLobos";

const GroupA = [Team__Aliados, Team__Cerveceros_MAIN, Team__Chiles, Team__Tec];

const GroupB = [
  Team__MeanMachine,
  Team__Nogaleros,
  Team__Raptors,
  Team__XLobos,
];

export const Groups__FIRST_DIVISION = [GroupA, GroupB];
