import { Team__JohnsonBranders } from "../teams/JohnsonBranders";
import { Team__Natalias } from "../teams/Natalias";
import { Team__PecanBros } from "../teams/PecanBros";
import { Team__Potros } from "../teams/Potros";
import { Team__Thunder } from "../teams/Thunder";

const JohnsonBranders = {
  team: Team__JohnsonBranders,
  playingPlayers: [],

  goals: 24,
  penaltyGoals: 0,
  isWinner: false,
};

const Natalias = {
  team: Team__Natalias,
  playingPlayers: [],

  goals: 27,
  penaltyGoals: 0,
  isWinner: false,
};

const Potros = {
  team: Team__Potros,
  playingPlayers: [],

  goals: 43,
  penaltyGoals: 0,
  isWinner: false,
};

const PecanBros = {
  team: Team__PecanBros,
  playingPlayers: [],

  goals: 33,
  penaltyGoals: 0,
  isWinner: false,
};

export const Jornada5 = {
  jornadaPosition: 5,
  matches: [
    {
      team1: Natalias,
      team2: JohnsonBranders,

      isCompleted: true,
      isPlaying: false,
      day: "Viernes",
      date: "2024-08-02",
      time: "12:00:00",
      location: "Centro Recreativo Lebaron",
    },
    {
      team1: PecanBros,
      team2: Potros,

      isCompleted: true,
      isPlaying: false,
      day: "Viernes",
      date: "2024-08-02",
      time: "13:00:00",
      location: "Centro Recreativo Lebaron",
    },
  ],
};
