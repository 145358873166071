// Owner: Diego Cruz

export const Team__Piratas = {
  name: "Piratas",
  logoImage: "/teamLogos/Piratas.png",
  teamImage: null,
  teamLogoColor: "#3C3D42",
  teamJerseyColor: "#ffffff",

  // Stats
  gamesWon: 4,
  gamesLost: 0,
  gamesTied: 0,
  gamesTiedWon: 0,

  ownGoalsInFavor: 1,
  goalsScoredAgainst: 0 + 2 + 2 + 2,
  defaultGoals: 0,

  isChampion: false,
  isEliminated: false,

  players: [
    {
      firstName: "Diego",
      lastName: "Cruz",
      nickName: null,
      yearBorn: 1996,
      number: null,
      image: null,
      isCaptain: true,

      // Stats
      matchesPlayed: 0 + 0 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Samuel",
      lastName: "Esquivel",
      nickName: null,
      yearBorn: 2006,
      number: null,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 1 + 1 + 1 + 1,
      goalsScored: 1 + 1,
      foulsCommited: 1 + 1,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Jorge",
      lastName: "Esquivel",
      nickName: null,
      yearBorn: 1999,
      number: null,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 1 + 1 + 1 + 1,
      goalsScored: 10 + 6 + 8 + 3,
      foulsCommited: 2,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Alfredo",
      lastName: "Delgado",
      nickName: null,
      yearBorn: 1998,
      number: null,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 1 + 1 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Martin",
      lastName: "Castillo",
      nickName: null,
      yearBorn: 2001,
      number: null,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 1 + 1 + 1 + 1,
      goalsScored: 1,
      foulsCommited: 2,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Miguel",
      lastName: "Castillo",
      nickName: null,
      yearBorn: 2002,
      number: null,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 0 + 1 + 1,
      goalsScored: 1,
      foulsCommited: 2,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Luis",
      lastName: "Dominguez",
      nickName: null,
      yearBorn: 1990,
      number: null,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 1 + 0 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Daniel",
      lastName: "Ontiveros",
      nickName: null,
      yearBorn: 2006,
      number: null,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 0 + 1 + 1 + 1,
      goalsScored: 1 + 1,
      foulsCommited: 1 + 1,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Hugo",
      lastName: "Díaz",
      nickName: null,
      yearBorn: 2004,
      number: null,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 1 + 1 + 1 + 1,
      goalsScored: 1,
      foulsCommited: 2 + 1,
      yellowCards: 0,
      redCards: 0,
    },
  ],
};
