// Owner: Machis

export const Team__Pumbas = {
  name: "Pumbas",
  logoImage: null,
  teamImage: null,
  teamLogoColor: "#ffffff",
  teamJerseyColor: "#ffffff",

  // Stats
  gamesWon: 2,
  gamesLost: 2,
  gamesTied: 0,
  gamesTiedWon: 0,

  ownGoalsInFavor: 0,
  goalsScoredAgainst: 2 + 7 + 1,
  defaultGoals: 2,

  isChampion: false,
  isEliminated: false,

  players: [
    {
      firstName: "Gaby",
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 0 + 1 + 1,
      goalsScored: 1 + 1,
      foulsCommited: 1 + 1,
      yellowCards: 0,
      redCards: 0,
    },
    {
      firstName: "Karina",
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 0 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },
    {
      firstName: "Paulina",
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 0 + 1 + 1,
      goalsScored: 2 + 1,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },
    {
      firstName: "Adri",
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 0 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 1 + 1,
      yellowCards: 0,
      redCards: 0,
    },
    {
      firstName: "Yiomara",
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 0 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 1,
      yellowCards: 0,
      redCards: 0,
    },
    {
      firstName: "Magaly",
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 0 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },
    {
      firstName: "Denise",
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 0 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },
    {
      firstName: "Karime",
      lastName: "Dominguez",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 0 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Veronica",
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 0 + 0 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },
  ],
};
