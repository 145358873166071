import Logo from "./Logo.webp";
import Logo_B from "./2024Logo.png";

// import Wallpaper_A from "./mens__first_division/images/wallpaper.jpg";
import Wallpaper_A from "./mens__first_division/images/D1_MENS.jpg";

import Wallpaper_B from "./mens__a/images/FAIR_MENS_2.0.jpg";

import WomensWallpaper from "./FAIR_WOMENS.jpg";

import { Groups } from "./mens__a/GroupsList";
import { ActiveJornada, JornadaSchedule } from "./mens__a/JornadaSchedule";
import { teamList } from "./mens__a/TeamsList";
import { ActiveJornada_FIRST_DIVISION } from "./mens__first_division/JornadaSchedule";
import { Groups__FIRST_DIVISION } from "./mens__first_division/GroupsList";
import { Groups__WOMEN } from "./womens/GroupsList";
import { ActiveJornada_WOMEN } from "./womens/JornadaSchedule";

// ========================================================
// ==DDDDDD========AAAA=====TTTTTTTTTTTT========AAAA=======
// ==DD===DD======AA==AA=========TT============AA==AA======
// ==DD====DD=====A====A=========TT============A====A======
// ==DD=====DD===AA====AA========TT===========AA====AA=====
// ==DD=====DD===AAAAAAAA========TT===========AAAAAAAA=====
// ==DD====DD====A======A========TT===========A======A=====
// ==DD===DD====AA======AA=======TT==========AA======AA====
// ==DDDDDD=====AA======AA=======TT==========AA======AA====
// ========================================================

export const basketball_tournaments_data = [
  {
    // Info
    name: "Torneo de Basketbol: 1ERA Fuerza",
    URL_name: "feria-de-la-amistad-mens-basketball-first-division",
    thumbnailImage: Wallpaper_A,
    tournamentLogoImage: Logo,
    heroSectionImage: Wallpaper_A,

    location: "Lebaron",

    // Host
    host: "Feria de la Amistad",
    hostLogoImage: Logo_B,

    // Config
    sex: "male",
    pageColorVersion: "red",

    tournamentType: "groups_and_playoff",
    teamsToSurviveGroup: 2,
    pointsPerWin: 3,
    pointsPerDraw: 1,
    pointsPerDrawWin: 1,
    pointsPerLoss: 0,

    isCompleted: false,
    isLocalData: true,

    teams: teamList,
    groups: Groups__FIRST_DIVISION,
    jornadas: JornadaSchedule,
    activeJornadas: ActiveJornada_FIRST_DIVISION,
  },

  {
    // Info
    name: "Torneo de Basketbol: 2DA Fuerza",
    URL_name: "feria-de-la-amistad-mens-basketball",
    thumbnailImage: Wallpaper_B,
    tournamentLogoImage: Logo,
    heroSectionImage: Wallpaper_B,

    location: "Lebaron",

    // Host
    host: "Feria de la Amistad",
    hostLogoImage: Logo_B,

    // Config
    sex: "male",
    pageColorVersion: "red",

    tournamentType: "groups_and_playoff",
    teamsToSurviveGroup: 2,
    pointsPerWin: 3,
    pointsPerDraw: 1,
    pointsPerDrawWin: 1,
    pointsPerLoss: 0,

    isCompleted: false,
    isLocalData: true,

    teams: teamList,
    groups: Groups,
    jornadas: JornadaSchedule,
    activeJornadas: ActiveJornada,
  },

  {
    // Info
    name: "Torneo de Basketbol: Feminil",
    URL_name: "feria-de-la-amistad-womens-basketball",
    thumbnailImage: WomensWallpaper,
    tournamentLogoImage: Logo,
    heroSectionImage: WomensWallpaper,

    location: "Lebaron",

    // Host
    host: "Feria de la Amistad",
    hostLogoImage: Logo_B,

    // Config
    sex: "female",
    pageColorVersion: "red",

    tournamentType: "groups_and_playoff",
    teamsToSurviveGroup: 4,
    pointsPerWin: 3,
    pointsPerDraw: 1,
    pointsPerDrawWin: 1,
    pointsPerLoss: 0,

    isCompleted: false,
    isLocalData: true,

    teams: teamList,
    groups: Groups__WOMEN,
    jornadas: JornadaSchedule,
    activeJornadas: ActiveJornada_WOMEN,
  },
];
