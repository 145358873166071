// Owner: Jose ???

import Logo from "./images/teamLogos/DeportivoMaster.png";

import PFP__AldairHernandez from "./images/playerImages/DeportivoMaster/AldairHernandez.jpg";
import PFP__AngelMoreno from "./images/playerImages/DeportivoMaster/AngelMoreno.jpg";
import PFP__Edwin from "./images/playerImages/DeportivoMaster/Edwin.jpg";
import PFP__OmarPerez from "./images/playerImages/DeportivoMaster/OmarPerez.jpg";
import PFP__PedroSanches from "./images/playerImages/DeportivoMaster/PedroSanches.jpg";

export const Team__DeportivoMaster = {
  name: "Deportivo Master",
  logoImage: Logo,
  teamImage: null,
  teamLogoColor: "#3F657D",
  teamJerseyColor: "#ffffff",

  // Stats
  gamesWon: 1,
  gamesLost: 3,
  gamesTied: 0,
  gamesTiedWon: 0,

  ownGoalsInFavor: 0,
  goalsScoredAgainst: 12 + 7 + 17 + 2,
  defaultGoals: 0,

  isChampion: false,
  isEliminated: false,

  players: [
    {
      firstName: "Aldair",
      lastName: "Hernandez",
      nickName: null,
      yearBorn: 1998,
      number: null,
      image: PFP__AldairHernandez,

      // Stats
      matchesPlayed: 1 + 1 + 1,
      goalsScored: 1,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Ángel",
      lastName: "Moreno",
      nickName: null,
      yearBorn: 2007,
      number: null,
      image: PFP__AngelMoreno,

      // Stats
      matchesPlayed: 0,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Edwin",
      lastName: "Mendoza",
      nickName: null,
      yearBorn: 2007,
      number: null,
      image: PFP__Edwin,

      // Stats
      matchesPlayed: 1 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },
    {
      firstName: "Omar",
      lastName: "Perez",
      nickName: null,
      yearBorn: 2008,
      number: null,
      image: PFP__OmarPerez,

      // Stats
      matchesPlayed: 1 + 0 + 1,
      goalsScored: 2,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Pedro",
      lastName: "Sanches",
      nickName: null,
      yearBorn: 2007,
      number: null,
      image: PFP__PedroSanches,

      // Stats
      matchesPlayed: 1 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    // ===========================================
    // ========= Non Registered Players ==========
    // ===========================================

    {
      firstName: "Jose",
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 1 + 1 + 1,
      goalsScored: 1,
      foulsCommited: 1 + 1,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Jorge",
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 1 + 0 + 1,
      goalsScored: 5 + 2,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Tomas",
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 1 + 0 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Memo",
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Pale",
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 1 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Joel",
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 1,
      goalsScored: 0,
      foulsCommited: 1,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Daniel",
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 0 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 2,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Luis",
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 0 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 1,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Toño",
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 0 + 1,
      goalsScored: 0,
      foulsCommited: 1,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Richi",
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 0 + 0 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },
  ],
};
