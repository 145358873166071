import { Jornada1 } from "./Jornadas/Jornada_1";
import { Jornada2 } from "./Jornadas/Jornada_1 copy";
import { Jornada4 } from "./Jornadas/Jornada_4";
import { Jornada5 } from "./Jornadas/Jornada_5";
import { Jornada6 } from "./Jornadas/Jornada_6";
import { Team__Potros } from "./teams/Potros";
import { Team__Thunder } from "./teams/Thunder";

const Thunder = {
  team: Team__Thunder,
  playingPlayers: [],

  goals: 27,
  penaltyGoals: 0,
  isWinner: false,
};

const Potros = {
  team: Team__Potros,
  playingPlayers: [],

  goals: 43,
  penaltyGoals: 0,
  isWinner: false,
};

export const Jornada3 = {
  jornadaPosition: 3,
  matches: [
    {
      team1: Thunder,
      team2: Potros,

      isCompleted: true,
      isPlaying: false,
      day: "Miercoles",
      date: "2024-07-31",
      time: "18:00:00",
      location: "Centro Recreativo Lebaron",
    },
  ],
};

export const ActiveJornada = [
  Jornada1,
  Jornada2,
  Jornada3,
  Jornada4,
  Jornada5,
  Jornada6,
];

export const JornadaSchedule = [Jornada1];
