import { Team__Aliados } from "../teams/Aliados";
import { Team__Cerveceros_MAIN } from "../teams/Cerveceros_MAIN";
import { Team__Chiles } from "../teams/Chiles";
import { Team__MeanMachine } from "../teams/MeanMachine";
import { Team__Nogaleros } from "../teams/Nogaleros";
import { Team__Raptors } from "../teams/Raptors";
import { Team__Tec } from "../teams/Tec";
import { Team__XLobos } from "../teams/XLobos";

const Aliados = {
  team: Team__Aliados,
  playingPlayers: [],

  goals: 58,
  penaltyGoals: 0,
  isWinner: false,
};

const Cerveceros = {
  team: Team__Cerveceros_MAIN,
  playingPlayers: [],

  goals: 37,
  penaltyGoals: 0,
  isWinner: false,
};

const Chiles = {
  team: Team__Chiles,
  playingPlayers: [],

  goals: 48,
  penaltyGoals: 0,
  isWinner: false,
};

const MeanMachine = {
  team: Team__MeanMachine,
  playingPlayers: [],

  goals: 53,
  penaltyGoals: 0,
  isWinner: false,
};

const Nogaleros = {
  team: Team__Nogaleros,
  playingPlayers: [],

  goals: 42,
  penaltyGoals: 0,
  isWinner: false,
};

const Raptors = {
  team: Team__Raptors,
  playingPlayers: [],

  goals: 40,
  penaltyGoals: 0,
  isWinner: false,
};

const Tec = {
  team: Team__Tec,
  playingPlayers: [],

  goals: 34,
  penaltyGoals: 0,
  isWinner: false,
};

const XLobos = {
  team: Team__XLobos,
  playingPlayers: [],

  goals: 44,
  penaltyGoals: 0,
  isWinner: false,
};

export const Jornada4 = {
  jornadaPosition: 4,
  matches: [
    {
      team1: Tec,
      team2: Cerveceros,

      isCompleted: true,
      isPlaying: false,
      day: "Jueves",
      date: "2024-08-01",
      time: "17:00:00",
      location: "Centro Recreativo Lebaron",
    },
    {
      team1: MeanMachine,
      team2: Nogaleros,

      isCompleted: true,
      isPlaying: false,
      day: "Jueves",
      date: "2024-08-01",
      time: "18:00:00",
      location: "Centro Recreativo Lebaron",
    },
    {
      team1: Aliados,
      team2: Chiles,

      isCompleted: true,
      isPlaying: false,
      day: "Jueves",
      date: "2024-08-01",
      time: "19:00:00",
      location: "Centro Recreativo Lebaron",
    },
    {
      team1: XLobos,
      team2: Raptors,

      isCompleted: true,
      isPlaying: false,
      day: "Jueves",
      date: "2024-08-01",
      time: "20:00:00",
      location: "Centro Recreativo Lebaron",
    },
  ],
};
