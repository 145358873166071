// Owner: Diego Vega

export const Team__Vagos = {
  name: "Vagos",
  logoImage: null,
  teamImage: null,
  teamLogoColor: "#ffffff",
  teamJerseyColor: "#ffffff",

  // Stats
  gamesWon: 2,
  gamesLost: 2,
  gamesTied: 0,
  gamesTiedWon: 0,

  ownGoalsInFavor: 0,
  goalsScoredAgainst: 2 + 14 + 3,
  defaultGoals: 2,

  isChampion: false,
  isEliminated: false,

  players: [
    {
      firstName: "Chayo",
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 0 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },
    {
      firstName: "Meny",
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 0 + 1 + 1,
      goalsScored: 1,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },
    {
      firstName: "Luis",
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 0 + 1 + 1 + 1,
      goalsScored: 1 + 2,
      foulsCommited: 1,
      yellowCards: 0,
      redCards: 0,
    },
    {
      firstName: "Johny",
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 0 + 1 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 1 + 1,
      yellowCards: 0,
      redCards: 0,
    },
    {
      firstName: "Jordy",
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 0 + 1 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },
    {
      firstName: "Miguel",
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 0 + 1 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 1,
      yellowCards: 0,
      redCards: 0,
    },
    {
      firstName: "Alfredo",
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 0 + 1 + 1 + 1,
      goalsScored: 2 + 4,
      foulsCommited: 2,
      yellowCards: 0,
      redCards: 0,
    },
    {
      firstName: "Mauricio",
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 0 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 1,
      yellowCards: 0,
      redCards: 0,
    },

    // ===========================================
    // ========= Non Registered Players ==========
    // ===========================================

    {
      firstName: "Manuel",
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 0 + 0 + 0 + 1,
      goalsScored: 2,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Daniel",
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 0 + 0 + 0 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Joel",
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 0 + 0 + 0 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },
  ],
};
