import { Team__Adrianes } from "../teams/Adrianes";
import { Team__Cerveceros } from "../teams/Cerveceros";
import { Team__Joeles } from "../teams/Joeles";
import { Team__JohnsonBranders } from "../teams/JohnsonBranders";
import { Team__Llamas } from "../teams/Llamas";
import { Team__Natalias } from "../teams/Natalias";
import { Team__PecanBros } from "../teams/PecanBros";
import { Team__Potros } from "../teams/Potros";
import { Team__TheTakeOvers } from "../teams/TheTakeOvers";
import { Team__Thunder } from "../teams/Thunder";

const Adrianes = {
  team: Team__Adrianes,
  playingPlayers: [],

  goals: 34,
  penaltyGoals: 0,
  isWinner: false,
};

const Cerveceros = {
  team: Team__Cerveceros,
  playingPlayers: [],

  goals: 17,
  penaltyGoals: 0,
  isWinner: false,
};

const Joeles = {
  team: Team__Joeles,
  playingPlayers: [],

  goals: 30,
  penaltyGoals: 0,
  isWinner: false,
};

const JohnsonBranders = {
  team: Team__JohnsonBranders,
  playingPlayers: [],

  goals: 29,
  penaltyGoals: 0,
  isWinner: false,
};

const Natalias = {
  team: Team__Natalias,
  playingPlayers: [],

  goals: 38,
  penaltyGoals: 0,
  isWinner: false,
};

const Thunder = {
  team: Team__Thunder,
  playingPlayers: [],

  goals: 33,
  penaltyGoals: 0,
  isWinner: false,
};

const Llamas = {
  team: Team__Llamas,
  playingPlayers: [],

  goals: 41,
  penaltyGoals: 0,
  isWinner: false,
};

const PecanBros = {
  team: Team__PecanBros,
  playingPlayers: [],

  goals: 30,
  penaltyGoals: 0,
  isWinner: false,
};

const TheTakeOvers = {
  team: Team__TheTakeOvers,
  playingPlayers: [],

  goals: 16,
  penaltyGoals: 0,
  isWinner: false,
};

const Potros = {
  team: Team__Potros,
  playingPlayers: [],

  goals: 32,
  penaltyGoals: 0,
  isWinner: false,
};

export const Jornada2 = {
  jornadaPosition: 2,
  matches: [
    {
      team1: Natalias,
      team2: Thunder,

      isCompleted: true,
      isPlaying: false,
      day: "Martes",
      date: "2024-07-30",
      time: "11:00:00",
      location: "Centro Recreativo Lebaron",
    },
    {
      team1: Llamas,
      team2: JohnsonBranders,

      isCompleted: true,
      isPlaying: false,
      day: "Martes",
      date: "2024-07-30",
      time: "12:00:00",
      location: "Centro Recreativo Lebaron",
    },
    {
      team1: Potros,
      team2: Adrianes,

      isCompleted: true,
      isPlaying: false,
      day: "Martes",
      date: "2024-07-30",
      time: "13:00:00",
      location: "Centro Recreativo Lebaron",
    },
    {
      team1: PecanBros,
      team2: Cerveceros,

      isCompleted: true,
      isPlaying: false,
      day: "Martes",
      date: "2024-07-30",
      time: "14:00:00",
      location: "Centro Recreativo Lebaron",
    },
    {
      team1: Joeles,
      team2: Natalias,

      isCompleted: true,
      isPlaying: false,
      day: "Martes",
      date: "2024-07-30",
      time: "15:00:00",
      location: "Centro Recreativo Lebaron",
    },
    {
      team1: TheTakeOvers,
      team2: Llamas,

      isCompleted: true,
      isPlaying: false,
      day: "Martes",
      date: "2024-07-30",
      time: "16:00:00",
      location: "Centro Recreativo Lebaron",
    },
  ],
};
