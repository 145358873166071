import React from "react";
import { Link } from "react-router-dom";

import "../../../styles/components/NavigationComponents/Tournament/SmallSidebar.scss";

function SmallSidebar({ currentPageUrl }) {
  return (
    <div className={`smallSidebar`}>
      <div className="linksContainer">
        <Link to={`/`} className={currentPageUrl("")}>
          <div className="iconContainer">
            <span class="material-symbols-rounded">trophy</span>
          </div>
          <div className="linkTextContainer">
            <div className="linkText">Torneos</div>
          </div>
        </Link>

        <Link
          to={`https://deportesarreola.netlify.app/`}
          target="_blank"
          className={currentPageUrl("comprar")}
        >
          <div className="iconContainer">
            <span class="material-symbols-rounded">local_mall</span>
          </div>
          <div className="linkTextContainer">
            <div className="linkText">Comprar</div>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default SmallSidebar;
