import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// scss
import "../../styles/pages/TeamInfoPage.scss";

import { tournaments_data } from "../../__FakeDatabase/Tournaments_data";

// Componentes
import DefaultImage2 from "../../images/PlayerCards/_DefaultImage2.jpeg";
import PlayerStatsCard from "../../components/Cards/PlayerStatsCard";
import BetaTournamentNavigationComponents from "../../components/BetaComponents/Tournament/TournamentNavigationComponents";
import TeamLogoIcon from "../../components/TeamLogoIcon";

function BetaTeamInfoPage() {
  const { id, team_id } = useParams();
  const tournament = tournaments_data.find((p) => p.URL_name === id);
  const team = tournament.teams.find((team) => team.name === team_id);

  const [showSplash, setShowSplash] = useState(true);
  const [textColor, setTextColor] = useState("");

  useEffect(() => {
    if (showSplash) {
      const timer = setTimeout(() => {
        setShowSplash(false);
      }, 1500);

      return () => clearTimeout(timer);
    }
  }, [showSplash]);

  const goalsSortedPlayers = team?.players
    .slice()
    .sort((a, b) => b.goalsScored - a.goalsScored);

  useEffect(() => {
    // Function to calculate the brightness of a color
    if (team.teamLogoColor) {
      function getBrightness(color) {
        // Convert color to RGB
        const rgb = parseInt(color.slice(1), 16);
        const r = (rgb >> 16) & 0xff;
        const g = (rgb >> 8) & 0xff;
        const b = (rgb >> 0) & 0xff;

        // Calculate brightness using the formula (0.299*R + 0.587*G + 0.114*B)
        const brightness = 0.299 * r + 0.587 * g + 0.114 * b;
        return brightness;
      }

      const backgroundColorBrightness = getBrightness(team.teamLogoColor);

      // Check if the background color is dark or light
      if (backgroundColorBrightness >= 128) {
        setTextColor("black"); // Set text color to black for light backgrounds
      } else {
        setTextColor("white"); // Set text color to white for dark backgrounds
      }
    } else {
      setTextColor("black");
    }
  }, [team.teamLogoColor]);

  return (
    <BetaTournamentNavigationComponents tournament={tournament}>
      {showSplash && <TeamSplashScreen team={team} />}
      <div
        className={`teamInfoPage teamInfoPage__${tournament.pageColorVersion}`}
      >
        {team.teamImage ? (
          <div
            className="teamHeroSection"
            style={{ backgroundColor: team.teamLogoColor }}
          >
            <div className="teamImageContainerWrapper">
              <div className="teamImageContainer">
                <img src={team?.teamImage} alt="" className="teamImage" />
                <div className="backgroundGradient"></div>
                <div className="teamNameAndLogo">
                  {team?.logoImage ? (
                    <img
                      src={team?.logoImage}
                      className="teamLogo"
                      draggable="false"
                    />
                  ) : null}
                  <div className="teamName">{team.name}</div>
                </div>
              </div>
            </div>
            <div className="roundedThingInBackground"></div>
          </div>
        ) : (
          // <div className="topSectionWrapper">
          //   <div className="topSection">
          //     {/* <div className="backgroundImgContainer"> */}
          //     <img src={team.teamImage} alt="" className="backgroundImg" />
          //     {/* </div> */}
          //     <div className="backgroundGradient"></div>
          //     <div className="teamNameContainer">
          //       {team.logoImage ? (
          //         <img src={team.logoImage} alt="" draggable="false" />
          //       ) : null}
          //       <div className="teamName">{team.name}</div>
          //     </div>
          //   </div>
          // </div>
          <div className="teamNameContainer">
            {team.logoImage ? (
              <img src={team.logoImage} alt="" draggable="false" />
            ) : null}
            <div className="teamName">{team.name}</div>
          </div>
        )}

        <div className="playerStatsSection">
          {team.players.length > 0 ? (
            <div className="playersContainer">
              {goalsSortedPlayers.map((player, index) => (
                <div className="playerCard" key={index}>
                  <div className="topSection">
                    <div
                      className={`imgContainer ${
                        player.isMartinArreola &&
                        "imgContainer__isMartinArreola"
                      }`}
                    >
                      <img
                        src={player.image ? player.image : DefaultImage2}
                        alt=""
                        draggable="false"
                        className="playerImg"
                      />

                      {player.isMartinArreola && (
                        <div className="creatorOfWebsiteOverlay"></div>
                      )}
                    </div>

                    <div className="playerInfo">
                      <div className="playerName">
                        {player.firstName} {player.lastName}
                      </div>
                      {player.nickName && (
                        <div className="playerNickname">
                          "{player.nickName}"
                        </div>
                      )}
                      <div className="playerYearBorn">
                        Año: {player.yearBorn ? player.yearBorn : "???"}
                      </div>

                      <div className="teamLogoContainer">
                        <TeamLogoIcon team={team} iconSize={30} />
                        <span className="playerTeamName">{team.name}</span>
                      </div>
                    </div>

                    {player.number && (
                      <div
                        className="playerNumber"
                        style={{
                          backgroundColor: team.teamLogoColor,
                          color: textColor,
                        }}
                      >
                        #{player.number}
                      </div>
                    )}
                  </div>

                  <div className="bottomSection">
                    <div className="statBox">
                      <div className="number">{player.matchesPlayed}</div>
                      <div className="statName">Juegos Jugados</div>
                    </div>
                    <div className="statBox">
                      <div className="number">{player.goalsScored}</div>
                      <div className="statName">Goles</div>
                    </div>
                    <div className="statBox">
                      <div className="number">{player.foulsCommited}</div>
                      <div className="statName">Faltas</div>
                    </div>
                    <div className="statBox">
                      <div className="number">{player.yellowCards}</div>
                      <div className="statName">Tarjetas Amarillas</div>
                    </div>
                    <div className="statBox">
                      <div className="number">{player.redCards}</div>
                      <div className="statName">Tarjetas Rojas</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <>
              <div className="noInfoTextContainer">
                <div className="noInfoText">No hay informacion del equipo</div>
              </div>
            </>
          )}
        </div>
      </div>
    </BetaTournamentNavigationComponents>
  );
}

export default BetaTeamInfoPage;

function TeamSplashScreen({ team }) {
  return (
    <div
      className="teamSplashScreen"
      style={{
        backgroundImage: `linear-gradient(to bottom, ${team.teamLogoColor}, black`,
      }}
    >
      <div className="teamLogoAndName">
        <TeamLogoIcon team={team} iconSize={"100%"} fillSpace={true} />
        <span className="teamName">{team.name}</span>
      </div>
    </div>
  );
}
