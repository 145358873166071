// Owner: Aaron Stubbs

export const Team__Nogaleros = {
  name: "Nogaleros",
  logoImage: null,
  teamImage: null,
  teamLogoColor: null,
  teamJerseyColor: "#012500",

  // Stats
  gamesWon: 1 + 1,
  gamesLost: 1,
  gamesTied: 0,
  gamesTiedWon: 0,

  ownGoalsInFavor: 0,
  goalsScoredAgainst: 33 + 31 + 53,
  defaultGoals: 0,

  isChampion: false,
  isEliminated: false,

  players: [
    {
      goalsScored: 64 + 53 + 42,
    },
  ],
};
