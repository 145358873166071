// Owner: Machis

import Logo from "./images/teamLogos/SuperNovas.png";

export const Team__SuperNovas = {
  name: "Super Novas",
  logoImage: Logo,
  teamImage: null,
  teamLogoColor: "#638ED8",
  teamJerseyColor: "#ffffff",

  // Stats
  gamesWon: 0,
  gamesLost: 3,
  gamesTied: 1,
  gamesTiedWon: 0,

  ownGoalsInFavor: 0,
  goalsScoredAgainst: 5 + 8 + 2,
  defaultGoals: 0,

  isChampion: false,
  isEliminated: false,

  players: [
    {
      firstName: "Karla",
      lastName: "Angiano",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 1 + 1,
      goalsScored: 2 + 1,
      foulsCommited: 1 + 2,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Yadira",
      lastName: "Ontiveros",
      nickName: null,
      yearBorn: null,
      number: null,

      // Stats
      matchesPlayed: 1 + 0 + 1,
      goalsScored: 2 + 2,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Lupita",
      lastName: "Ontiveros",
      nickName: null,
      yearBorn: null,
      number: null,

      // Stats
      matchesPlayed: 1 + 1 + 1,
      goalsScored: 1,
      foulsCommited: 1,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Lupita",
      lastName: "Mendoza",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 0 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Minerva",
      lastName: "Macias",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 1 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Diana",
      lastName: "Gonzalez",
      nickName: null,
      yearBorn: null,
      number: null,

      // Stats
      matchesPlayed: 1 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Brenda",
      lastName: "Acevedo",
      nickName: null,
      yearBorn: null,
      number: null,

      // Stats
      matchesPlayed: 1 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Camila",
      lastName: "Erives",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 1 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 1,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Idania",
      lastName: "Palacio",
      nickName: null,
      yearBorn: null,
      number: null,

      // Stats
      matchesPlayed: 1 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 2 + 1,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Camila",
      lastName: "Zapata",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 0 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    // ===========================================
    // ========= Non Registered Players ==========
    // ===========================================
  ],
};
