import React, { useEffect } from "react";
import BetaTournamentNavbar from "../BetaTournamentNavbar";
import SmallSidebar from "./SmallSidebar";
import LargeSidebar from "./LargeSidebar";
import MobileFooter from "./MobileFooter";
import { useParams } from "react-router-dom";
// import { useParams } from "react-router-dom";

// import { useDispatch, useSelector } from "react-redux";

// import { listTournamentDetails } from "../../../redux/actions/tournamentActions";

// // scss
// import "../../../styles/components/NavigationComponents/Tournament/TournamentNavigationComponents.scss";

// Components
// import MobileFooter from "./MobileFooter";
// import SmallSidebar from "./SmallSidebar";
// import TournamentNavbar from "../../TournamentNavbar";
// import LargeSidebar from "./LargeSidebar";

function BasketballTournamentNavigationComponents(props) {
  const tournamentParams = useParams();

  const currentPage = window.location.href;

  const localhost = `http://localhost:3000/#/t-beta/basketball/${props.tournament.URL_name}`;
  const networkServer = `http://192.168.1.236:3000/#/t-beta/basketball/${props.tournament.URL_name}`;
  const livePageUrl = `https://scoreboardhost.com/#/t-beta/basketball/${props.tournament.URL_name}`;
  const demoPageUrl = `https://scoreboardhost-demo.netlify.app/#/t-beta/basketball/${props.tournament.URL_name}`;

  const currentPageUrl = (pageUrl) => {
    if (currentPage === `${localhost}${pageUrl}`) {
      return "activePageLink";
    } else if (currentPage === `${networkServer}${pageUrl}`) {
      return "activePageLink";
    } else if (currentPage === `${livePageUrl}${pageUrl}`) {
      return "activePageLink";
    } else {
      return "pageLink";
    }
  };

  return (
    <>
      <BetaTournamentNavbar tournament={props.tournament} />

      <div className="smallSidebarContainer">
        <SmallSidebar
          tournament={props.tournament}
          currentPageUrl={currentPageUrl}
        />
      </div>
      <div className="largeSidebarContainer">
        <LargeSidebar
          tournament={props.tournament}
          currentPageUrl={currentPageUrl}
        />
      </div>
      <div className="thePage">{props.children}</div>
      {/* <div className="mobileFooterContainer">
        <MobileFooter
          tournament={props.tournament}
          currentPageUrl={currentPageUrl}
        />
      </div> */}
    </>
  );
}

export default BasketballTournamentNavigationComponents;
