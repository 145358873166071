import React from "react";
import { Link } from "react-router-dom";

import "../../../styles/components/NavigationComponents/Tournament/SmallSidebar.scss";

function SmallSidebar({ tournament, currentPageUrl }) {
  return (
    <div
      className={`smallSidebar smallSidebar__${tournament?.pageColorVersion}`}
    >
      <div className="linksContainer">
        <Link
          // to={`/torneo-beta/${tournament?.URL_name}/`}
          to={`/t-beta/basketball/${tournament?.URL_name}/`}
          className={currentPageUrl("/")}
        >
          <div className="iconContainer">
            {/* <i className="fa-solid fa-house"></i> */}
            <span class="material-symbols-rounded">home</span>
          </div>
          <div className="linkTextContainer">
            <div className="linkText">Principal</div>
          </div>
        </Link>

        {/* <Link
          to={`/t-beta/basketball/${tournament?.URL_name}/jornadas`}
          className={currentPageUrl("/jornadas")}
        >
          <div className="iconContainer">
            <span class="material-symbols-rounded">calendar_month</span>
          </div>
          <div className="linkTextContainer">
            <div className="linkText">Jornadas</div>
          </div>
        </Link> */}
      </div>
    </div>
  );
}

export default SmallSidebar;
