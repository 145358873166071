import {
  TEAMS_LIST_REQUEST,
  TEAMS_LIST_SUCCESS,
  TEAMS_LIST_FAIL,
  TEAM_DETAILS_REQUEST,
  TEAM_DETAILS_SUCCESS,
  TEAM_DETAILS_FAIL,
  TEAM_PLAYERS_LIST_REQUEST,
  TEAM_PLAYERS_LIST_SUCCESS,
  TEAM_PLAYERS_LIST_FAIL,
} from "../constants/teamsContstants";

export const teamsListReducer = (state = { teams: [] }, action) => {
  switch (action.type) {
    case TEAMS_LIST_REQUEST:
      return { loadingTeams: true, teams: [] };

    case TEAMS_LIST_SUCCESS:
      return { loadingTeams: false, teams: action.payload };

    case TEAMS_LIST_FAIL:
      return { loadingTeams: false, error: action.payload };

    default:
      return state;
  }
};

export const teamDetailsReducer = (state = { team: {} }, action) => {
  switch (action.type) {
    case TEAM_DETAILS_REQUEST:
      return { loading: true, ...state };

    case TEAM_DETAILS_SUCCESS:
      return { loading: false, team: action.payload };

    case TEAM_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const teamPlayersListReducer = (state = { players: [] }, action) => {
  switch (action.type) {
    case TEAM_PLAYERS_LIST_REQUEST:
      return { loading: true, players: [] };

    case TEAM_PLAYERS_LIST_SUCCESS:
      return { loading: false, players: action.payload };

    case TEAM_PLAYERS_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
