import React from "react";
import { Link } from "react-router-dom";

import "../../../styles/components/NavigationComponents/Tournament/LargeSidebar.scss";

function LargeSidebar({ tournament, currentPageUrl }) {
  return (
    <div
      className={`largeSidebar largeSidebar__tournament__${tournament?.pageColorVersion}`}
    >
      <div className="topLinksContainer">
        <Link
          to={`/torneo-beta/${tournament?.URL_name}/`}
          className={currentPageUrl("/")}
        >
          <div className="iconContainer">
            <span class="material-symbols-rounded">home</span>
          </div>
          <div className="linkText">Principal</div>
        </Link>

        <Link
          to={`/torneo-beta/${tournament?.URL_name}/equipos`}
          className={currentPageUrl("/equipos")}
        >
          <div className="iconContainer">
            <span class="material-symbols-rounded">group</span>
          </div>
          <div className="linkText">Equipos</div>
        </Link>

        <Link
          to={`/torneo-beta/${tournament?.URL_name}/jornadas`}
          className={currentPageUrl("/jornadas")}
        >
          <div className="iconContainer">
            <span class="material-symbols-rounded">calendar_month</span>
          </div>
          <div className="linkText">Jornadas</div>
        </Link>

        <Link
          to={`/torneo-beta/${tournament?.URL_name}/estadisticas`}
          className={currentPageUrl("/estadisticas")}
        >
          <div className="iconContainer">
            <span class="material-symbols-rounded">insert_chart</span>
          </div>
          <div className="linkText">Estadisticas</div>
        </Link>
      </div>

      <div className="extraInfoContainer">
        <div className="extraInfoBox">
          Para mas informacion puedes contactar:
          <div>
            <a
              href="https://api.whatsapp.com/send?phone=526361104576"
              target="_blank"
            >
              Martin Arreola jr.
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LargeSidebar;
