// Owner: Aaron Stubbs

export const Team__Chiles = {
  name: "Chiles",
  logoImage: null,
  teamImage: null,
  teamLogoColor: null,
  teamJerseyColor: "#BD3737",

  // Stats
  gamesWon: 0,
  gamesLost: 1 + 1 + 1,
  gamesTied: 0,
  gamesTiedWon: 0,

  ownGoalsInFavor: 0,
  goalsScoredAgainst: 47 + 54 + 58,
  defaultGoals: 0,

  isChampion: false,
  isEliminated: false,

  dontShowPointDif: true,

  players: [
    {
      goalsScored: 26 + 23 + 48,
    },
  ],
};
