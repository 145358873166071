// Owner: Aaron Stubbs

export const Team__Aliados = {
  name: "Aliados",
  logoImage: null,
  teamImage: null,
  teamLogoColor: null,
  teamJerseyColor: "#31A0BC",

  // Stats
  gamesWon: 2,
  gamesLost: 1,
  gamesTied: 0,
  gamesTiedWon: 0,

  ownGoalsInFavor: 0,
  // goalsScoredAgainst: 32 + 55 + 48,
  goalsScoredAgainst: 32 + 55,
  defaultGoals: 0,

  isChampion: false,
  isEliminated: false,

  players: [
    {
      // goalsScored: 38 + 48 + 58,
      goalsScored: 38 + 48,
    },
  ],
};
