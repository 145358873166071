import { Team__Astros } from "./teams/Astros";
import { Team__Brothers } from "./teams/Brothers";
import { Team__Cobras } from "./teams/Cobras";
import { Team__DeportivoMaster } from "./teams/DeportivoMaster";
import { Team__LagunitasFC } from "./teams/LagunitasFC";
import { Team__LeonesFC } from "./teams/LeonesFC";
import { Team__Piratas } from "./teams/Piratas";
import { Team__RSD } from "./teams/RSD";
import { Team__Vagos } from "./teams/Vagos";
import { Team__VeterinariaSabata } from "./teams/VeterinariaSabata";

export const teamList = [
  Team__Astros,
  Team__Brothers,
  Team__Cobras,
  Team__DeportivoMaster,
  Team__LagunitasFC,
  Team__LeonesFC,
  Team__Piratas,
  Team__RSD,
  Team__Vagos,
  Team__VeterinariaSabata,
];
