import {
  TOP_SCORERS_LIST_REQUEST,
  TOP_SCORERS_LIST_SUCCESS,
  TOP_SCORERS_LIST_FAIL,
} from "../constants/playersConstants";

export const topPlayersListReducer = (state = { topPlayers: [] }, action) => {
  switch (action.type) {
    case TOP_SCORERS_LIST_REQUEST:
      return { ...state, loadingTopPlayers: true, topPlayers: [] };
    case TOP_SCORERS_LIST_SUCCESS:
      return { ...state, loadingTopPlayers: false, topPlayers: action.payload };
    case TOP_SCORERS_LIST_FAIL:
      return {
        ...state,
        loadingTopPlayers: false,
        errorTopPlayers: action.payload,
      };

    default:
      return state;
  }
};
