import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

// data
import { products } from "../../__Shop/products";

// scss
import "../../styles/pages/ShopPages/ShopProductPage.scss";

// Components
import NavigationComponents from "../../components/NavigationComponents/Main Pages/NavigationComponents";

function ShopProductPage() {
  const name = useParams();
  const product = products.find((p) => p.name === name.name);

  const [selectedImage, setSelectedImage] = useState(product.image);

  useEffect(() => {
    document.title = `Comprar - Torneos Galeana`;
    window.scrollTo(0, 0);
  }, []);

  const wantToBuyText = `Quiero comprar *${product.name}*`;

  return (
    <NavigationComponents>
      <div className="shopProductPage">
        <div className="topInfoSection">
          <Link to={"/comprar"} className="backLink">
            <i class="fa-solid fa-angle-left"></i> Regresar a comprar
          </Link>
          <div className="productName">{product.name}</div>
          <div className="productBrand">{product.brand}</div>
        </div>

        <div className="productImagesContainer">
          <div className="productImageContainer">
            <img src={selectedImage} alt="imagen del producto" />
          </div>
          {product.extraImages && product.extraImages.length > 0 ? (
            <>
              <div className="extraImagesContainer">
                <div
                  className="imageButton"
                  onClick={() => setSelectedImage(product.image)}
                >
                  <img src={product.image} alt={`img`} />
                </div>
                {product.extraImages.map((image, index) => (
                  <div
                    className="imageButton"
                    key={index}
                    onClick={() => setSelectedImage(image.img)}
                  >
                    <img src={image.img} alt={`img`} />
                  </div>
                ))}
              </div>
            </>
          ) : null}
        </div>

        <div className="infoSection">
          <div className="topInfoSection">
            <Link to={"/comprar"} className="backLink">
              <i class="fa-solid fa-angle-left"></i> Regresar a comprar
            </Link>
            <div className="productName">{product.name}</div>
            <div className="productBrand">{product.brand}</div>
          </div>

          <div className="productInfoSection">
            <div className="priceTxt">Precio: ${product.price}</div>

            {product.size ? (
              <div className="productInfoTxt">{product.size}</div>
            ) : null}

            {product.amount ? (
              <div className="productInfoTxt isAvailable">
                Disponible: {product.amount}
              </div>
            ) : (
              <div className="productInfoTxt isUnavailable">Agotado</div>
            )}
          </div>

          <div className="orderBtnContainer">
            <a
              href={`https://wa.me/+526361104576/?text=${wantToBuyText}`}
              target="_blank"
            >
              Comprar por WhatsApp <i class="fa-brands fa-whatsapp"></i>
            </a>
          </div>
        </div>

        {product.extraDetails ? (
          <div className="extraDetailsContainer">
            <div className="title">Informacion Extra:</div>
            <div className="infoTxt">{product.extraDetails}</div>
          </div>
        ) : null}
      </div>
    </NavigationComponents>
  );
}

export default ShopProductPage;
