import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import {
  listTournamentDetails,
  listTournaments,
} from "../redux/actions/tournamentActions";
import { listTeams } from "../redux/actions/teamActions";

// scss
import "../styles/pages/TournamentHomePage.scss";

// Components
import Bracket from "../components/Bracket";
import PromoteMySelf from "../components/PromoteMySelf";
import TeamRankingsTable, {
  New_TeamRankingsTable,
} from "../components/tables/TeamRankingsTable";
import TournamentNavbar from "../components/TournamentNavbar";
import TournamentNavigationComponents from "../components/NavigationComponents/Tournament/TournamentNavigationComponents";
import WeekSchedule from "../components/WeekSchedule";
import { tournaments_data } from "../__FakeDatabase/Tournaments_data";

function getWindowDimensions() {
  const { innerWidth: screenWidth, innerHeight: screenHeight } = window;
  return {
    screenWidth,
    screenHeight,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

function TournamentHomePage() {
  const tournamentParams = useParams();
  const dispatch = useDispatch();

  const tournamentDetails = useSelector((state) => state.tournamentDetails);
  const { loading, error_tournament, tournament } = tournamentDetails;

  const tournamentTeams = useSelector((state) => state.teamsList);
  const { loadingTeams, error_teams, teams } = tournamentTeams;

  const [currentTournament, setCurrentTournament] = useState();

  const [headerPage, setHeaderPage] = useState("bracket");
  const [splitWeekMatches, setSplitWeekMatches] = useState(true);

  useEffect(() => {
    if (tournamentParams.isLocalData != null) {
      console.log("it here!!");
      setCurrentTournament(
        tournaments_data.find((p) => p.URL_name === tournamentParams.id)
      );
      return;
    } else {
      dispatch(listTeams(tournamentParams.id));
      setCurrentTournament(tournament);
    }
  }, [dispatch, tournamentParams.id]);

  // useEffect(() => {
  //   if (tournamentParams.isLocalData) {
  //     return;
  //   } else {
  //     dispatch(listTeams(tournamentParams.id));
  //   }
  // }, [])

  const { screenHeight, screenWidth } = useWindowDimensions();

  if (!tournament && !tournamentParams.isLocalData) {
    return (
      <>
        <h1 style={{ textAlign: "center" }}>Torneo No Encontrado</h1>
      </>
    );
  }

  console.log(currentTournament);

  return (
    <>
      <TournamentNavigationComponents tournament={currentTournament}>
        <div className="tournamentHomePage">
          <div className="heroSectionAndTableContainer">
            {!loading && (
              <div
                className="heroSection"
                // style={
                //   tournament?.heroSectionImage != null && {
                //     backgroundImage: `url(${tournament?.heroSectionImage})`,
                //   }
                // }
              >
                {currentTournament?.heroSectionImage != null && (
                  <div className="backgroundGradient"></div>
                )}
              </div>
            )}
            <div
              className="logoAndTableContainer"
              style={{ marginTop: -((screenHeight / 100) * 90) + 60 + 20 }}
            >
              <div className="logoAndNameContainer">
                <div className="logoAndName">
                  {currentTournament?.tournamentLogoImage && (
                    <div className="tournamentLogoContainer">
                      <img
                        src={currentTournament?.tournamentLogoImage}
                        alt=""
                        className="tournamentLogo"
                      />
                    </div>
                  )}
                  <div className="tournamentNameContainer">
                    {currentTournament?.name}
                  </div>
                </div>
              </div>

              <div className="tableTeamRankingsSection">
                {/* <New_TeamRankingsTable
                tournament={currentTournament}
                teams={teams}
                loading={loadingTeams}
              /> */}
              </div>
            </div>
          </div>

          <div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
            <div>.</div>
          </div>
        </div>
      </TournamentNavigationComponents>

      {/* ======================================================= */}
      {/* ======================================================= */}
      {/* ======================================================= */}
      {/* ======================================================= */}
      {/* ======================================================= */}

      {/* <TournamentNavigationComponents tournament={tournament}>
        <div className="tournamentHomePage">
          {tournament.warnings ? (
            <div className="warningTextContainer">
              {tournament.warnings.map((warning, index) => (
                <div
                  className={`warningText warningText__${warning.color}`}
                  key={index}
                >
                  {warning.text}
                </div>
              ))}
            </div>
          ) : null}

          <div className="contentWrapper-mobile">
            {tournament.inBracketStages ? (
              <div className="finalsAndTableContainer">
                <div className="header">
                  <div
                    className={
                      headerPage === "bracket"
                        ? `activeOption activeOption__${tournament.color}`
                        : "option"
                    }
                    onClick={() => setHeaderPage("bracket")}
                  >
                    Finales
                  </div>
                  <div
                    className={
                      headerPage === "table"
                        ? `activeOption activeOption__${tournament.color}`
                        : "option"
                    }
                    onClick={() => setHeaderPage("table")}
                  >
                    Tabla
                  </div>
                </div>

                <>
                  {headerPage === "bracket" ? (
                    <Bracket tournament={tournament} />
                  ) : headerPage === "table" ? (
                    <>
                      <TeamRankingsTable tournament={tournament} />
                    </>
                  ) : null}
                </>
              </div>
            ) : (
              <div className="teamsTableContainer">
                <TeamRankingsTable tournament={tournament} />
              </div>
            )}

            <WeekSchedule tournament={tournament} slice={splitWeekMatches} />
            {splitWeekMatches === true ? (
              <Link
                to={`/torneo/${tournament.URL_name}/jornadas`}
                className={`pastMatchesLink pastMatchesLink__${tournament.color}`}
              >
                Partidos pasados
              </Link>
            ) : null}
          </div>

          <PromoteMySelf />
        </div>
      </TournamentNavigationComponents> */}
    </>
  );
}

export default TournamentHomePage;
