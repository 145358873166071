import React, { useEffect, useState, useRef } from "react";

import "../styles/components/TeamLogoIcon.scss";

function TeamLogoIcon({ team, iconSize, letterSize, fillSpace }) {
  const elementRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);

  const [textColor, setTextColor] = useState("");
  const firstLetter = team.name[0];

  useEffect(() => {
    // Function to calculate the brightness of a color
    if (team.teamJerseyColor) {
      function getBrightness(color) {
        // Convert color to RGB
        const rgb = parseInt(color.slice(1), 16);
        const r = (rgb >> 16) & 0xff;
        const g = (rgb >> 8) & 0xff;
        const b = (rgb >> 0) & 0xff;

        // Calculate brightness using the formula (0.299*R + 0.587*G + 0.114*B)
        const brightness = 0.299 * r + 0.587 * g + 0.114 * b;
        return brightness;
      }

      const backgroundColorBrightness = getBrightness(team.teamJerseyColor);

      // Check if the background color is dark or light
      if (backgroundColorBrightness >= 128) {
        setTextColor("black"); // Set text color to black for light backgrounds
      } else {
        setTextColor("white"); // Set text color to white for dark backgrounds
      }
    } else {
      setTextColor("black");
    }
  }, [team.teamJerseyColor]);

  useEffect(() => {
    const updateWidth = (entries) => {
      for (let entry of entries) {
        setContainerWidth(entry.contentRect.width);
      }
    };

    const resizeObserver = new ResizeObserver(updateWidth);

    if (elementRef.current) {
      resizeObserver.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        resizeObserver.unobserve(elementRef.current);
      }
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div
      className={`teamLogoIcon ${fillSpace && "teamLogoIcon_fillSpace"}`}
      ref={elementRef}
    >
      {team.logoImage ? (
        <img
          src={team.logoImage}
          draggable="false"
          style={{ maxHeight: iconSize, maxWidth: iconSize }}
        />
      ) : (
        <div
          className="teamLetterContainer"
          style={{
            background: team.teamJerseyColor,
            color: textColor,

            height: iconSize,
            width: iconSize,
          }}
        >
          <div
            className="teamLetter"
            style={
              fillSpace
                ? { fontSize: containerWidth / 1.75 }
                : { fontSize: iconSize / 1.75 }
            }
          >
            {firstLetter}
          </div>
        </div>
      )}
    </div>
  );
}

export default TeamLogoIcon;
