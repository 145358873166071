import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import {
  listTournamentDetails,
  listTournaments,
} from "../redux/actions/tournamentActions";
import { listTeams } from "../redux/actions/teamActions";

// scss
import "../styles/pages/AllTeamsPage.scss";

// Components
import PromoteMySelf from "../components/PromoteMySelf";
import TournamentNavbar from "../components/TournamentNavbar";
import TournamentNavigationComponents from "../components/NavigationComponents/Tournament/TournamentNavigationComponents";
import TeamLogoIcon from "../components/TeamLogoIcon";

function AllTeamsPage() {
  const tournamentParams = useParams();
  const dispatch = useDispatch();

  const tournamentDetails = useSelector((state) => state.tournamentDetails);
  const { loading_tournament, error_tournament, tournament } =
    tournamentDetails;

  const tournamentTeams = useSelector((state) => state.teamsList);
  const { loading_teams, error_teams, teams } = tournamentTeams;

  useEffect(() => {
    dispatch(listTournamentDetails(tournamentParams.id));
    dispatch(listTeams(tournamentParams.id));
  }, [dispatch, tournamentParams]);

  return (
    <>
      <TournamentNavigationComponents tournament={tournament}>
        {teams.length > 0 ? (
          <div className="allTeamsPage">
            {teams.map((team, index) => (
              <div className="teamCardContainer" key={index}>
                <TeamCard tournament={tournament} team={team} />
              </div>
            ))}
          </div>
        ) : (
          <h1 style={{ textAlign: "center" }}>
            Todovia no hay equipos registrados
          </h1>
        )}
        <PromoteMySelf />
      </TournamentNavigationComponents>
    </>
  );
}

export default AllTeamsPage;

function TeamCard({ tournament, team }) {
  const [teamStatsModal, setTeamStatsModal] = useState(false);

  const [textColor, setTextColor] = useState(""); // Initialize with an empty string
  const firstLetter = team.name[0];

  useEffect(() => {
    // Function to calculate the brightness of a color
    if (team.teamLogoColor) {
      function getBrightness(color) {
        // Convert color to RGB
        const rgb = parseInt(color.slice(1), 16);
        const r = (rgb >> 16) & 0xff;
        const g = (rgb >> 8) & 0xff;
        const b = (rgb >> 0) & 0xff;

        // Calculate brightness using the formula (0.299*R + 0.587*G + 0.114*B)
        const brightness = 0.299 * r + 0.587 * g + 0.114 * b;
        return brightness;
      }

      const backgroundColorBrightness = getBrightness(team.teamLogoColor);

      // Check if the background color is dark or light
      if (backgroundColorBrightness >= 128) {
        setTextColor("black"); // Set text color to black for light backgrounds
      } else {
        setTextColor("white"); // Set text color to white for dark backgrounds
      }
    } else {
      setTextColor("black");
    }
  }, [team.teamLogoColor]);

  return (
    <>
      <Link
        to={`/torneo/${tournament.URL_name}/equipos/${team.id}`}
        className="teamCard_v2"
        onClick={() => setTeamStatsModal(true)}
      >
        <div className="logoContainer">
          <TeamLogoIcon
            team={team}
            iconSize={"100%"}
            letterSize={30}
            fillSpace={true}
          />
        </div>

        <div className="teamName">{team.name}</div>

        <div className="backgroundLogoContainer">
          {team.logoImage && (
            <div className="backgroundLogoContainer">
              <img src={team.logoImage} alt="" className="backgroundLogo" />
            </div>
          )}
          <div
            className="backgroundImage"
            style={{
              backgroundImage: `linear-gradient(to top right, ${team.teamLogoColor}, black)`,
            }}
          ></div>
        </div>
      </Link>
    </>
  );
}
