// Owner: Aaron Stubbs

export const Team__XLobos = {
  name: "XLobos",
  logoImage: null,
  teamImage: null,
  teamLogoColor: null,
  teamJerseyColor: "#1B534C",

  // Stats
  gamesWon: 1,
  gamesLost: 1 + 1,
  gamesTied: 0,
  gamesTiedWon: 0,

  ownGoalsInFavor: 0,
  goalsScoredAgainst: 64 + 60 + 40,
  defaultGoals: 0,

  isChampion: false,
  isEliminated: false,

  players: [
    {
      goalsScored: 33 + 52 + 44,
    },
  ],
};
