import React from "react";
import { Link } from "react-router-dom";

import "../../../styles/components/NavigationComponents/Main Pages/MobileFooter.scss";

function MobileFooter({ navLinks }) {
  return (
    <div className="mobileFooter">
      {navLinks.map((link, index) => (
        <Link to={link.url} className="pageLink" key={index}>
          {link?.icon}
          <div className="linkText">{link.title}</div>
        </Link>
      ))}
    </div>
  );
}

export default MobileFooter;
