import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// scss
import "../styles/components/TournamentNavbar.scss";

// Images
import MainLogo from "../images/Logos/Martin Arreola Logo (V2.0).png";
import BlueMLogo from "../images/Logos/Blue M Logo.png";
import PurpleMLogo from "../images/Logos/Purple.png";

function TournamentNavbar({ tournament }) {
  const [sidebarActive, setSidebarActive] = useState(false);

  document.body.style = "background: #f0f0f0;";

  useEffect(() => {
    document.title = `${tournament.name}`;
    window.scrollTo(0, 0);
  }, []);

  const links = [
    {
      url: "",
      name: "Torneos",
      icon: <span class="material-symbols-rounded">trophy</span>,
    },
    {
      url: "",
      name: "Comprar",
      icon: <span class="material-symbols-rounded">shopping_bag</span>,
    },
    {
      url: "",
      name: "Organizar Torneos",
      icon: <span class="material-symbols-rounded">inventory</span>,
    },
    {
      url: "",
      name: "Notificaciones",
      icon: <span class="material-symbols-rounded">notifications</span>,
    },
  ];

  return (
    <div
      className={`tournamentNavbar tournamentNavbar__${tournament.pageColorVersion}`}
    >
      <Link to={`/`}>
        <img
          src={
            tournament.hostLogoImage != null
              ? tournament.hostLogoImage
              : tournament.color == "male"
              ? BlueMLogo
              : tournament.color == "female"
              ? PurpleMLogo
              : MainLogo
          }
          alt=""
          draggable="false"
        />
      </Link>

      <div className="linksContainer">
        <Link to={`/`} className="pageLink">
          <span class="material-symbols-rounded">trophy</span>

          <span className="linkText">Mas Torneos</span>
        </Link>

        <Link to={`/comprar`} className="pageLink">
          <span class="material-symbols-rounded">local_mall</span>

          <span className="linkText">Comprar</span>
        </Link>
      </div>

      <div className="dropDownBtnContainer">
        <span
          class="material-symbols-rounded"
          onClick={() => setSidebarActive(true)}
        >
          menu
        </span>
      </div>

      <div
        className={`sidebarContainer  sidebarContainer__${
          sidebarActive ? "active" : "disabled"
        } transparentWhenPageLoads`}
      >
        <div className="sidebar">
          <div className="sidebarTitleContainer">Score Board Host</div>

          <div className="sidebarLinksContainer">
            <Link to="" className="profileLink">
              <div className="profilePictureContainer">
                <img
                  src="https://media.licdn.com/dms/image/D4E03AQGMHcfb6LWcbA/profile-displayphoto-shrink_800_800/0/1673284996040?e=2147483647&v=beta&t=Q-cE5JDWkdhbZNoqJ1DrOHimjD55ZD-7fzlU8XolH90"
                  className="profilePicture"
                />
              </div>

              <div className="profileNameAndUsernameContainer">
                <div className="fullName">Martin Arreola</div>
                <div className="username">thatdudemartin</div>
              </div>

              <div className="iconContainer">
                <span class="material-symbols-rounded">arrow_forward_ios</span>
              </div>
            </Link>
          </div>

          <div className="sidebarLinksContainer">
            {links.map((link, index) => (
              <Link to={link.url} className="sidebarLink" key={index}>
                {link.icon}
                {link.name}
              </Link>
            ))}
          </div>
        </div>

        <div
          className="background"
          onClick={() => setSidebarActive(false)}
        ></div>
      </div>
    </div>
  );
}

export default TournamentNavbar;
