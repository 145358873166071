import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

// scss
import "../../styles/pages/StatsPage.scss";

import { tournaments_data } from "../../__FakeDatabase/Tournaments_data";

// Images
import DefaultImage2 from "../../images/PlayerCards/_DefaultImage2.jpeg";

// Components
import PlayerStatsCard from "../../components/Cards/PlayerStatsCard";
import TeamLogoIcon from "../../components/TeamLogoIcon";
import PlayerStatRanking from "../../components/StatsComponents/PlayerStatRanking";
import BetaTournamentNavigationComponents from "../../components/BetaComponents/Tournament/TournamentNavigationComponents";

function BetaStatsPage() {
  const tournamentParams = useParams();
  const tournament = tournaments_data.find(
    (p) => p.URL_name === tournamentParams.id
  );

  const [activePlayerStatOption, setActivePlayerStatOption] = useState({
    title: "Goles",
    value: "goalsScored",
  });

  const playerStatsOptions = [
    {
      title: "Goles",
      value: "goalsScored",
    },
    {
      title: "Goles Sub 18",
      value: "goals-u18",
    },
    {
      title: "Faltas",
      value: "foulsCommited",
    },
    {
      title: "Tarjetas Amarillas",
      value: "yellowCards",
    },
    {
      title: "Tarjetas Rojas",
      value: "redCards",
    },
  ];

  return (
    <BetaTournamentNavigationComponents tournament={tournament}>
      <div className={`statsPage statsPage__${tournament.pageColorVersion}`}>
        <div className="statsPageNavbarContainer">
          <div className="statsPageNavbar">
            <Link
              to={`/torneo-beta/${tournament.URL_name}/estadisticas`}
              className="statsPageNavbarLink statsPageNavbarLink__active"
            >
              Individual
            </Link>
            <Link
              to={`/torneo-beta/${tournament.URL_name}/estadisticas/equipos`}
              className="statsPageNavbarLink "
            >
              Equipos
            </Link>
          </div>
        </div>

        <div className="statOptionsChipbarContainer">
          <div className="statOptionsChipbar">
            {playerStatsOptions.map((option, index) => (
              <div
                className={`statOptionChip statOptionChip__${
                  activePlayerStatOption.value === option.value && "active"
                } `}
                key={index}
                onClick={() =>
                  setActivePlayerStatOption({
                    title: option.title,
                    value: option.value,
                    database_url: option.database_url,
                    playersList: option.playersList,
                  })
                }
              >
                {option.title}
              </div>
            ))}
          </div>
        </div>

        <div className="allPlayerStatsContainer">
          {activePlayerStatOption.value === "goalsScored" ? (
            <TopGoalsScorers
              tournament={tournament}
              activeStat={activePlayerStatOption}
            />
          ) : activePlayerStatOption.value === "goals-u18" ? (
            <TopU18GoalsScorers
              tournament={tournament}
              activeStat={activePlayerStatOption}
            />
          ) : activePlayerStatOption.value === "foulsCommited" ? (
            <TopFoulsPlayers
              tournament={tournament}
              activeStat={activePlayerStatOption}
            />
          ) : activePlayerStatOption.value === "yellowCards" ? (
            <TopYellowCardPlayers
              tournament={tournament}
              activeStat={activePlayerStatOption}
            />
          ) : (
            activePlayerStatOption.value === "redCards" && (
              <TopRedCardPlayers
                tournament={tournament}
                activeStat={activePlayerStatOption}
              />
            )
          )}
        </div>
      </div>
    </BetaTournamentNavigationComponents>
  );
}

export default BetaStatsPage;

function TopGoalsScorers({ tournament }) {
  const allPlayers = tournament.teams.flatMap((team) => team.players);

  const playersList__topGoalScorers = allPlayers.sort(
    (a, b) => b.goalsScored - a.goalsScored
  );

  return (
    <TopPlayersStatBox
      tournament={tournament}
      activeStat={playersList__topGoalScorers}
      title="Goles"
      value={"goalsScored"}
    />
  );
}

function TopU18GoalsScorers({ tournament }) {
  const allPlayers = tournament.teams.flatMap((team) => team.players);

  const allPlayersSub18 = allPlayers.filter((player) => {
    const currentYear = new Date().getFullYear();
    return currentYear - player.yearBorn <= 18;
  });

  const playersList__topSub18GoalScorers = allPlayersSub18.sort(
    (a, b) => b.goalsScored - a.goalsScored
  );

  return (
    <TopPlayersStatBox
      tournament={tournament}
      activeStat={playersList__topSub18GoalScorers}
      title="Goles"
      value={"goalsScored"}
    />
  );
}

function TopFoulsPlayers({ tournament }) {
  const allPlayers = tournament.teams.flatMap((team) => team.players);

  const playersList__topGoalScorers = allPlayers.sort(
    (a, b) => b.foulsCommited - a.foulsCommited
  );

  return (
    <TopPlayersStatBox
      tournament={tournament}
      activeStat={playersList__topGoalScorers}
      title="Faltas"
      value={"foulsCommited"}
    />
  );
}

function TopYellowCardPlayers({ tournament }) {
  const allPlayers = tournament.teams.flatMap((team) => team.players);

  const playersList__topGoalScorers = allPlayers.sort(
    (a, b) => b.yellowCards - a.yellowCards
  );

  return (
    <TopPlayersStatBox
      tournament={tournament}
      activeStat={playersList__topGoalScorers}
      title="Tarjetas Amarillas"
      value={"yellowCards"}
    />
  );
}

function TopRedCardPlayers({ tournament }) {
  const allPlayers = tournament.teams.flatMap((team) => team.players);

  const playersList__topGoalScorers = allPlayers.sort(
    (a, b) => b.redCards - a.redCards
  );

  return (
    <TopPlayersStatBox
      tournament={tournament}
      activeStat={playersList__topGoalScorers}
      title="Tarjetas Rojas"
      value={"redCards"}
    />
  );
}

function TopPlayersStatBox({ tournament, activeStat, title, value }) {
  function getTeamName(player, teams) {
    for (const team of teams) {
      for (const teamPlayer of team.players) {
        if (
          teamPlayer.firstName + teamPlayer.lastName ===
          player.firstName + player.lastName
        ) {
          const teamData = {
            name: team.name,
            logoImage: team.logoImage,
            teamJerseyColor: team.teamJerseyColor,
          };
          return teamData;
        }
      }
    }
    return "???";
  }

  const PlayerPodium = ({ index }) => {
    const team = getTeamName(activeStat[index], tournament.teams);

    return (
      <div className="playerPodium">
        <div
          className={`playerImgContainer    ${
            activeStat[index].isMartinArreola &&
            "playerImgContainer__isMartinArreola"
          }`}
        >
          <div className="playerImgWrapper">
            <img
              src={
                activeStat[index].image
                  ? activeStat[index].image
                  : DefaultImage2
              }
              alt=""
              className="playerImg"
            />
          </div>

          {activeStat[index].isMartinArreola && (
            <div className="creatorOfWebsiteOverlay"></div>
          )}

          <div className="playerPlacement">#{index + 1}</div>
        </div>

        <div className="statsSection">
          <div className="playerInfo">
            <div className="playerTeam">
              <TeamLogoIcon team={team} iconSize={25} />
              <span className="playerTeamName">{team.name}</span>
            </div>
            <div className="playerName">
              {activeStat[index].firstName} {activeStat[index].lastName}
            </div>
            <div className="playerNickname">
              {activeStat[index].nickName && (
                <>"{activeStat[index].nickName}"</>
              )}
            </div>
            <div className="playerYear">
              Año:{" "}
              {activeStat[index].yearBorn ? activeStat[index].yearBorn : "???"}
            </div>
          </div>

          <div className="playerPointsContainer">
            <div className="playerPoints">{activeStat[index][value]}</div>
            <div className="statTitleContainer">{title}</div>
          </div>
        </div>
      </div>
    );
  };

  console.log("Players: ", activeStat);

  return (
    <div className="statsPagePlayerRanking">
      <div className="statTitle">{activeStat.title}</div>

      <div className="statsSectionWrapper">
        <div className="statsSection">
          <div className="podiumContainer">
            {activeStat.length <= 0 ? (
              <>
                <div className="playerPodium playerPodium__fake">
                  <div className="statsSection"></div>
                </div>{" "}
                <div className="playerPodium playerPodium__fake">
                  <div className="statsSection"></div>
                </div>{" "}
                <div className="playerPodium playerPodium__fake">
                  <div className="statsSection"></div>
                </div>
              </>
            ) : activeStat.length === 1 ? (
              <>
                <div className="playerPodium playerPodium__fake">
                  <div className="statsSection"></div>
                </div>
                <PlayerPodium index={0} />
                <div className="playerPodium playerPodium__fake">
                  <div className="statsSection"></div>
                </div>
              </>
            ) : activeStat.length === 2 ? (
              <>
                <PlayerPodium index={1} />
                <PlayerPodium index={0} />
                <div className="playerPodium playerPodium__fake">
                  <div className="statsSection"></div>
                </div>
              </>
            ) : (
              activeStat.length > 2 && (
                <>
                  <PlayerPodium index={1} />
                  <PlayerPodium index={0} />
                  <PlayerPodium index={2} />
                </>
              )
            )}
          </div>

          {activeStat.slice(3, 10).map((player, index) => {
            const team = getTeamName(player, tournament.teams);

            return (
              <div className="topPlayerCard" key={index}>
                <div className="placementBanner">
                  <div className="background"></div>
                  <div className="placement">{index + 4}</div>
                </div>
                <div className="playerInfoSide">
                  <div
                    className={`imgContainer ${
                      player.isMartinArreola && "imgContainer__isMartinArreola"
                    }`}
                  >
                    <img
                      src={player.image ? player.image : DefaultImage2}
                      alt=""
                      draggable="false"
                      className="playerImg"
                    />

                    {player.isMartinArreola && (
                      <div className="creatorOfWebsiteOverlay"></div>
                    )}
                  </div>

                  <div className="playerInfoContainer">
                    <div className="playerName">
                      {player.firstName} {player.lastName}
                    </div>
                    <div className="playerNickname">
                      {player.nickName && <>"{player.nickName}"</>}
                    </div>
                    <div className="yearBorn">
                      Año: {player.yearBorn ? player.yearBorn : "???"}
                    </div>

                    <div className="playerTeam">
                      <TeamLogoIcon team={team} iconSize={25} />
                      <span className="playerTeamName">{team.name}</span>
                    </div>
                  </div>
                </div>
                <div className="playerPointsContainer">
                  <div className="playerPoints">{player[value]}</div>
                  <div className="statTitleContainer">{title}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
