import {
  JORNADA_LIST_REQUEST,
  JORNADA_LIST_SUCCESS,
  JORNADA_LIST_FAIL,
} from "../constants/jornadaConstants";

export const jornadasListReducer = (state = { jornadas: [] }, action) => {
  switch (action.type) {
    case JORNADA_LIST_REQUEST:
      return { loading: true, jornadas: [] };
    case JORNADA_LIST_SUCCESS:
      return { loading: false, jornadas: action.payload };
    case JORNADA_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const jornadaMatchesListReducer = (
  state = { jornadaMatches: [] },
  action
) => {
  switch (action.type) {
    case JORNADA_LIST_REQUEST:
      return { loading: true, jornadaMatches: [] };
    case JORNADA_LIST_SUCCESS:
      return { loading: false, jornadaMatches: action.payload };
    case JORNADA_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
