import React from "react";
import { Link } from "react-router-dom";

import "../../styles/components/MainPageComponents/Navbar.scss";

import SBH_logo from "../../images/_AppImages/SBH_black-text_transparent.png";
import DefaultProfileImg from "../../images/_AppImages/default-profile-icon.jpg";

function Navbar() {
  return (
    <div className="navbar">
      <img src={SBH_logo} alt="" draggable="false" className="logoImg" />

      <Link to="/profile" className="profilePictureContainer">
        <img
          src={DefaultProfileImg}
          alt=""
          draggable="false"
          className="profileImg"
        />
      </Link>
    </div>
  );
}

export default Navbar;
