// Owner: Carlos Luna

import PFP_Jerry from "./images/playerImages/Cobras/Jerry.jpg";

export const Team__Cobras = {
  name: "Cobras",
  logoImage: null,
  teamImage: null,
  teamLogoColor: "#DA291C",
  teamJerseyColor: "#DA291C",

  // Stats
  gamesWon: 0,
  gamesLost: 4,
  gamesTied: 0,
  gamesTiedWon: 0,

  ownGoalsInFavor: 0,
  goalsScoredAgainst: 9 + 11 + 11 + 2,
  defaultGoals: 0,

  isChampion: false,
  isEliminated: false,

  players: [
    {
      firstName: "Jerry",
      lastName: "Gonzalez",
      nickName: null,
      yearBorn: 2002,
      number: null,
      image: PFP_Jerry,

      // Stats
      matchesPlayed: 1 + 0 + 1 + 1,
      goalsScored: 1,
      foulsCommited: 3 + 2,
      yellowCards: 1,
      redCards: 0,
    },

    // ===========================================
    // ========= Non Registered Players ==========
    // ===========================================

    {
      firstName: "Carlos",
      lastName: "Luna",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,
      isCaptain: true,

      // Stats
      matchesPlayed: 1,
      goalsScored: 2,
      foulsCommited: 1,
      yellowCards: 0,
      redCards: 0,
    },
    {
      firstName: "Obet",
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,
      isCaptain: true,

      // Stats
      matchesPlayed: 1 + 0 + 1,
      goalsScored: 1,
      foulsCommited: 0,
      yellowCards: 1,
      redCards: 0,
    },
    {
      firstName: "Eduardo",
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,
      isCaptain: true,

      // Stats
      matchesPlayed: 1 + 0 + 1 + 1,
      goalsScored: 1,
      foulsCommited: 1,
      yellowCards: 0,
      redCards: 0,
    },
    {
      firstName: "Issac", // Isac
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,
      isCaptain: true,

      // Stats
      matchesPlayed: 1 + 0 + 1 + 1,
      goalsScored: 2 + 1,
      foulsCommited: 1 + 1,
      yellowCards: 0,
      redCards: 0,
    },
    {
      firstName: "Alberto",
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,
      isCaptain: true,

      // Stats
      matchesPlayed: 1 + 0 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 2,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Brian",
      lastName: "L.",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 0 + 0 + 1 + 1,
      goalsScored: 1 + 2,
      foulsCommited: 1 + 1,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Santiago",
      lastName: "C.",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 0 + 0 + 1,
      goalsScored: 1,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Andy",
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 0 + 0 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 1,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Esteban",
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 0 + 0 + 0 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Arturo",
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 0 + 0 + 0 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },
  ],
};
