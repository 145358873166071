// Owner: Aaron Stubbs

export const Team__TheTakeOvers = {
  name: "The Take Overs",
  logoImage: null,
  teamImage: null,
  teamLogoColor: null,
  teamJerseyColor: "#0075EB",

  // Stats
  gamesWon: 0,
  gamesLost: 1 + 1 + 1 + 1,
  gamesTied: 0,
  gamesTiedWon: 0,

  ownGoalsInFavor: 0,
  goalsScoredAgainst: 25 + 31 + 41 + 42,
  defaultGoals: 0,

  isChampion: false,
  isEliminated: false,

  players: [
    {
      goalsScored: 20 + 30 + 16 + 38,
    },
  ],
};
