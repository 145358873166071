// Owner: Aaron Stubbs

export const Team__JohnsonBranders = {
  name: "Johnson Branders",
  logoImage: null,
  teamImage: null,
  teamLogoColor: null,
  teamJerseyColor: null,

  // Stats
  gamesWon: 1 + 1 + 1,
  gamesLost: 1,
  gamesTied: 0,
  gamesTiedWon: 0,

  ownGoalsInFavor: 0,
  goalsScoredAgainst: 21 + 20 + 21 + 26,
  defaultGoals: 0,

  isChampion: false,
  isEliminated: false,

  players: [
    {
      goalsScored: 25 + 37 + 29 + 23,
    },
  ],
};
