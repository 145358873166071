import {
  legacy_createStore as createStore,
  combineReducers,
  applyMiddleware,
} from "redux";
import { thunk } from "redux-thunk";

import {
  myTournamentsListReducer,
  tournamentDetailsReducer,
  tournamentListReducer,
} from "./redux/reducers/tournamentReducers";

import {
  teamDetailsReducer,
  teamsListReducer,
  teamPlayersListReducer,
} from "./redux/reducers/teamsReducers";

import {
  jornadasListReducer,
  jornadaMatchesListReducer,
} from "./redux/reducers/jornadaReducers";

import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
} from "./redux/reducers/userReducers";

import { topPlayersListReducer } from "./redux/reducers/playersReducers";

const reducer = combineReducers({
  tournamentList: tournamentListReducer,
  myTournamentsList: myTournamentsListReducer,
  tournamentDetails: tournamentDetailsReducer,

  teamsList: teamsListReducer,
  teamDetails: teamDetailsReducer,
  teamPlayersList: teamPlayersListReducer,

  topPlayers: topPlayersListReducer,

  jornadasList: jornadasListReducer,
  jornadaMatchesList: jornadaMatchesListReducer,

  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
});

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
  userDetails: { loading: false, user: {} },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  applyMiddleware(...middleware)
  // composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
