// Owner: Aaron Stubbs

export const Team__Natalias = {
  name: "Natalias",
  logoImage: null,
  teamImage: null,
  teamLogoColor: null,
  teamJerseyColor: null,

  // Stats
  gamesWon: 2 + 1,
  gamesLost: 1,
  gamesTied: 0,
  gamesTiedWon: 0,

  ownGoalsInFavor: 0,
  goalsScoredAgainst: 34 + 33 + 30 + 34,
  defaultGoals: 0,

  isChampion: false,
  isEliminated: false,

  dontShowPointDif: true,

  players: [
    {
      goalsScored: 23 + 40 + 38 + 47,
    },
  ],
};
