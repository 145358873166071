import { Team__Astros } from "./teams/Astros";
import { Team__Piratas } from "./teams/Piratas";
import { Team__RSD } from "./teams/RSD";
import { Team__VeterinariaSabata } from "./teams/VeterinariaSabata";

const Astros = {
  team: Team__Astros,
  playingPlayers: [
    {
      player: {
        firstName: "Beto",
        lastName: "Gonzalez",
        nickName: null,
        yearBorn: 2002,
      },

      goals: 0,
      fouls_commited: 1,
      yellow_card: 1,
      red_card: 0,
    },
    {
      player: {
        firstName: "Jonathan",
        lastName: "Stubbs",
        nickName: null,
        yearBorn: 2001,
      },

      goals: 1,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Aaron",
        lastName: "Stubbs",
        nickName: null,
        yearBorn: 2006,
      },

      goals: 1,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Israel",
        lastName: "Sarabia",
        nickName: null,
        yearBorn: 1996,
        number: null,
      },

      goals: 0,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Rolando",
        lastName: "Trevizo",
        nickName: null,
        yearBorn: 2005,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Carlos",
        lastName: "Carrasco",
        nickName: "Charly",
        yearBorn: 2006,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Octavio",
        lastName: "Carbajal",
        nickName: null,
        yearBorn: 2006,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Fausto",
        lastName: "Murillo",
        nickName: null,
        yearBorn: 2000,
      },

      goals: 2,
      fouls_commited: 2,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 4,
  penaltyGoals: 0,
  isWinner: false,
};

const Piratas = {
  team: Team__Piratas,
  playingPlayers: [
    {
      player: {
        firstName: "Jorge",
        lastName: "Esquivel",
        nickName: null,
        yearBorn: 1999,
        number: null,
      },

      goals: 1,
      fouls_commited: 2,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Miguel",
        lastName: "Castillo",
        nickName: null,
        yearBorn: 2002,
        number: null,
      },

      goals: 0,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Martin",
        lastName: "Castillo",
        nickName: null,
        yearBorn: 2001,
        number: null,
      },

      goals: 1,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Samuel",
        lastName: "Esquivel",
        nickName: null,
        yearBorn: 2006,
        number: null,
      },

      goals: 0,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Daniel",
        lastName: "Ontiveros",
        nickName: null,
        yearBorn: 2006,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Diego",
        lastName: "Cruz",
        nickName: null,
        yearBorn: 1996,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Luis",
        lastName: "Dominguez",
        nickName: null,
        yearBorn: 1990,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Hugo",
        lastName: "Díaz",
        nickName: null,
        yearBorn: 2004,
        number: null,
      },

      goals: 1,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Alfredo",
        lastName: "Delgado",
        nickName: null,
        yearBorn: 1998,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 3,
  penaltyGoals: 0,
  isWinner: false,
};

const RSD = {
  team: Team__RSD,
  playingPlayers: [
    {
      player: {
        firstName: "Arath",
        lastName: "Acosta",
        nickName: null,
        yearBorn: 2002,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Carlos‎",
        lastName: "Carrasco",
        nickName: null,
        yearBorn: 2001,
        number: null,
      },

      goals: 1,
      fouls_commited: 4,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Rufino",
        lastName: "Avalos",
        nickName: null,
        yearBorn: 1995,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Joel",
        lastName: "Marquez",
        nickName: null,
        yearBorn: 1992,
        number: null,
      },

      goals: 2,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Adrian",
        lastName: "Vazquez",
        nickName: null,
        yearBorn: 2000,
        number: null,
      },

      goals: 1,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Hector",
        lastName: "Nevarez",
        nickName: null,
        yearBorn: 2000,
        number: null,
      },

      goals: 2,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Yair",
        lastName: "Fernandez",
        nickName: null,
        yearBorn: 2009,
        number: null,
      },

      goals: 2,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Edwin",
        lastName: "Olivas",
        nickName: null,
        yearBorn: 2003,
        number: null,
      },

      goals: 1,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Armando",
        lastName: "Rodriguez",
        nickName: null,
        yearBorn: 2000,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Alexis",
        lastName: "Flores",
        nickName: null,
        yearBorn: 2003,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 9,
  penaltyGoals: 0,
  isWinner: false,
};

const VeterinariaSabata = {
  team: Team__VeterinariaSabata,
  playingPlayers: [
    {
      player: {
        firstName: "Angel",
        lastName: "Vargas",
        nickName: null,
        yearBorn: 2002,
        number: null,
      },

      goals: 2,
      fouls_commited: 2,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Ivan",
        lastName: "Stubbs",
        nickName: null,
        yearBorn: 2003,
        number: null,
      },

      goals: 1,
      fouls_commited: 2,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Kevin",
        lastName: "Rodríguez",
        nickName: "Kevincito",
        yearBorn: 2004,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Luis",
        lastName: "Estrada",
        nickName: "Rojo",
        yearBorn: 2002,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },

    {
      player: {
        firstName: "",
        lastName: "Sosa",
        nickName: null,
        yearBorn: 2000,
        number: null,
      },

      goals: 0,
      fouls_commited: 3,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 3,
  penaltyGoals: 0,
  isWinner: false,
};

const FINALIST_1 = {
  team: Team__Astros,
  playingPlayers: [
    {
      player: {
        firstName: "",
        lastName: "",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 0,
  penaltyGoals: 0,
  isWinner: false,
};

const FINALIST_2 = {
  team: Team__RSD,
  playingPlayers: [
    {
      player: {
        firstName: "",
        lastName: "",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 0,
  penaltyGoals: 0,
  isWinner: false,
};

const ThirdPlacement_1 = {
  team: Team__Piratas,
  playingPlayers: [
    {
      player: {
        firstName: "",
        lastName: "",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 0,
  penaltyGoals: 0,
  isWinner: false,
};

const ThirdPlacement_2 = {
  team: Team__VeterinariaSabata,
  playingPlayers: [
    {
      player: {
        firstName: "",
        lastName: "",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 0,
  penaltyGoals: 0,
  isWinner: false,
};

export const playoffs_varonil = [
  {
    playoffName: "Semifinal",

    matches: [
      {
        team1: Piratas,
        team2: Astros,

        isCompleted: true,
        isPlaying: false,
        day: "Jueves",
        date: "2024-08-15",
        time: "21:00:00",
        location: "Cancha Gerardo Gonzales",
      },
      {
        team1: RSD,
        team2: VeterinariaSabata,

        isCompleted: true,
        isPlaying: false,
        day: "Jueves",
        date: "2024-08-15",
        time: "22:00:00",
        location: "Cancha Gerardo Gonzales",
      },
    ],
  },

  {
    playoffName: "Gran final",

    matches: [
      {
        matchName: "Gran final",

        team1: FINALIST_1,
        team2: FINALIST_2,

        isCompleted: false,
        isPlaying: false,
        day: "Sabado",
        date: "2024-08-17",
        time: "22:00:00",
        location: "Cancha Gerardo Gonzales",
      },
      {
        matchName: "Tercer lugar",

        team1: ThirdPlacement_1,
        team2: ThirdPlacement_2,

        isCompleted: false,
        isPlaying: false,
        day: "Sabado",
        date: "2024-08-17",
        time: "20:00:00",
        location: "Cancha Gerardo Gonzales",
      },
    ],
  },
];
