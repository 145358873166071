import React, { useEffect } from "react";

// data
import { products } from "../../__Shop/products";

// scss
import "../../styles/pages/ShopPages/ShopHomePage.scss";

// Components
import NavigationComponents from "../../components/NavigationComponents/Main Pages/NavigationComponents";
import ShopProductCard from "../../components/Cards/ShopProductCard";

function ShopHomePage() {
  useEffect(() => {
    document.title = `Comprar - Torneos Galeana`;
    window.scrollTo(0, 0);
  }, []);

  return (
    <NavigationComponents>
      <div className="shopHomePage">
        <div className="productsContainer">
          {products.map((product, index) => {
            return <ShopProductCard product={product} key={index} />;
          })}
        </div>
      </div>
    </NavigationComponents>
  );
}

export default ShopHomePage;
