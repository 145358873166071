// Owner: Machis

import Logo from "./images/teamLogos/Leonas.png";

export const Team__Leonas = {
  name: "Leonas",
  logoImage: Logo,
  teamImage: null,
  teamLogoColor: "#c09138",
  teamJerseyColor: "#c09138",

  // Stats
  gamesWon: 1,
  gamesLost: 1,
  gamesTied: 2,
  gamesTiedWon: 2,

  ownGoalsInFavor: 0,
  goalsScoredAgainst: 1 + 2 + 7,
  defaultGoals: 2,

  isChampion: false,
  isEliminated: false,

  players: [
    {
      firstName: "Julia",
      lastName: "Carbajal", // Romero
      nickName: null,
      yearBorn: 2007,
      number: null,

      // Stats
      matchesPlayed: 1 + 1 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 1,
      yellowCards: 1,
      redCards: 0,
    },

    {
      firstName: "Citlali",
      lastName: "Hernández",
      nickName: null,
      yearBorn: 2007,
      number: null,

      // Stats
      matchesPlayed: 1 + 1 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Julissa",
      lastName: "Arreola",
      nickName: null,
      yearBorn: 2007,
      number: null,

      // Stats
      matchesPlayed: 1 + 1 + 1 + 1,
      goalsScored: 1 + 1,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Johana",
      lastName: "Murillo",
      nickName: null,
      yearBorn: 2002,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 1 + 0 + 1 + 1,
      goalsScored: 1 + 1,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Reyna",
      lastName: "Macario",
      nickName: null,
      yearBorn: 1990,
      number: null,

      // Stats
      matchesPlayed: 1 + 1 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Gabriela",
      lastName: "Domínguez",
      nickName: null,
      yearBorn: 1991,
      number: null,
      image: null,
      isCaptain: false,
      isMartinArreola: false,

      // Stats
      matchesPlayed: 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Miranda",
      lastName: "Rodríguez",
      nickName: null,
      yearBorn: 2011,
      number: null,

      // Stats
      matchesPlayed: 1 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 1 + +1,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Jaqueline",
      lastName: "Arreola",
      nickName: "Jacky",
      yearBorn: 2010,
      number: null,

      // Stats
      matchesPlayed: 1 + 1 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Elisa",
      lastName: "",
      nickName: "",
      yearBorn: null,
      number: null,

      // Stats
      matchesPlayed: 0 + 0 + 0 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },
    {
      firstName: "Jessica",
      lastName: "",
      nickName: "",
      yearBorn: null,
      number: null,

      // Stats
      matchesPlayed: 0 + 0 + 0 + 1,
      goalsScored: 1,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },
  ],
};
