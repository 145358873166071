import { useState, useEffect } from "react";
import {
  HashRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

import { collection, addDoc, getDocs } from "firebase/firestore";
import { db } from "./firebase";

// Components
import SplashScreen from "./components/SplashScreen";

// Pages
import HomePage from "./pages/HomePage";

// --- Authentication Pages
import LoginPage from "./pages/AuthenticationPages/LoginPage";
import RegisterPage from "./pages/AuthenticationPages/RegisterPage";

// --- Tournament Pages
import AllTeamsPage from "./pages/AllTeamsPage";
import TeamInfoPage from "./pages/TeamInfoPage";
import TournamentHomePage from "./pages/TournamentHomePage";
import StatsPage from "./pages/StatsPage";
import SchedulePage from "./pages/SchedulePage";

// --- Shop Pages
import ShopHomePage from "./pages/ShopPages/ShopHomePage";
import ShopProductPage from "./pages/ShopPages/ShopProductPage";
import ProfilePage from "./pages/UserPages/ProfilePage";
import BetaTournamentHomePage from "./pages/BetaPages/BetaTournamentHomePage";
import BetaAllTeamsPage from "./pages/BetaPages/BetaAllTeamsPage";
import BetaTeamInfoPage from "./pages/BetaPages/BetaTeamInfoPage";
import BetaSchedulePage from "./pages/BetaPages/BetaSchedulePage";
import BetaStatsPage from "./pages/BetaPages/BetaStatsPage";
import BetaStatsPageTeams from "./pages/BetaPages/BetaStatsPageTeams";
import BetaStatsNotReady from "./pages/BetaPages/BetaStatsNotReady";
import BasketballTournamentHomePage from "./pages/BasketballPages/TournamentHomePage";
import GiveAdminID from "./giveAdminID";

function App() {
  const [showSplash, setShowSplash] = useState(true);

  useEffect(() => {
    if (showSplash) {
      const timer = setTimeout(() => {
        setShowSplash(false);
        // localStorage.setItem("splashShown", "true");
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [showSplash]);

  return (
    <Router>
      {showSplash && <SplashScreen />}
      <DetectPageChange />

      <Routes>
        <Route path="/" element={<HomePage />} />

        <Route path="/login" element={<LoginPage />} />
        <Route path="/create-account" element={<RegisterPage />} />

        <Route path="/profile" element={<ProfilePage />} />

        <Route path="/torneo/:id" element={<TournamentHomePage />} />
        <Route path="/torneo/:id/jornadas" element={<SchedulePage />} />
        <Route path="/torneo/:id/equipos" element={<AllTeamsPage />} />
        <Route path="/torneo/:id/equipos/:team_id" element={<TeamInfoPage />} />
        <Route path="/torneo/:id/estadisticas" element={<StatsPage />} />

        <Route path="/torneo-beta/:id/" element={<BetaTournamentHomePage />} />
        <Route
          path="/torneo-beta/:id/jornadas"
          element={<BetaSchedulePage />}
        />
        <Route path="/torneo-beta/:id/equipos" element={<BetaAllTeamsPage />} />
        <Route
          path="/torneo-beta/:id/equipos/:team_id"
          element={<BetaTeamInfoPage />}
        />

        <Route
          path="/torneo-beta/:id/estadisticas"
          element={<BetaStatsPage />}
        />

        <Route
          path="/torneo-beta/:id/estadisticas/equipos"
          element={<BetaStatsPageTeams />}
        />

        {/* <Route
          path="/torneo-beta/:id/estadisticas"
          element={<BetaStatsNotReady />}
        /> */}

        <Route
          path="/t-beta/basketball/:id/"
          element={<BasketballTournamentHomePage />}
        />

        <Route path="/comprar" element={<ShopHomePage />} />
        <Route path="/comprar/:name" element={<ShopProductPage />} />

        <Route
          path="/random-weird-admin-page-i-think"
          element={<GiveAdminID />}
        />
      </Routes>
    </Router>
  );
}

export default App;

function DetectPageChange() {
  const location = useLocation();

  const currentTime = new Date();
  const formattedTime = currentTime.toISOString();

  const adminDataPassId = JSON.parse(localStorage.getItem("adminDataPass"));

  useEffect(() => {
    if (adminDataPassId === "124") {
      return;
    } else {
      const sendData = async (e) => {
        try {
          const docRef = await addDoc(collection(db, "pages visited"), {
            url: location.pathname,
            timestamp: formattedTime,
          });
        } catch (e) {
          return;
        }
      };

      sendData();
    }
  }, [location]);
}
