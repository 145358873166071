import { Team__Adrianes } from "../teams/Adrianes";
import { Team__Cerveceros } from "../teams/Cerveceros";
import { Team__Joeles } from "../teams/Joeles";
import { Team__JohnsonBranders } from "../teams/JohnsonBranders";
import { Team__Llamas } from "../teams/Llamas";
import { Team__Natalias } from "../teams/Natalias";
import { Team__PecanBros } from "../teams/PecanBros";
import { Team__Potros } from "../teams/Potros";
import { Team__TheTakeOvers } from "../teams/TheTakeOvers";
import { Team__Thunder } from "../teams/Thunder";

const Adrianes = {
  team: Team__Adrianes,
  playingPlayers: [],

  goals: 34,
  penaltyGoals: 0,
  isWinner: false,
};

const Cerveceros = {
  team: Team__Cerveceros,
  playingPlayers: [],

  goals: 21,
  penaltyGoals: 0,
  isWinner: false,
};

const Joeles = {
  team: Team__Joeles,
  playingPlayers: [],

  goals: 29,
  penaltyGoals: 0,
  isWinner: false,
};

const JohnsonBranders = {
  team: Team__JohnsonBranders,
  playingPlayers: [],

  goals: 37,
  penaltyGoals: 0,
  isWinner: false,
};

const Natalias = {
  team: Team__Natalias,
  playingPlayers: [],

  goals: 45,
  penaltyGoals: 0,
  isWinner: false,
};

const Thunder = {
  team: Team__Thunder,
  playingPlayers: [],

  goals: 50,
  penaltyGoals: 0,
  isWinner: false,
};

const Llamas = {
  team: Team__Llamas,
  playingPlayers: [],

  goals: 22,
  penaltyGoals: 0,
  isWinner: false,
};

const PecanBros = {
  team: Team__PecanBros,
  playingPlayers: [],

  goals: 33,
  penaltyGoals: 0,
  isWinner: false,
};

const TheTakeOvers = {
  team: Team__TheTakeOvers,
  playingPlayers: [],

  goals: 20,
  penaltyGoals: 0,
  isWinner: false,
};

const Potros = {
  team: Team__Potros,
  playingPlayers: [],

  goals: 36,
  penaltyGoals: 0,
  isWinner: false,
};

const FINALIST_1 = {
  team: {
    name: "Finalista",
    logoImage: null,
    teamImage: null,
    teamLogoColor: null,
    teamJerseyColor: "#ffffff",
  },
  playingPlayers: [],

  goals: 0,
  penaltyGoals: 0,
  isWinner: false,
};

const FINALIST_2 = {
  team: {
    name: "Finalista",
    logoImage: null,
    teamImage: null,
    teamLogoColor: null,
    teamJerseyColor: "#ffffff",
  },
  playingPlayers: [],

  goals: 0,
  penaltyGoals: 0,
  isWinner: false,
};

export const Jornada6 = {
  jornadaPosition: 6,
  matches: [
    {
      team1: Natalias,
      team2: Potros,

      isCompleted: true,
      isPlaying: false,
      day: "Sabado",
      date: "2024-08-03",
      time: "12:00:00",
      location: "Centro Recreativo Lebaron",
    },
  ],
};
