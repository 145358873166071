// Owner: Aaron Stubbs

export const Team__MeanMachine = {
  name: "Mean Machine",
  logoImage: null,
  teamImage: null,
  teamLogoColor: null,
  teamJerseyColor: "#0E293D",

  // Stats
  gamesWon: 1 + 1 + 1,
  gamesLost: 0,
  gamesTied: 0,
  gamesTiedWon: 0,

  ownGoalsInFavor: 0,
  goalsScoredAgainst: 42 + 52 + 42,
  defaultGoals: 0,

  isChampion: false,
  isEliminated: false,

  players: [
    {
      goalsScored: 48 + 60 + 53,
    },
  ],
};
