// Owner: Ivan Stubbs

export const Team__VeterinariaSabata = {
  name: "Veterinaria Sabata",
  logoImage: null,
  teamImage: null,
  teamLogoColor: "#F615D1",
  teamJerseyColor: "#F615D1",

  // Stats
  gamesWon: 3,
  gamesLost: 1,
  gamesTied: 0,
  gamesTiedWon: 0,

  ownGoalsInFavor: 0,
  goalsScoredAgainst: 6 + 5 + 3 + 4,
  defaultGoals: 0,

  isChampion: false,
  isEliminated: false,

  players: [
    {
      firstName: "Ivan",
      lastName: "Stubbs",
      nickName: null,
      yearBorn: 2003,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 1 + 1 + 1 + 1,
      goalsScored: 3 + 1 + 1,
      foulsCommited: 1 + 1,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Luis",
      lastName: "Estrada",
      nickName: "Rojo",
      yearBorn: 2002,
      number: null,

      // Stats
      matchesPlayed: 1 + 1 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Aldo",
      lastName: "Soto",
      nickName: null,
      yearBorn: 2002,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 1 + 1 + 1,
      goalsScored: 5 + 16 + 8,
      foulsCommited: 1 + 1,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Andres",
      lastName: "Soto",
      nickName: null,
      yearBorn: 1996,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 1 + 1 + 1,
      goalsScored: 1,
      foulsCommited: 2,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Kevin",
      lastName: "Rodríguez",
      nickName: "Kevincito",
      yearBorn: 2004,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 1 + 1 + 1 + 1,
      goalsScored: 1,
      foulsCommited: 1,
      yellowCards: 0,
      redCards: 1,
    },

    {
      firstName: "Gilbran",
      lastName: "Guerrero",
      nickName: null,
      yearBorn: 2006,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 1 + 1,
      goalsScored: 1,
      foulsCommited: 1,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Angel",
      lastName: "Vargas",
      nickName: null,
      yearBorn: 2002,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 1 + 0 + 1 + 1,
      goalsScored: 1,
      foulsCommited: 4,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "",
      lastName: "Sosa",
      nickName: null,
      yearBorn: 2000,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 1 + 0 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 1,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Luis Angel",
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 0 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Fabian",
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 0 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Ivan",
      lastName: "R.",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 0 + 1,
      goalsScored: 1,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Ever",
      lastName: "Z.",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 0 + 0 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },
  ],
};
