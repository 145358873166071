import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { listTopPlayers } from "../redux/actions/playerActions";

// Data
import { tournaments_data } from "../__FakeDatabase/Tournaments_data";
import TournamentNavbar from "../components/TournamentNavbar";

// scss
import "../styles/pages/StatsPage.scss";

// Images
import JumpscareImg from "../images/freddy dancing.gif";
import DefaultImage2 from "../images/PlayerCards/_DefaultImage2.jpeg";

// Components
import PromoteMySelf from "../components/PromoteMySelf";
import TournamentNavigationComponents from "../components/NavigationComponents/Tournament/TournamentNavigationComponents";
import PlayerStatsCard from "../components/Cards/PlayerStatsCard";
import TeamLogoIcon from "../components/TeamLogoIcon";
import PlayerStatRanking from "../components/StatsComponents/PlayerStatRanking";

function StatsPage() {
  const tournamentParams = useParams();
  const dispatch = useDispatch();

  const tournamentDetails = useSelector((state) => state.tournamentDetails);
  const { loading, error_tournament, tournament } = tournamentDetails;

  const [activePlayerStatOption, setActivePlayerStatOption] = useState({
    title: "Goals",
    value: "goalsScored",
    database_url: "",
  });

  const playerStatsOptions = [
    {
      title: "Goles",
      value: "goalsScored",
      database_url: "",
    },
    // {
    //   title: "Goals U-18",
    //   value: "goals-u18",
    //   database_url: "goals-u18",
    // },
    // {
    //   title: "Avg. Goals",
    //   value: "goalsScored",
    //   database_url: "avg-goals",
    // },
    {
      title: "Faltas",
      value: "foulsCommited",
      database_url: "fouls",
    },
    {
      title: "Tarjetas Amarillas",
      value: "yellowCards",
      database_url: "yellow-cards",
    },
    {
      title: "Tarjetas Rojas",
      value: "redCards",
      database_url: "red-cards",
    },
  ];

  return (
    <TournamentNavigationComponents tournament={tournament}>
      <div className={`statsPage statsPage__${tournament.pageColorVersion}`}>
        <div className="statsPageNavbarContainer">
          <div className="statsPageNavbar">
            <Link
              to=""
              className="statsPageNavbarLink statsPageNavbarLink__active"
            >
              Individual
            </Link>
            <Link to="" className="statsPageNavbarLink">
              Equipos
            </Link>
          </div>
        </div>

        <div className="statOptionsChipbarContainer">
          <div className="statOptionsChipbar">
            {playerStatsOptions.map((option, index) => (
              <div
                className={`statOptionChip statOptionChip__${
                  activePlayerStatOption.database_url === option.database_url &&
                  "active"
                } `}
                key={index}
                onClick={() =>
                  setActivePlayerStatOption({
                    title: option.title,
                    value: option.value,
                    database_url: option.database_url,
                  })
                }
              >
                {option.title}
              </div>
            ))}
          </div>
        </div>

        <div className="allPlayerStatsContainer">
          <TopPlayersStatBox
            tournament={tournament}
            activeStat={activePlayerStatOption}
          />
        </div>
      </div>
      <PromoteMySelf />
    </TournamentNavigationComponents>
  );
}

export default StatsPage;

function TopPlayersStatBox({ tournament, activeStat }) {
  const dispatch = useDispatch();

  const _topPlayers = useSelector((state) => state.topPlayers);
  const { loadingTopPlayers, errorTopPlayers, topPlayers } = _topPlayers;

  useEffect(() => {
    dispatch(listTopPlayers(tournament.URL_name, activeStat.database_url));
  }, [dispatch, tournament, activeStat]);

  const renderSkeletonAmount = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <div className="playerStatsRanking">
      <div className="statTitle">{activeStat.title}</div>

      <div className="statsSection">
        {topPlayers.map((player, index) => {
          return (
            <div className="topPlayerCard" key={index}>
              <div className="placementBanner">
                <div className="background"></div>
                <div className="placement">{index + 1}</div>
              </div>
              <div className="playerInfoSide">
                <div className="imgContainer">
                  <img
                    src={player.image ? player.image : DefaultImage2}
                    alt=""
                    draggable="false"
                    className="playerImg"
                  />

                  {player.creatorOfWebsite && (
                    <div className="creatorOfWebsiteOverlay"></div>
                  )}
                </div>

                <div className="playerInfoContainer">
                  <div className="playerName">
                    {player.firstName} {player.lastName}
                  </div>
                  <div className="yearBorn">Año: {player.yearBorn}</div>
                  <div className="yearBorn">{player.team}</div>
                </div>
              </div>
              <div className="playerPointsContainer">
                <div className="playerPoints">26</div>
                <div className="statTitleContainer">{activeStat.title}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
