import { Team__Leonas } from "../teams/Leonas";
import { Team__Pumbas } from "../teams/Pumbas";
import { Team__SuperNovas } from "../teams/SuperNovas";
import { Team__Valleras } from "../teams/Valleras";
import { Team__Vaqueras } from "../teams/Vaqueras";

const Leonas = {
  team: Team__Leonas,
  playingPlayers: [
    {
      player: {
        firstName: "Julia",
        lastName: "Carbajal", // Romero
        nickName: null,
        yearBorn: 2007,
      },

      goals: 0,
      fouls_commited: 1,
      yellow_card: 1,
      red_card: 0,
    },
    {
      player: {
        firstName: "Julissa",
        lastName: "Arreola",
        nickName: null,
        yearBorn: 2007,
      },

      goals: 1,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Reyna",
        lastName: "Macario",
        nickName: null,
        yearBorn: 1990,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Jaqueline",
        lastName: "Arreola",
        nickName: null,
        yearBorn: 2010,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Citlali",
        lastName: "Hernández",
        nickName: null,
        yearBorn: 2007,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Miranda",
        lastName: "Rodríguez",
        nickName: null,
        yearBorn: 2011,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 1,
  penaltyGoals: 1,
  isWinner: false,
};

const Pumbas = {
  team: Team__Pumbas,
  playingPlayers: [
    {
      player: {
        firstName: "Gaby",
        lastName: null,
        nickName: null,
        image: null,
        number: null,
      },

      goals: 1,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Karina",
        lastName: null,
        nickName: null,
        image: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Paulina",
        lastName: null,
        nickName: null,
        image: null,
        number: null,
      },

      goals: 2,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Adri",
        lastName: null,
        nickName: null,
        image: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Yiomara",
        lastName: null,
        nickName: null,
        image: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Magaly",
        lastName: null,
        nickName: null,
        image: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Denise",
        lastName: null,
        nickName: null,
        image: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Dominguez",
        lastName: null,
        nickName: null,
        image: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 3,
  penaltyGoals: 0,
  isWinner: false,
};

const SuperNovas = {
  team: Team__SuperNovas,
  playingPlayers: [
    {
      player: {
        firstName: null,
        lastName: null,
        nickName: null,
        image: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 0,
  penaltyGoals: 0,
  isWinner: false,
};

const Valleras = {
  team: Team__Valleras,
  playingPlayers: [
    {
      player: {
        firstName: "Adriana",
        lastName: "Avaloz",
        nickName: null,
        yearBorn: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },

    {
      player: {
        firstName: "Marce",
        lastName: "Chalaca",
        nickName: null,
        yearBorn: null,
        number: 19,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },

    {
      player: {
        firstName: "Maricela",
        lastName: "Rodríguez",
        nickName: null,
        yearBorn: 1987,
        number: 13,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },

    {
      player: {
        firstName: "Leslie",
        lastName: "Vega", // Ochoa
        nickName: null,
        yearBorn: null,
        number: 11,
      },

      goals: 1,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },

    {
      player: {
        firstName: "Idali",
        lastName: "Córdova",
        nickName: null,
        yearBorn: null,
        number: 7,
      },

      goals: 0,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },

    {
      player: {
        firstName: "Miriam",
        lastName: "Serrano",
        nickName: null,
        yearBorn: null,
        number: 1,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },

    {
      player: {
        firstName: "Anaricka",
        lastName: "Delgado",
        nickName: null,
        yearBorn: 1989,
        number: 4,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },

    {
      player: {
        firstName: "Alexa",
        lastName: "Quezada",
        nickName: null,
        yearBorn: 2007,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 1,
  penaltyGoals: 0,
  isWinner: false,
};

const Vaqueras = {
  team: Team__Vaqueras,
  playingPlayers: [
    {
      player: {
        firstName: "Elisa",
        lastName: "Erives",
        nickName: null,
        yearBorn: 2008,
        number: null,
      },

      goals: 0,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Elena",
        lastName: "Lujan",
        nickName: null,
        yearBorn: 2010,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Fatima",
        lastName: "Miramontes",
        nickName: null,
        yearBorn: 2009,
        number: null,
      },

      goals: 3,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Fernanda",
        lastName: "Núñez",
        nickName: null,
        yearBorn: 2008,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Iris",
        lastName: "Macario",
        nickName: null,
        yearBorn: 2008,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Gabriela",
        lastName: "Rosario",
        nickName: null,
        yearBorn: 1991,
        number: null,
      },

      goals: 2,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Mayte",
        lastName: "Xalate",
        nickName: null,
        yearBorn: 2009,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Lucy",
        lastName: "Xalate",
        nickName: null,
        yearBorn: 2007,
        number: null,
      },

      goals: 2,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 7,
  penaltyGoals: 0,
  isWinner: false,
};

export const Jornada2 = {
  jornadaPosition: 2,
  matches: [
    {
      team1: Valleras,
      team2: Leonas,

      isCompleted: true,
      isPlaying: false,
      day: "Jueves",
      date: "2024-07-18",
      time: "20:00:00",
      location: "Cancha Gerardo Gonzales",
    },
    {
      team1: Vaqueras,
      team2: Pumbas,

      isCompleted: true,
      isPlaying: false,
      day: "Viernes",
      date: "2024-07-19",
      time: "20:00:00",
      location: "Cancha Gerardo Gonzales",
    },
  ],
};
