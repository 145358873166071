import React from "react";
import { Link } from "react-router-dom";

import "../../../styles/components/NavigationComponents/Tournament/SmallSidebar.scss";

function SmallSidebar({ tournament, currentPageUrl }) {
  return (
    <div
      className={`smallSidebar smallSidebar__${tournament.pageColorVersion}`}
    >
      <div className="linksContainer">
        <Link
          to={`/torneo/${tournament.URL_name}/`}
          className={currentPageUrl("/")}
        >
          <div className="iconContainer">
            {/* <i className="fa-solid fa-house"></i> */}
            <span class="material-symbols-rounded">home</span>
          </div>
          <div className="linkTextContainer">
            <div className="linkText">Principal</div>
          </div>
        </Link>

        <Link
          to={`/torneo/${tournament.URL_name}/equipos`}
          className={currentPageUrl("/equipos")}
        >
          <div className="iconContainer">
            {/* <i className="fa-solid fa-shirt"></i> */}

            <span class="material-symbols-rounded">group</span>
          </div>
          <div className="linkTextContainer">
            <div className="linkText">Equipos</div>
          </div>
        </Link>

        <Link
          to={`/torneo/${tournament.URL_name}/jornadas`}
          className={currentPageUrl("/jornadas")}
        >
          <div className="iconContainer">
            {/* <i className="fa-solid fa-calendar-days"></i> */}

            <span class="material-symbols-rounded">calendar_month</span>
          </div>
          <div className="linkTextContainer">
            <div className="linkText">Jornadas</div>
          </div>
        </Link>

        <Link
          to={`/torneo/${tournament.URL_name}/estadisticas`}
          className={currentPageUrl("/estadisticas")}
        >
          <div className="iconContainer">
            {/* <i className="fa-solid fa-chart-column"></i> */}

            <span class="material-symbols-rounded">insert_chart</span>
          </div>
          <div className="linkTextContainer">
            <div className="linkText">Estadisticas</div>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default SmallSidebar;
