import { Team__Astros } from "../teams/Astros";
import { Team__Brothers } from "../teams/Brothers";
import { Team__Cobras } from "../teams/Cobras";
import { Team__DeportivoMaster } from "../teams/DeportivoMaster";
import { Team__LagunitasFC } from "../teams/LagunitasFC";
import { Team__LeonesFC } from "../teams/LeonesFC";
import { Team__Piratas } from "../teams/Piratas";
import { Team__RSD } from "../teams/RSD";
import { Team__Vagos } from "../teams/Vagos";
import { Team__VeterinariaSabata } from "../teams/VeterinariaSabata";

const Astros = {
  team: Team__Astros,
  playingPlayers: [
    {
      player: {
        firstName: "Jonathan",
        lastName: "Stubbs",
        nickName: null,
        yearBorn: 2001,
      },

      goals: 0,
      fouls_commited: 2,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Fausto",
        lastName: "Murillo",
        nickName: null,
        yearBorn: 2000,
      },

      goals: 5,
      fouls_commited: 0,
      yellow_card: 2,
      red_card: 0,
    },
    {
      player: {
        firstName: "Beto",
        lastName: "Gonzalez",
        nickName: null,
        yearBorn: 2002,
      },

      goals: 0,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Aaron",
        lastName: "Stubbs",
        nickName: null,
        yearBorn: 2006,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Ulises",
        lastName: "",
        nickName: "",
        yearBorn: 2009,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Carlos",
        lastName: "Carrasco",
        nickName: "Charly",
        yearBorn: 2006,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Israel",
        lastName: "Sarabia",
        nickName: null,
        yearBorn: 1996,
        number: null,
      },

      goals: 0,
      fouls_commited: 2,
      yellow_card: 2,
      red_card: 0,
    },
    {
      player: {
        firstName: "Martin",
        lastName: "Arreola",
        nickName: "El Programador",
        yearBorn: 2007,
        number: 4,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 5,
  penaltyGoals: 0,
  isWinner: false,
};

const RSD = {
  team: Team__RSD,
  playingPlayers: [
    {
      player: {
        firstName: "Arath",
        lastName: "Acosta",
        nickName: null,
        yearBorn: 2002,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Adrian",
        lastName: "Vazquez",
        nickName: null,
        yearBorn: 2000,
        number: null,
      },

      goals: 2,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Rufino",
        lastName: "Avalos",
        nickName: null,
        yearBorn: 1995,
        number: null,
      },

      goals: 2,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 1,
    },
    {
      player: {
        firstName: "Joel",
        lastName: "Marquez",
        nickName: null,
        yearBorn: 1992,
        number: null,
      },

      goals: 1,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Carlos‎",
        lastName: "Carrasco",
        nickName: null,
        yearBorn: 2001,
        number: null,
      },

      goals: 0,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Alexis",
        lastName: "Flores",
        nickName: null,
        yearBorn: 2003,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 1,
      red_card: 0,
    },
    {
      player: {
        firstName: "Edwin",
        lastName: "Olivas",
        nickName: null,
        yearBorn: 2003,
        number: null,
      },

      goals: 1,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Armando",
        lastName: "Rodriguez",
        nickName: null,
        yearBorn: 2000,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Yair",
        lastName: "Fernandez",
        nickName: null,
        yearBorn: 2009,
        number: null,
      },

      goals: 2,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Hector",
        lastName: "Nevarez",
        nickName: null,
        yearBorn: 2000,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Antonio", // Toño
        lastName: "Flores",
        nickName: null,
        yearBorn: 2001,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 8,
  penaltyGoals: 0,
  isWinner: false,
};

const LagunitasFC = {
  team: Team__LagunitasFC,
  playingPlayers: [
    {
      player: {
        firstName: "",
        firstName: "Sotero",
        lastName: "Guillén",
        nickName: null,
        yearBorn: 1981,
        number: 1,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Diego",
        lastName: "Guillén",
        nickName: null,
        yearBorn: 2006,
        number: 7,
      },

      goals: 2,
      fouls_commited: 2,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "David", // Adan
        lastName: "Roque",
        nickName: null,
        yearBorn: 200,
        number: 4,
      },

      goals: 0,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Moises",
        lastName: "Jimenez",
        nickName: "Moy",
        yearBorn: 1999,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Alexis",
        lastName: "Domínguez",
        nickName: null,
        yearBorn: 2004,
        number: 22,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Alberto",
        lastName: "",
        nickName: null,
        image: null,
        number: null,
      },

      goals: 1,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Juan",
        lastName: "Ramírez",
        nickName: "Pony",
        yearBorn: 1990,
        number: 17,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 3,
  penaltyGoals: 0,
  isWinner: false,
};

const Vagos = {
  team: Team__Vagos,
  playingPlayers: [
    {
      player: {
        firstName: "Miguel",
        lastName: "",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Luis",
        lastName: "",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 2,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Alfredo",
        lastName: "",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 4,
      fouls_commited: 2,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Johny",
        lastName: "",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Manuel",
        lastName: "",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 2,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Daniel",
        lastName: "",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Joel",
        lastName: "",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Jordy",
        lastName: "",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 8,
  penaltyGoals: 0,
  isWinner: false,
};

const Brothers = {
  team: Team__Brothers,
  playingPlayers: [
    {
      player: {
        firstName: "",
        lastName: "",
        nickName: null,
        image: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 0,
  penaltyGoals: 0,
  isWinner: false,
};

const VeterinariaSabata = {
  team: Team__VeterinariaSabata,
  playingPlayers: [
    {
      player: {
        firstName: "Luis",
        lastName: "Estrada",
        nickName: "Rojo",
        yearBorn: 2002,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Gilbran",
        lastName: "Guerrero",
        nickName: null,
        yearBorn: 2006,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "",
        lastName: "Sosa",
        nickName: null,
        yearBorn: 2000,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Ivan",
        lastName: "Stubbs",
        nickName: null,
        yearBorn: 2003,
        number: null,
      },

      goals: 1,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Angel",
        lastName: "Vargas",
        nickName: null,
        yearBorn: 2002,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Kevin",
        lastName: "Rodríguez",
        nickName: "Kevincito",
        yearBorn: 2004,
        number: null,
      },

      goals: 1,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 1,
    },
    {
      player: {
        firstName: "Fabian",
        lastName: "",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 2,
  penaltyGoals: 0,
  isWinner: false,
};

const Piratas = {
  team: Team__Piratas,
  playingPlayers: [
    {
      player: {
        firstName: "Jorge",
        lastName: "Esquivel",
        nickName: null,
        yearBorn: 1999,
        number: null,
      },

      goals: 3,
      fouls_commited: 2,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Miguel",
        lastName: "Castillo",
        nickName: null,
        yearBorn: 2002,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Martin",
        lastName: "Castillo",
        nickName: null,
        yearBorn: 2001,
        number: null,
      },

      goals: 1,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Samuel",
        lastName: "Esquivel",
        nickName: null,
        yearBorn: 2006,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Daniel",
        lastName: "Ontiveros",
        nickName: null,
        yearBorn: 2006,
        number: null,
      },

      goals: 0,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Hugo",
        lastName: "Díaz",
        nickName: null,
        yearBorn: 2004,
        number: null,
      },

      goals: 0,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Alfredo",
        lastName: "Delgado",
        nickName: null,
        yearBorn: 1998,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Diego",
        lastName: "Cruz",
        nickName: null,
        yearBorn: 1996,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Luis",
        lastName: "Dominguez",
        nickName: null,
        yearBorn: 1990,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 4,
  penaltyGoals: 0,
  isWinner: false,
};

const Cobras = {
  team: Team__Cobras,
  playingPlayers: [
    {
      player: {
        firstName: "NO SE PRESENTARON",
        lastName: "",
        nickName: null,
        image: null,
        number: 404,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 0,
  penaltyGoals: 0,
  isWinner: false,
};

const LeonesFC = {
  team: Team__LeonesFC,
  playingPlayers: [
    {
      player: {
        firstName: "Luis",
        lastName: "Cardenas",
        nickName: "Grillo",
        yearBorn: 2005,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Marcelino",
        lastName: "Macario",
        nickName: "Machis",
        yearBorn: 1992,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "David",
        lastName: "Hernández",
        nickName: null,
        yearBorn: 2008,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Deibin",
        lastName: "Gonzalez",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Brian",
        lastName: "Favela",
        nickName: "Chivo",
        yearBorn: 2007,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Diego",
        lastName: "Polanco",
        nickName: null,
        yearBorn: 2003,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Gustavo",
        lastName: "Xalate",
        nickName: "Tavo",
        yearBorn: 2005,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Marcos",
        lastName: "",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Abraham",
        lastName: "Gutiérrez",
        nickName: null,
        yearBorn: 1996,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Ramon",
        lastName: "",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 2,
  penaltyGoals: 0,
  isWinner: false,
};

const DeportivoMaster = {
  team: Team__DeportivoMaster,
  playingPlayers: [
    {
      player: {
        firstName: "",
        lastName: "",
        nickName: null,
        image: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 0,
  penaltyGoals: 0,
  isWinner: false,
};

export const Jornada5 = {
  jornadaPosition: 5,
  matches: [
    {
      team1: VeterinariaSabata,
      team2: Piratas,

      isCompleted: true,
      isPlaying: false,
      day: "Jueves",
      date: "2024-08-08",
      time: "21:00:00",
      location: "Cancha Gerardo Gonzales",
    },

    {
      team1: Astros,
      team2: RSD,

      isCompleted: true,
      isPlaying: false,
      day: "Jueves",
      date: "2024-08-08",
      time: "22:00:00",
      location: "Cancha Gerardo Gonzales",
    },

    // =====================================
    // =====================================

    {
      team1: Vagos,
      team2: LagunitasFC,

      isCompleted: true,
      isPlaying: false,
      day: "Viernes",
      date: "2024-08-09",
      time: "21:00:00",
      location: "Cancha Gerardo Gonzales",
    },

    {
      team1: LeonesFC,
      team2: Cobras,

      isCompleted: true,
      isPlaying: false,
      day: "Viernes",
      date: "2024-08-09",
      time: "22:00:00",
      location: "Cancha Gerardo Gonzales",
    },
  ],
};
