// Owner: Aaron Stubbs

export const Team__Nogaleras = {
  name: "Nogaleras",
  logoImage: null,
  teamImage: null,
  teamLogoColor: null,
  teamJerseyColor: "#012500",

  // Stats
  gamesWon: 1 + 1 + 1,
  gamesLost: 0,
  gamesTied: 0,
  gamesTiedWon: 0,

  ownGoalsInFavor: 0,
  goalsScoredAgainst: 22 + 17 + 18,
  defaultGoals: 0,

  isChampion: false,
  isEliminated: false,

  players: [
    {
      goalsScored: 34 + 22 + 27,
    },
  ],
};
