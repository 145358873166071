import React, { useEffect } from "react";
import { redirect, useNavigate, useLocation } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import NavigationComponents from "../../components/NavigationComponents/Main Pages/NavigationComponents";
import { getUserDetails, logout } from "../../redux/actions/userActions";
import TournamentCard from "../../components/Cards/TournamentCard";
import { listMyTournaments } from "../../redux/actions/tournamentActions";

function ProfilePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const userDetails = useSelector((state) => state.userDetails.user);
  const { error, loading, user } = useSelector((state) => state.userDetails);

  const myTouranementsList = useSelector((state) => state.myTournamentsList);
  const { errorMyTournaments, loadingMyTournaments, myTournaments } =
    myTouranementsList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    } else {
      if (!userDetails || !user.username) {
        dispatch(getUserDetails("profile"));
      }

      dispatch(listMyTournaments(userInfo?.id));
    }
  }, [dispatch, navigate, userInfo, user]);

  useEffect(() => {}, []);

  return (
    <NavigationComponents>
      <div className="profilePage">
        <h1>Profile</h1>
        <h2>{user.username}</h2>
        <h2>{user.id}</h2>
        <h2>
          {user.first_name} {user.last_name}
        </h2>

        <button onClick={() => dispatch(logout())}>Logout</button>

        {errorMyTournaments}
        {myTournaments?.map((tournament, index) => (
          <TournamentCard tournament={tournament} key={index} />
        ))}
      </div>
    </NavigationComponents>
  );
}

export default ProfilePage;
