import React from "react";

import "../../../styles/components/NavigationComponents/Main Pages/NavigationComponents.scss";

// scss
import Navbar from "../../MainPageComponents/Navbar";
import SmallSidebar from "./SmallSidebar";
import MobileFooter from "./MobileFooter";
import LargeSidebar from "./LargeSidebar";

function NavigationComponents(props) {
  const currentPage = window.location.href;

  const localhost = "http://localhost:3000/";
  const networkServer = "http://192.168.1.236:3000/#/";
  const livePageUrl = "https://galeana.biz/";

  const currentPageUrl = (pageUrl) => {
    if (currentPage === `${localhost}${pageUrl}`) {
      return "activePageLink";
    } else if (currentPage === `${networkServer}${pageUrl}`) {
      return "activePageLink";
    } else if (currentPage === `${livePageUrl}${pageUrl}`) {
      return "activePageLink";
    } else {
      return "pageLink";
    }
  };

  const navLinks = [
    {
      title: "Torneos",
      icon: <span class="material-symbols-rounded">trophy</span>,
      url: "/",
    },
    {
      title: "Comprar",
      icon: <span class="material-symbols-rounded">local_mall</span>,
      url: "/comprar",
    },
    // {
    //   title: "Organizar Torneos",
    //   icon: <span class="material-symbols-rounded">inventory</span>,
    //   url: "/comprar",
    // },
  ];

  return (
    <>
      <Navbar />
      <div className="smallSidebarContainer">
        <SmallSidebar currentPageUrl={currentPageUrl} />
      </div>
      <div className="largeSidebarContainer">
        <LargeSidebar currentPageUrl={currentPageUrl} />
      </div>

      <div className="thePage">{props.children}</div>
      <div className="mobileFooterContainer">
        <MobileFooter navLinks={navLinks} />
      </div>
    </>
  );
}

export default NavigationComponents;
