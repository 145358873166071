import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { listTournamentDetails } from "../redux/actions/tournamentActions";
import { getTeamDetails, listTeamPlayers } from "../redux/actions/teamActions";

// scss
import "../styles/pages/TeamInfoPage.scss";

// Componentes
import DefaultImage2 from "../images/PlayerCards/_DefaultImage2.jpeg";
import TeamLogoIcon from "../components/StatsComponents/PlayerStatRanking";
import PromoteMySelf from "../components/PromoteMySelf";
import TournamentNavigationComponents from "../components/NavigationComponents/Tournament/TournamentNavigationComponents";
import PlayerStatsCard from "../components/Cards/PlayerStatsCard";

function TeamInfoPage() {
  const { id, team_id } = useParams();
  const dispatch = useDispatch();

  const tournamentDetails = useSelector((state) => state.tournamentDetails);
  const { loading_tournament, error_tournament, tournament } =
    tournamentDetails;

  const teamDetails = useSelector((state) => state.teamDetails);
  const { loading_team, error_team, team } = teamDetails;

  const teamPlayers = useSelector((state) => state.teamPlayersList);
  const { loading_players, error_players, players } = teamPlayers;

  useEffect(() => {
    dispatch(listTournamentDetails(id));
    dispatch(getTeamDetails(team_id));
    dispatch(listTeamPlayers(team_id));

    // setAllPlayers(players);
  }, [dispatch, id, team_id]);

  const goalsSortedPlayers = players.slice().sort((a, b) => b.goals - a.goals);

  return (
    <>
      <TournamentNavigationComponents tournament={tournament}>
        <div className={`teamInfoPage teamInfoPage__${tournament.color}`}>
          {team.teamImage ? (
            <div
              className="teamHeroSection"
              style={{ backgroundColor: team.teamJerseyColor }}
            >
              <div className="teamImageContainerWrapper">
                <div className="teamImageContainer">
                  <img src={team.teamImage} alt="" className="teamImage" />
                  <div className="backgroundGradient"></div>
                  <div className="teamNameAndLogo">
                    {team.logoImage ? (
                      <img
                        src={team.logoImage}
                        className="teamLogo"
                        draggable="false"
                      />
                    ) : null}
                    <div className="teamName">{team.name}</div>
                  </div>
                </div>
              </div>
              <div className="roundedThingInBackground"></div>
            </div>
          ) : (
            // <div className="topSectionWrapper">
            //   <div className="topSection">
            //     {/* <div className="backgroundImgContainer"> */}
            //     <img src={team.teamImage} alt="" className="backgroundImg" />
            //     {/* </div> */}
            //     <div className="backgroundGradient"></div>
            //     <div className="teamNameContainer">
            //       {team.logoImage ? (
            //         <img src={team.logoImage} alt="" draggable="false" />
            //       ) : null}
            //       <div className="teamName">{team.name}</div>
            //     </div>
            //   </div>
            // </div>
            <div className="teamNameContainer">
              {team.logoImage ? (
                <img src={team.logoImage} alt="" draggable="false" />
              ) : null}
              <div className="teamName">{team.name}</div>
            </div>
          )}

          <div className="playerStatsSection">
            {players.length > 0 ? (
              <div className="playerStatsTableContainer">
                {players && (
                  <div className="tableContainer">
                    <table className="playerStatsTable">
                      <thead>
                        <th className="teamColumn">
                          {tournament.sex === "female" ? "Jugadora" : "Jugador"}
                        </th>
                        <th className="pointsColumn">Goles</th>
                        <th className="pointsColumn">Amarillas</th>
                        <th className="pointsColumn">Rojas</th>
                        <th className="pointsColumn">Partidos</th>
                      </thead>

                      <tbody className="statsSection">
                        {goalsSortedPlayers && (
                          <>
                            {goalsSortedPlayers.map((player, index) => {
                              return (
                                <>
                                  <tr key={index} className="playerInfoRow">
                                    <td className="playerInfoContainer">
                                      <div className={`imgContainer `}>
                                        <img
                                          src={
                                            player.image
                                              ? player.image
                                              : DefaultImage2
                                          }
                                          alt=""
                                          draggable="false"
                                          className="playerImg"
                                        />

                                        {player.isMartinArreola && (
                                          <div className="creatorOfWebsiteOverlay"></div>
                                        )}
                                      </div>

                                      <div className="playerInfo">
                                        <div className="playerName">
                                          {player.firstName} {player.lastName}
                                        </div>
                                        {player.nickName && (
                                          <div>"{player.nickName}"</div>
                                        )}
                                        <div>
                                          Año:{" "}
                                          {player.yearBorn
                                            ? player.yearBorn
                                            : "???"}
                                        </div>
                                      </div>
                                    </td>
                                    <td className="playerPoints">
                                      {player.goalsScored}
                                    </td>
                                    <td className="playerPoints">
                                      {player.yellowCards}
                                    </td>
                                    <td className="playerPoints">
                                      {player.redCards}
                                    </td>
                                    <td className="playerPoints">
                                      {player.matchesPlayed}
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            ) : (
              <>
                <div className="noInfoTextContainer">
                  <div className="noInfoText">
                    No hay informacion del equipo
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="moreStatsContainer">
            <div className="moreStats">
              <div className="sectionTitle">Estadisticas del Equipo</div>

              <div className="statContainer">
                <span className="title">Juegos Ganados: </span>
                <span className="data">{team.gamesWon}</span>
              </div>
              <div className="statContainer">
                <span className="title">Juegos Perdidos: </span>
                <span className="data">{team.gamesLost}</span>
              </div>
              <div className="statContainer">
                <span className="title">Juegos Empatados: </span>
                <span className="data">{team.gamesTied}</span>
              </div>
              <div className="statContainer">
                <span className="title">Juegos Empatados Ganados: </span>
                <span className="data">{team.gamesTiedWon}</span>
              </div>
              <div className="statContainer">
                <span className="title">Goles: </span>
                <span className="data">
                  ???
                  {/* {teamTotalGoals().totalGoals + team.stats.defaultGoals} */}
                </span>
              </div>
              <div className="statContainer">
                <span className="title">Goles Encontra: </span>
                <span className="data">{team.goalsScoredAgainst}</span>
              </div>
              <div className="statContainer">
                <span className="title">Goles por Default: </span>
                <span className="data">{team.defaultGoals}</span>
              </div>
              <div className="statContainer">
                <span className="title">Juegos Jugados: </span>
                <span className="data">
                  {" "}
                  {team.gamesWon + team.gamesTied + team.gamesLost}
                </span>
              </div>
            </div>
          </div>

          {team.teamImage ? (
            <div className="imageContainer">
              <img
                src={team.teamImage}
                alt="Foto del equipo"
                draggable="false"
              />
            </div>
          ) : null}
        </div>
        <PromoteMySelf />
      </TournamentNavigationComponents>
    </>
  );
}

export default TeamInfoPage;
