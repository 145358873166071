import { Team__Aliados } from "../teams/Aliados";
import { Team__Cerveceros_MAIN } from "../teams/Cerveceros_MAIN";
import { Team__Chiles } from "../teams/Chiles";
import { Team__MeanMachine } from "../teams/MeanMachine";
import { Team__Nogaleros } from "../teams/Nogaleros";
import { Team__Raptors } from "../teams/Raptors";
import { Team__Tec } from "../teams/Tec";
import { Team__XLobos } from "../teams/XLobos";

const Aliados = {
  team: Team__Aliados,
  playingPlayers: [],

  goals: 38,
  penaltyGoals: 0,
  isWinner: false,
};

const Cerveceros = {
  team: Team__Cerveceros_MAIN,
  playingPlayers: [],

  goals: 32,
  penaltyGoals: 0,
  isWinner: false,
};

const Chiles = {
  team: Team__Chiles,
  playingPlayers: [],

  goals: 26,
  penaltyGoals: 0,
  isWinner: false,
};

const MeanMachine = {
  team: Team__MeanMachine,
  playingPlayers: [],

  goals: 48,
  penaltyGoals: 0,
  isWinner: false,
};

const Nogaleros = {
  team: Team__Nogaleros,
  playingPlayers: [],

  goals: 64,
  penaltyGoals: 0,
  isWinner: false,
};

const Raptors = {
  team: Team__Raptors,
  playingPlayers: [],

  goals: 42,
  penaltyGoals: 0,
  isWinner: false,
};

const Tec = {
  team: Team__Tec,
  playingPlayers: [],

  goals: 47,
  penaltyGoals: 0,
  isWinner: false,
};

const XLobos = {
  team: Team__XLobos,
  playingPlayers: [],

  goals: 33,
  penaltyGoals: 0,
  isWinner: false,
};

export const Jornada1 = {
  jornadaPosition: 1,
  matches: [
    {
      team1: Cerveceros,
      team2: Aliados,

      isCompleted: true,
      isPlaying: false,
      day: "Lunes",
      date: "2024-07-29",
      time: "17:00:00",
      location: "Centro Recreativo Lebaron",
    },
    {
      team1: MeanMachine,
      team2: Raptors,

      isCompleted: true,
      isPlaying: false,
      day: "Lunes",
      date: "2024-07-29",
      time: "18:00:00",
      location: "Centro Recreativo Lebaron",
    },
    {
      team1: Tec,
      team2: Chiles,

      isCompleted: true,
      isPlaying: false,
      day: "Lunes",
      date: "2024-07-29",
      time: "19:00:00",
      location: "Centro Recreativo Lebaron",
    },
    {
      team1: Nogaleros,
      team2: XLobos,

      isCompleted: true,
      isPlaying: false,
      day: "Lunes",
      date: "2024-07-29",
      time: "20:00:00",
      location: "Centro Recreativo Lebaron",
    },
  ],
};
