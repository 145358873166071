// Owner: Diego Guillen

import Logo from "./images/teamLogos/Lagunitas.jpg";

export const Team__LagunitasFC = {
  name: "Lagunitas F.C.",
  logoImage: Logo,
  teamImage: null,
  teamLogoColor: "#093745",
  teamJerseyColor: "#130F86",

  // Stats
  gamesWon: 1,
  gamesLost: 3,
  gamesTied: 0,
  gamesTiedWon: 0,

  ownGoalsInFavor: 0,
  goalsScoredAgainst: 12 + 14 + 8,
  defaultGoals: 2,

  isChampion: false,
  isEliminated: false,

  players: [
    {
      firstName: "Diego",
      lastName: "Guillén",
      nickName: null,
      yearBorn: 2006,
      number: 7,
      image: null,
      isCaptain: true,

      // Stats
      matchesPlayed: 1 + 1 + 1 + 1,
      goalsScored: 1 + 2,
      foulsCommited: 1 + 1 + 2,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Sotero",
      lastName: "Guillén",
      nickName: null,
      yearBorn: 1981,
      number: 1,
      image: null,

      // Stats
      matchesPlayed: 1 + 1 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "David", // Adan
      lastName: "Roque",
      nickName: null,
      yearBorn: 200,
      number: 4,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 1 + 1 + 1 + 1,
      goalsScored: 1,
      foulsCommited: 2 + 1,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Alan",
      lastName: "Guillen",
      nickName: null,
      yearBorn: 2006,
      number: 10,
      image: null,

      // Stats
      matchesPlayed: 1,

      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Alexis",
      lastName: "Domínguez",
      nickName: null,
      yearBorn: 2004,
      number: 22,
      image: null,

      // Stats
      matchesPlayed: 1 + 1 + 1,

      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Juan",
      lastName: "Ramírez",
      nickName: "Pony",
      yearBorn: 1990,
      number: 17,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 1,
      goalsScored: 0,
      foulsCommited: 0 + 1,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Mauricio",
      lastName: "Mendoza",
      nickName: null,
      yearBorn: 2005,
      number: 11,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 1 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 1 + 2,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Moises",
      lastName: "Jimenez",
      nickName: "Moy",
      yearBorn: 1999,
      number: null,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 1 + 1 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Marcelo",
      lastName: "Galdamez", // Gonzales
      nickName: "",
      yearBorn: 1992,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 0,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    // ===========================================
    // ========= Non Registered Players ==========
    // ===========================================

    {
      firstName: "Gavino",
      lastName: "",
      nickName: "",
      yearBorn: null,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 1 + 1,
      goalsScored: 1 + 3,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Alberto",
      lastName: "",
      nickName: "",
      yearBorn: null,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 0 + 0 + 0 + 1,
      goalsScored: 1,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },
  ],
};
