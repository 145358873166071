import { Team__Adrianes } from "./teams/Adrianes";
import { Team__Cerveceros } from "./teams/Cerveceros";
import { Team__Joeles } from "./teams/Joeles";
import { Team__JohnsonBranders } from "./teams/JohnsonBranders";
import { Team__Llamas } from "./teams/Llamas";
import { Team__Natalias } from "./teams/Natalias";
import { Team__PecanBros } from "./teams/PecanBros";
import { Team__Potros } from "./teams/Potros";
import { Team__TheTakeOvers } from "./teams/TheTakeOvers";
import { Team__Thunder } from "./teams/Thunder";

const GroupA = [
  Team__Adrianes,
  Team__Joeles,
  Team__Natalias,
  Team__Potros,
  Team__Thunder,
];

const GroupB = [
  Team__Cerveceros,
  Team__JohnsonBranders,
  Team__Llamas,
  Team__PecanBros,
  Team__TheTakeOvers,
];

export const Groups = [GroupA, GroupB];
