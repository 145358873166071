// Owner: Aaron Stubbs

export const Team__Raptors = {
  name: "Raptors",
  logoImage: null,
  teamImage: null,
  teamLogoColor: null,
  teamJerseyColor: "#164F9D",

  // Stats
  gamesWon: 0,
  gamesLost: 1 + 1 + 1,
  gamesTied: 0,
  gamesTiedWon: 0,

  ownGoalsInFavor: 0,
  goalsScoredAgainst: 23 + 20 + 35,
  defaultGoals: 0,

  isChampion: false,
  isEliminated: false,

  players: [
    {
      goalsScored: 20 + 17 + 16,
    },
  ],
};
