import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

// scss
import "../../styles/pages/AllTeamsPage.scss";

// Components
import Bracket from "../../components/Bracket";
import TeamRankingsTable, {
  BetaTeamRankingsTable,
} from "../../components/tables/TeamRankingsTable";

import WeekSchedule from "../../components/WeekSchedule";

import { tournaments_data } from "../../__FakeDatabase/Tournaments_data";
import BetaTournamentNavigationComponents from "../../components/BetaComponents/Tournament/TournamentNavigationComponents";
import TeamLogoIcon from "../../components/TeamLogoIcon";

function BetaAllTeamsPage() {
  const tournamentParams = useParams();
  const tournament = tournaments_data.find(
    (p) => p.URL_name === tournamentParams.id
  );

  const sortedTeamStats = [...tournament.teams].sort((a, b) => {
    const aTotalGoals = a.players.reduce((total, player) => {
      return total + player.goalsScored;
    }, 0);

    const bTotalGoals = b.players.reduce((total, player) => {
      return total + player.goalsScored;
    }, 0);

    const aPoints =
      a.gamesWon * tournament.pointsPerWin +
      a.drawWins * tournament.pointsPerDraw +
      (a.draws - a.drawWins * tournament.pointsPerDrawWin);

    const bPoints =
      b.gamesWon * tournament.pointsPerWin +
      b.drawWins * tournament.pointsPerDraw +
      (b.draws - b.drawWins * tournament.pointsPerDrawWin);

    if (bPoints !== aPoints) {
      return bPoints - aPoints;
    } else {
      return (
        bTotalGoals -
        b.goalsScoredAgainst -
        (aTotalGoals - a.goalsScoredAgainst)
      );
    }
  });

  return (
    <>
      <BetaTournamentNavigationComponents tournament={tournament}>
        {sortedTeamStats.length > 0 ? (
          <div className="allTeamsPage">
            {sortedTeamStats.map((team, index) => (
              <div className="teamCardContainer" key={index}>
                <TeamCard tournament={tournament} team={team} />
              </div>
            ))}
          </div>
        ) : (
          <h1 style={{ textAlign: "center" }}>
            Todovia no hay equipos registrados
          </h1>
        )}
        {/* <PromoteMySelf /> */}
      </BetaTournamentNavigationComponents>
    </>
  );
}

export default BetaAllTeamsPage;

function TeamCard({ tournament, team }) {
  const [teamStatsModal, setTeamStatsModal] = useState(false);

  const [textColor, setTextColor] = useState(""); // Initialize with an empty string
  const firstLetter = team.name[0];

  useEffect(() => {
    // Function to calculate the brightness of a color
    if (team.teamLogoColor) {
      function getBrightness(color) {
        // Convert color to RGB
        const rgb = parseInt(color.slice(1), 16);
        const r = (rgb >> 16) & 0xff;
        const g = (rgb >> 8) & 0xff;
        const b = (rgb >> 0) & 0xff;

        // Calculate brightness using the formula (0.299*R + 0.587*G + 0.114*B)
        const brightness = 0.299 * r + 0.587 * g + 0.114 * b;
        return brightness;
      }

      const backgroundColorBrightness = getBrightness(team.teamLogoColor);

      // Check if the background color is dark or light
      if (backgroundColorBrightness >= 128) {
        setTextColor("black"); // Set text color to black for light backgrounds
      } else {
        setTextColor("white"); // Set text color to white for dark backgrounds
      }
    } else {
      setTextColor("black");
    }
  }, [team.teamLogoColor]);

  return (
    <>
      <Link
        to={`/torneo-beta/${tournament.URL_name}/equipos/${team.name}`}
        className="teamCard_v2"
        onClick={() => setTeamStatsModal(true)}
      >
        <div className="logoContainer">
          <TeamLogoIcon
            team={team}
            iconSize={"100%"}
            letterSize={30}
            fillSpace={true}
          />
        </div>

        <div className="teamName">{team.name}</div>

        <div className="backgroundLogoContainer">
          {team.logoImage && (
            <div className="backgroundLogoContainer">
              <img src={team.logoImage} alt="" className="backgroundLogo" />
            </div>
          )}
          <div
            className="backgroundImage"
            style={{
              backgroundImage: `linear-gradient(to top right, ${team.teamLogoColor}, black)`,
            }}
          ></div>
        </div>
      </Link>
    </>
  );
}
