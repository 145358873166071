import React from "react";

import "../styles/components/SplashScreen.scss";

import MDarkBGLogo from "../images/Logos/M logo transparent.png";

function SplashScreen() {
  return (
    <div className="splashScreen">
      <div className="logoAndTitleContainer">
        <img src={MDarkBGLogo} alt="" className="myLogoImg" />

        <div className="splashTitle">
          <div className="topSection">That Dude Martin's</div>
          <div className="bottomSection">Projects</div>
        </div>
      </div>
    </div>
  );
}

export default SplashScreen;
