// Owner: Aaron Stubbs

export const Team__Joeles = {
  name: "Joeles",
  logoImage: null,
  teamImage: null,
  teamLogoColor: "#03141F",
  teamJerseyColor: "#03141F",

  // Stats
  gamesWon: 1,
  gamesLost: 1 + 1 + 1,
  gamesTied: 0,
  gamesTiedWon: 0,

  ownGoalsInFavor: 0,
  goalsScoredAgainst: 50 + 41 + 38 + 29,
  defaultGoals: 0,

  isChampion: false,
  isEliminated: false,

  dontShowPointDif: true,

  players: [
    {
      goalsScored: 29 + 37 + 30 + 30,
    },
  ],
};
