import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import "../../styles/pages/SchedulePage.scss";

import { tournaments_data } from "../../__FakeDatabase/Tournaments_data";

import Match from "../../components/Matches/Match";
import BetaTournamentNavigationComponents from "../../components/BetaComponents/Tournament/TournamentNavigationComponents";

function BetaSchedulePage() {
  const params = useParams();
  const tournament = tournaments_data.find((p) => p.URL_name === params.id);

  const [matchModalOpen, setMatchModalOpen] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState(null);

  const handleSelectedMatch = (match) => {
    setMatchModalOpen(true);
    setSelectedMatch(match);

    document.body.style.overflow = "hidden";
  };

  const handleSelectedMatchClose = () => {
    setMatchModalOpen(false);
    setSelectedMatch(null);

    document.body.style.overflow = "auto";
  };

  return (
    <BetaTournamentNavigationComponents tournament={tournament}>
      <div className="schedulePage">
        {tournament.jornadas?.map((jornada, index) => {
          return (
            <JornadaBox
              jornada={jornada}
              index={index}
              tournament={tournament}
            />
          );
        })}

        {/* {tournament.jornadaSchedule.map((jornada, index) => {
          return (
            <div
              className={`jornadaContainer jornadaContainer__${tournament.color}`}
              key={index}
            >
              <div className="title">
                <div className="titleTxt">Jornada {index + 1}</div>
              </div>

              {jornada.matches.map((match, index) => {
                return <Match match={match} index={index} />;
              })}

              {jornada.doesntPlay ? (
                <div className="teamRest" key={index}>
                  Descansa: {jornada.doesntPlay}
                </div>
              ) : null}
            </div>
          );
        })} */}
      </div>
    </BetaTournamentNavigationComponents>
  );
}

export default BetaSchedulePage;

export const JornadaBox = ({ jornada, index, tournament, isBasketball }) => {
  return (
    <div
      className={`jornadaContainer jornadaContainer__${tournament.pageColorVersion}`}
      key={index}
    >
      <div className="title">
        <div className="titleTxt">
          {isBasketball ? (
            <>
              Dia{" "}
              {jornada.jornadaPosition ? jornada.jornadaPosition : index + 1}
            </>
          ) : (
            <>
              Jornada{" "}
              {jornada.jornadaPosition ? jornada.jornadaPosition : index + 1}
            </>
          )}
        </div>
      </div>
      {jornada?.matches.map((match, index) => {
        return <Match match={match} index={index} key={index} />;
      })}

      {jornada?.doesntPlay ? (
        <div className="teamRest" key={index}>
          Descansa: {jornada.doesntPlay}
        </div>
      ) : null}
    </div>
  );
};
