import axios from "axios";

import {
  TEAMS_LIST_REQUEST,
  TEAMS_LIST_SUCCESS,
  TEAMS_LIST_FAIL,
  TEAM_DETAILS_REQUEST,
  TEAM_DETAILS_SUCCESS,
  TEAM_DETAILS_FAIL,
  TEAM_PLAYERS_LIST_REQUEST,
  TEAM_PLAYERS_LIST_SUCCESS,
  TEAM_PLAYERS_LIST_FAIL,
} from "../constants/teamsContstants";

export const listTeams = (tournament_URL_name) => async (dispatch) => {
  try {
    dispatch({ type: TEAMS_LIST_REQUEST });

    const { data } = await axios.get(
      `/api/tournaments/${tournament_URL_name}/teams/`
    );

    dispatch({
      type: TEAMS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TEAMS_LIST_FAIL,
      payload:
        error.response && error.response.data.message.detail
          ? error.reponse.data.message.detail
          : error.message,
    });
  }
};

export const getTeamDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: TEAM_DETAILS_REQUEST });

    const { data } = await axios.get(`/api/teams/${id}/`);

    dispatch({
      type: TEAM_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TEAM_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.reponse.data.message.detail
          : error.message,
    });
  }
};

export const listTeamPlayers = (teamId) => async (dispatch) => {
  try {
    dispatch({ type: TEAM_PLAYERS_LIST_REQUEST });

    const { data } = await axios.get(`/api/teams/${teamId}/players/`);

    dispatch({
      type: TEAM_PLAYERS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TEAM_PLAYERS_LIST_FAIL,
      payload:
        error.response && error.response.data.message.detail
          ? error.reponse.data.message.detail
          : error.message,
    });
  }
};
