// Owner: Arath Acosta

import PFP_Arath_Acosta from "./images/playerImages/RSD/arath_acosta.jpg";
import PFP_yair_fernandez from "./images/playerImages/RSD/yair_fernandez.jpg";

export const Team__RSD = {
  name: "RSD",
  logoImage: "/teamLogos/RSD.png",
  teamImage: null,
  teamLogoColor: "#111C51",
  teamJerseyColor: "#ffffff",

  // Stats
  gamesWon: 4,
  gamesLost: 0,
  gamesTied: 0,
  gamesTiedWon: 0,

  ownGoalsInFavor: 0,
  defaultGoals: 0,
  goalsScoredAgainst: 4 + 4 + 2 + 5,

  isChampion: false,
  isEliminated: false,

  players: [
    {
      firstName: "Adrian",
      lastName: "Vazquez",
      nickName: null,
      yearBorn: 2000,
      number: null,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 1 + 1,
      goalsScored: 2 + 2,
      foulsCommited: 1,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Alexis",
      lastName: "Flores",
      nickName: null,
      yearBorn: 2003,
      number: null,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 1 + 1 + 1 + 1,
      goalsScored: 1 + 4 + 1,
      foulsCommited: 0,
      yellowCards: 1,
      redCards: 0,
    },

    {
      firstName: "Andres",
      lastName: "Muños",
      nickName: null,
      yearBorn: 1997,
      number: null,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 1,
      goalsScored: 1,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Antonio", // Toño
      lastName: "Flores",
      nickName: null,
      yearBorn: 2001,
      number: null,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 1 + 0 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Arath",
      lastName: "Acosta",
      nickName: null,
      yearBorn: 2002,
      number: null,
      image: PFP_Arath_Acosta,
      isCaptain: false,

      // Stats
      matchesPlayed: 1 + 1 + 1,
      goalsScored: 3,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Armando",
      lastName: "Rodriguez",
      nickName: null,
      yearBorn: 2000,
      number: null,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 1 + 0 + 1 + 1,
      goalsScored: 1,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Carlos‎",
      lastName: "Carrasco",
      nickName: null,
      yearBorn: 2001,
      number: null,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 1 + 1,
      goalsScored: 2,
      foulsCommited: 1,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Daniel",
      lastName: "Perez",
      nickName: "Dani",
      yearBorn: 2000,
      number: null,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 0 + 0 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Diego",
      lastName: "Vega",
      nickName: null,
      yearBorn: 2000,
      number: null,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 1 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Edwin",
      lastName: "Olivas",
      nickName: null,
      yearBorn: 2003,
      number: null,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 1 + 1 + 1 + 1,
      goalsScored: 3 + 1,
      foulsCommited: 1 + 1,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Ezequiel",
      lastName: "Espinoza",
      nickName: null,
      yearBorn: 2006,
      number: null,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 1 + 0 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Francisco",
      lastName: "Vega",
      nickName: "Paquito",
      yearBorn: 1995,
      number: null,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 0,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Hector",
      lastName: "Nevarez",
      nickName: null,
      yearBorn: 2000,
      number: null,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 1 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Joel",
      lastName: "Marquez",
      nickName: null,
      yearBorn: 1992,
      number: null,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 1 + 1,
      goalsScored: 3 + 1,
      foulsCommited: 1,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Rufino",
      lastName: "Avalos",
      nickName: null,
      yearBorn: 1995,
      number: null,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 1 + 0 + 1 + 1,
      goalsScored: 2 + 1 + 2,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 1,
    },

    {
      firstName: "Yair",
      lastName: "Fernandez",
      nickName: null,
      yearBorn: 2009,
      number: null,
      image: PFP_yair_fernandez,
      isCaptain: false,

      // Stats
      matchesPlayed: 1 + 1 + 1 + 1,
      goalsScored: 6 + 1 + 7 + 2,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    // ===========================================
    // ========= Non Registered Players ==========
    // ===========================================

    {
      firstName: "Ivan",
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 0 + 0 + 1,

      goalsScored: 1,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Josue",
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 0 + 0 + 1,

      goalsScored: 0,
      foulsCommited: 1,
      yellowCards: 0,
      redCards: 0,
    },
  ],
};
