// Owner: Machis

import Logo from "./images/teamLogos/Vaqueras.png";

export const Team__Vaqueras = {
  name: "Vaqueras",
  logoImage: Logo,
  teamImage: null,
  teamLogoColor: "#c4556a",
  teamJerseyColor: "#ffffff",

  // Stats
  gamesWon: 4,
  gamesLost: 0,
  gamesTied: 0,
  gamesTiedWon: 0,

  ownGoalsInFavor: 0,
  goalsScoredAgainst: 3 + 2 + 2 + 3,
  defaultGoals: 0,

  isChampion: false,
  isEliminated: false,

  players: [
    {
      firstName: "Lucy",
      lastName: "Xalate",
      nickName: null,
      yearBorn: 2007,
      number: null,

      // Stats
      matchesPlayed: 1 + 1 + 1 + 1,
      goalsScored: 2 + 1 + 1 + 1,
      foulsCommited: 2,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Mayte",
      lastName: "Xalate",
      nickName: null,
      yearBorn: 2009,
      number: null,

      // Stats
      matchesPlayed: 1 + 1 + 1 + 1,
      goalsScored: 3,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },
    {
      firstName: "Iris",
      lastName: "Macario",
      nickName: null,
      yearBorn: 2008,
      number: null,

      // Stats
      matchesPlayed: 1 + 1,
      goalsScored: 2,
      foulsCommited: 1,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Fatima",
      lastName: "Miramontes",
      nickName: null,
      yearBorn: 2009,
      number: null,

      // Stats
      matchesPlayed: 1 + 1 + 1 + 1,
      goalsScored: 3 + 3 + 3 + 3,
      foulsCommited: 1,
      yellowCards: 0,
      redCards: 0,
    },
    {
      firstName: "Fernanda",
      lastName: "Núñez",
      nickName: null,
      yearBorn: 2008,
      number: null,

      // Stats
      matchesPlayed: 1 + 1 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Elisa",
      lastName: "Erives",
      nickName: null,
      yearBorn: 2008,
      number: null,

      // Stats
      matchesPlayed: 1 + 1 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 1,
      yellowCards: 0,
      redCards: 0,
    },
    {
      firstName: "Alina",
      lastName: "Flores",
      nickName: null,
      yearBorn: 2008,
      number: null,
      image: null,
      isCaptain: false,
      isMartinArreola: false,

      // Stats
      matchesPlayed: 0,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Elena",
      lastName: "Lujan",
      nickName: null,
      yearBorn: 2010,
      number: null,

      // Stats
      matchesPlayed: 1 + 0 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    // ===========================================
    // ========= Non Registered Players ==========
    // ===========================================

    {
      firstName: "Gabriela",
      lastName: "Rosario",
      nickName: "Gaby",
      yearBorn: 1991,
      number: null,

      // Stats
      matchesPlayed: 1 + 1 + 1 + 1,
      goalsScored: 2 + 1,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },
  ],
};
