// Owner: Aaron Stubbs

import PFP__Martin from "./images/playerImages/Astros/Martin.jpeg";

export const Team__Astros = {
  name: "Astros",
  logoImage: "/teamLogos/astros_logo_v2.png",
  teamImage: "/teamImage/AstrosTeam.jpg",
  teamLogoColor: "#0F38A1",
  teamJerseyColor: "#DFEEEF",

  // Stats
  gamesWon: 3,
  gamesLost: 1,
  gamesTied: 0,
  gamesTiedWon: 0,

  ownGoalsInFavor: 0,
  goalsScoredAgainst: 4 + 8,
  defaultGoals: 2 + 2,

  isChampion: false,
  isEliminated: false,

  players: [
    {
      id: 0,

      firstName: "Martin",
      lastName: "Arreola",
      nickName: "El Programador",
      yearBorn: 2007,
      number: 4,
      image: null,
      isCaptain: false,
      isMartinArreola: false,

      // Stats
      matchesPlayed: 1 + 1 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      id: 1,

      firstName: "Aaron",
      lastName: "Stubbs",
      nickName: null,
      yearBorn: 2006,

      image: null,
      isCaptain: true,

      // Stats
      matchesPlayed: 1 + 1 + 1 + 1,
      goalsScored: 3,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      id: 2,

      firstName: "Rolando",
      lastName: "Trevizo",
      nickName: null,
      yearBorn: 2005,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 1 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      id: 3,

      firstName: "Luis",
      lastName: "Zambrano",
      nickName: null,
      yearBorn: 1999,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 1 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      id: 4,

      firstName: "Beto",
      lastName: "Gonzalez",
      nickName: null,
      yearBorn: 2002,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 1 + 1 + 1 + 1,
      goalsScored: 3,
      foulsCommited: 1,
      yellowCards: 0,
      redCards: 0,
    },

    {
      id: 5,

      firstName: "Octavio",
      lastName: "Carbajal",
      nickName: null,
      yearBorn: 2006,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 1 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      id: 6,

      firstName: "Israel",
      lastName: "Sarabia",
      nickName: null,
      yearBorn: 1996,
      number: null,
      image: null,

      // Stats
      matchesPlayed: 1 + 1 + 1 + 1,
      goalsScored: 1,
      foulsCommited: 2,
      yellowCards: 2,
      redCards: 0,
    },

    {
      id: 7,

      firstName: "Fausto",
      lastName: "Murillo",
      nickName: null,
      yearBorn: 2000,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 1 + 1 + 1 + 1,
      goalsScored: 4 + 5,
      foulsCommited: 0,
      yellowCards: 2,
      redCards: 0,
    },

    {
      id: 8,

      firstName: "Jonathan",
      lastName: "Stubbs",
      nickName: null,
      yearBorn: 2001,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 1 + 1 + 1 + 1,
      goalsScored: 2,
      foulsCommited: 2,
      yellowCards: 0,
      redCards: 0,
    },

    {
      id: 9,

      firstName: "Carlos",
      lastName: "Carrasco",
      nickName: "Charly",
      yearBorn: 2006,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 1 + 1 + 1 + 1,
      goalsScored: 1,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      id: 9,

      firstName: "Ulises",
      lastName: "",
      nickName: "",
      yearBorn: 2009,
      image: null,
      isCaptain: false,

      // Stats
      matchesPlayed: 0 + 1 + 0 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },
  ],
};
