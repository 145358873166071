import axios from "axios";

import {
  TOP_SCORERS_LIST_REQUEST,
  TOP_SCORERS_LIST_SUCCESS,
  TOP_SCORERS_LIST_FAIL,
} from "../constants/playersConstants";

export const listTopPlayers =
  (tournament_URL_name, stat_category) => async (dispatch) => {
    try {
      dispatch({ type: TOP_SCORERS_LIST_REQUEST });

      const { data } = await axios.get(
        `/api/tournaments/${tournament_URL_name}/stats/${stat_category}`
      );

      dispatch({
        type: TOP_SCORERS_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TOP_SCORERS_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
