// Owner: Aaron Stubbs

export const Team__Cerveceros_MAIN = {
  name: "Cerveceros",
  logoImage: null,
  teamImage: null,
  teamLogoColor: null,
  teamJerseyColor: "#E6DE0B",

  // Stats
  gamesWon: 2,
  gamesLost: 1,
  gamesTied: 0,
  gamesTiedWon: 0,

  ownGoalsInFavor: 0,
  // goalsScoredAgainst: 38 + 23 + 34,
  goalsScoredAgainst: 38 + 34,
  defaultGoals: 0,

  isChampion: false,
  isEliminated: false,

  players: [
    {
      // goalsScored: 32 + 54 + 37,
      goalsScored: 32 + 37,
    },
  ],
};
