// Owner: Aaron Stubbs

export const Team__Potros = {
  name: "Potros",
  logoImage: null,
  teamImage: null,
  teamLogoColor: null,
  teamJerseyColor: "#330101",

  // Stats
  gamesWon: 2,
  gamesLost: 2,
  gamesTied: 0,
  gamesTiedWon: 0,

  ownGoalsInFavor: 0,
  // goalsScoredAgainst: 37 + 34 + 27 + 47,
  goalsScoredAgainst: 34 + 27,
  defaultGoals: 0,

  isChampion: false,
  isEliminated: false,

  players: [
    {
      // goalsScored: 41 + 32 + 43 + 34,
      goalsScored: 32 + 43,
    },
  ],
};
