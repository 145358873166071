import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { listTournaments } from "../redux/actions/tournamentActions";

import "../styles/pages/HomePage.scss";

import TournamentCard from "../components/Cards/TournamentCard";

import { tournaments_data } from "../__FakeDatabase/Tournaments_data";
import NavigationComponents from "../components/NavigationComponents/Main Pages/NavigationComponents";
import { basketball_tournaments_data } from "../__fakeBasketballDatabase/Tournaments_data";

function HomePage() {
  const dispatch = useDispatch();
  const { error, loading, tournaments } = useSelector(
    (state) => state.tournamentList
  );

  const [tournamentsList, setTournamentsList] = useState([]);

  useEffect(() => {
    dispatch(listTournaments());
  }, [dispatch]);

  useEffect(() => {
    document.title = `Score Board Host`;
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!tournaments) return;

    setTournamentsList(tournaments);
  }, [tournaments]);

  return (
    <NavigationComponents>
      <div className="homePage">
        <div className="tournamentCardsSection">
          <div className="tournamentsContainerWrapper">
            <div className="tournamentsContainer">
              {tournaments_data?.map((tournament, index) => (
                <TournamentCard tournament={tournament} key={index} />
              ))}
              {tournamentsList?.map((tournament, index) => (
                <TournamentCard tournament={tournament} key={index} />
              ))}
              {basketball_tournaments_data?.map((tournament, index) => (
                <TournamentCard
                  tournament={tournament}
                  key={index}
                  isBasketball={true}
                />
              ))}
            </div>
          </div>
        </div>

        <div className="promoteMySelf__mainPages">
          Este sitio web fue creado por Martín Arreola Jr.
          <div className="socialMediaSections">
            <a
              href="https://youtube.com/@ThatDudeMartin?si=B68VnkCVJIgbN8wL"
              className="socialLink"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa-brands fa-youtube"></i>
            </a>
            {/* <a
              href="https://instagram.com/that_dude_martin?igshid=NzZlODBkYWE4Ng=="
              className="socialLink"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa-brands fa-instagram homeInstaIcon"></i>
            </a> */}
            <a
              href="https://api.whatsapp.com/send/?phone=%2B526361104576"
              className="socialLink"
              target="_blank"
              rel="noreferrer"
            >
              <i class="fa-brands fa-whatsapp"></i>
            </a>
          </div>
        </div>
      </div>
    </NavigationComponents>
  );
}

export default HomePage;
