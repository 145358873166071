// Owner: Luis "Grillo" Angel

export const Team__LeonesFC = {
  name: "Leones FC",
  logoImage: null,
  teamImage: null,
  teamLogoColor: "#ffffff",
  teamJerseyColor: "#ffffff",

  // Stats
  gamesWon: 2,
  gamesLost: 2,
  gamesTied: 0,
  gamesTiedWon: 0,

  ownGoalsInFavor: 0,
  goalsScoredAgainst: 10 + 8,
  defaultGoals: 2 + 2,

  isChampion: false,
  isEliminated: false,

  players: [
    {
      firstName: "Luis",
      lastName: "Cardenas",
      nickName: "Grillo",
      yearBorn: 2005,
      number: null,

      // Stats
      matchesPlayed: 1 + 1 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Marcelino",
      lastName: "Macario",
      nickName: "Machis",
      yearBorn: 1992,
      number: null,

      // Stats
      matchesPlayed: 1 + 1 + 1 + 1,
      goalsScored: 1,
      foulsCommited: 2 + 4,
      yellowCards: 1,
      redCards: 0,
    },

    {
      firstName: "David",
      lastName: "Hernández",
      nickName: null,
      yearBorn: 2008,
      number: null,

      // Stats
      matchesPlayed: 1 + 1 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 1,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Deibin",
      lastName: "Gonzalez",
      nickName: null,
      yearBorn: null,
      number: null,

      // Stats
      matchesPlayed: 1 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Brian",
      lastName: "Favela",
      nickName: "Chivo",
      yearBorn: 2007,
      number: null,

      // Stats
      matchesPlayed: 1 + 1 + 1 + 1,
      goalsScored: 1,
      foulsCommited: 1 + 1,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Diego",
      lastName: "Polanco",
      nickName: null,
      yearBorn: 2003,
      number: null,

      // Stats
      matchesPlayed: 1 + 1 + 1 + 1,
      goalsScored: 1,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Gustavo",
      lastName: "Xalate",
      nickName: "Tavo",
      yearBorn: 2005,
      number: null,

      // Stats
      matchesPlayed: 1 + 1 + 1 + 1,
      goalsScored: 5,
      foulsCommited: 1,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Marcos",
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,

      // Stats
      matchesPlayed: 0 + 0 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    {
      firstName: "Abraham",
      lastName: "Gutiérrez",
      nickName: null,
      yearBorn: 1996,
      number: null,

      // Stats
      matchesPlayed: 0 + 0 + 1 + 1,
      goalsScored: 0,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },

    // ===========================================
    // ========= Non Registered Players ==========
    // ===========================================

    {
      firstName: "Ramon",
      lastName: "",
      nickName: null,
      yearBorn: null,
      number: null,

      // Stats
      matchesPlayed: 0 + 1 + 1 + 1,
      goalsScored: 1,
      foulsCommited: 0,
      yellowCards: 0,
      redCards: 0,
    },
  ],
};
