import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { listTournamentDetails } from "../../../redux/actions/tournamentActions";

// scss
import "../../../styles/components/NavigationComponents/Tournament/TournamentNavigationComponents.scss";

// Components
import MobileFooter from "./MobileFooter";
import SmallSidebar from "./SmallSidebar";
import TournamentNavbar from "../../TournamentNavbar";
import LargeSidebar from "./LargeSidebar";

function TournamentNavigationComponents(props) {
  const tournamentParams = useParams();
  const dispatch = useDispatch();

  const tournamentDetails = useSelector((state) => state.tournamentDetails);
  const { loading_tournament, error_tournament, tournament } =
    tournamentDetails;

  let tournamentURL_name = "";

  useEffect(() => {
    if (tournament != null) {
      tournamentURL_name = tournament.URL_name;
    } else if (props.tournament) {
    }
  }, []);

  useEffect(() => {
    // if (props.tournament.isLocalData) return null;
    dispatch(listTournamentDetails(tournamentParams.id));
  }, []);

  const currentPage = window.location.href;

  const localhost = `http://localhost:3000/#/torneo/${props.tournament.URL_name}`;
  const networkServer = `http://192.168.1.236:3000/#/torneo/${props.tournament.URL_name}`;
  const livePageUrl = `https://scoreboardhost.netlify.app/#/torneo/${props.tournament.URL_name}`;
  const demoPageUrl = `https://scoreboardhost-demo.netlify.app/#/torneo/${props.tournament.URL_name}`;

  const currentPageUrl = (pageUrl) => {
    if (currentPage === `${localhost}${pageUrl}`) {
      return "activePageLink";
    } else if (currentPage === `${networkServer}${pageUrl}`) {
      return "activePageLink";
    } else if (currentPage === `${livePageUrl}${pageUrl}`) {
      return "activePageLink";
    } else {
      return "pageLink";
    }
  };

  return (
    <>
      <TournamentNavbar tournament={tournament} />
      <div className="smallSidebarContainer">
        <SmallSidebar
          tournament={props.tournament}
          currentPageUrl={currentPageUrl}
        />
      </div>
      <div className="largeSidebarContainer">
        <LargeSidebar tournament={tournament} currentPageUrl={currentPageUrl} />
      </div>
      <div className="thePage">{props.children}</div>
      <div className="mobileFooterContainer">
        <MobileFooter tournament={tournament} currentPageUrl={currentPageUrl} />
      </div>
    </>
  );
}

export default TournamentNavigationComponents;
