// Owner: Aaron Stubbs

export const Team__Llamas = {
  name: "Llamas",
  logoImage: null,
  teamImage: null,
  teamLogoColor: null,
  teamJerseyColor: null,

  // Stats
  gamesWon: 1 + 1,
  gamesLost: 1 + 1,
  gamesTied: 0,
  gamesTiedWon: 0,

  ownGoalsInFavor: 0,
  goalsScoredAgainst: 33 + 29 + 16 + 18,
  defaultGoals: 0,

  isChampion: false,
  isEliminated: false,

  players: [
    {
      goalsScored: 22 + 21 + 41 + 42,
    },
  ],
};
