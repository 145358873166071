export const TOURNAMENT_LIST_REQUEST = "TOURNAMENT_LIST_REQUEST";
export const TOURNAMENT_LIST_SUCCESS = "TOURNAMENT_LIST_SUCCESS";
export const TOURNAMENT_LIST_FAIL = "TOURNAMENT_LIST_FAIL";

export const MY_TOURNAMENTS_LIST_REQUEST = "MY_TOURNAMENTS_LIST_REQUEST";
export const MY_TOURNAMENTS_LIST_SUCCESS = "MY_TOURNAMENTS_LIST_SUCCESS";
export const MY_TOURNAMENTS_LIST_FAIL = "MY_TOURNAMENTS_LIST_FAIL";

export const TOURNAMENT_DETAILS_REQUEST = "TOURNAMENT_DETAILS_REQUEST";
export const TOURNAMENT_DETAILS_SUCCESS = "TOURNAMENT_DETAILS_SUCCESS";
export const TOURNAMENT_DETAILS_FAIL = "TOURNAMENT_DETAILS_FAIL";
