import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// scss
import "../../styles/components/TournamentNavbar.scss";

function BetaTournamentNavbar({ tournament }) {
  const [sidebarActive, setSidebarActive] = useState(false);

  document.body.style = "background: #f0f0f0;";

  useEffect(() => {
    document.title = `${tournament?.name}`;
    window.scrollTo(0, 0);
  }, [tournament]);

  const links = [
    {
      url: "/",
      name: "Torneos",
      icon: <span class="material-symbols-rounded">trophy</span>,
    },
    {
      url: "https://deportesarreola.netlify.app/",
      name: "Comprar",
      icon: <span class="material-symbols-rounded">shopping_bag</span>,
    },
    // {
    //   url: "",
    //   name: "Organizar Torneos",
    //   icon: <span class="material-symbols-rounded">inventory</span>,
    // },
    // {
    //   url: "",
    //   name: "Notificaciones",
    //   icon: <span class="material-symbols-rounded">notifications</span>,
    // },
  ];

  return (
    <div
      className={`tournamentNavbar tournamentNavbar__${tournament?.pageColorVersion}`}
    >
      <Link to={`/`} className="hostLogoContainer">
        <img
          src={
            tournament?.hostLogoImage
              ? `${tournament.hostLogoImage}`
              : "/GreyMTransparent.png"
          }
          alt="Logo"
          draggable="false"
          className="navbarHostLogo"
        />
      </Link>

      <div className="linksContainer">
        <Link to={`/`} className="pageLink">
          <span class="material-symbols-rounded">trophy</span>

          <span className="linkText">Mas Torneos</span>
        </Link>

        <Link to={`/comprar`} className="pageLink">
          <span class="material-symbols-rounded">local_mall</span>

          <span className="linkText">Comprar</span>
        </Link>
      </div>

      <div className="dropDownBtnContainer">
        <span
          class="material-symbols-rounded"
          onClick={() => setSidebarActive(true)}
        >
          menu
        </span>
      </div>

      <div
        className={`sidebarContainer  sidebarContainer__${
          sidebarActive ? "active" : "disabled"
        } transparentWhenPageLoads`}
      >
        <div className="sidebar">
          <div className="sidebarTitleContainer">Score Board Host</div>

          <div className="sidebarLinksContainer">
            {links.map((link, index) => (
              <Link to={link.url} className="sidebarLink" key={index}>
                {link.icon}
                {link.name}
              </Link>
            ))}
          </div>
        </div>

        <div
          className="background"
          onClick={() => setSidebarActive(false)}
        ></div>
      </div>
    </div>
  );
}

export default BetaTournamentNavbar;
