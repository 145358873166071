export const TEAMS_LIST_REQUEST = "TEAMS_LIST_REQUEST";
export const TEAMS_LIST_SUCCESS = "TEAMS_LIST_SUCCESS";
export const TEAMS_LIST_FAIL = "TEAMS_LIST_FAIL";

export const TEAM_DETAILS_REQUEST = "TEAM_DETAILS_REQUEST";
export const TEAM_DETAILS_SUCCESS = "TEAM_DETAILS_SUCCESS";
export const TEAM_DETAILS_FAIL = "TEAM_DETAILS_FAIL";

export const TEAM_PLAYERS_LIST_REQUEST = "TEAM_PLAYERS_LIST_REQUEST";
export const TEAM_PLAYERS_LIST_SUCCESS = "TEAM_PLAYERS_LIST_SUCCESS";
export const TEAM_PLAYERS_LIST_FAIL = "TEAM_PLAYERS_LIST_FAIL";
