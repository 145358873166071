import { Team__Adrianes } from "../teams/Adrianes";
import { Team__Cerveceros } from "../teams/Cerveceros";
import { Team__Joeles } from "../teams/Joeles";
import { Team__JohnsonBranders } from "../teams/JohnsonBranders";
import { Team__Llamas } from "../teams/Llamas";
import { Team__Natalias } from "../teams/Natalias";
import { Team__PecanBros } from "../teams/PecanBros";
import { Team__Potros } from "../teams/Potros";
import { Team__TheTakeOvers } from "../teams/TheTakeOvers";
import { Team__Thunder } from "../teams/Thunder";

const Adrianes = {
  team: Team__Adrianes,
  playingPlayers: [],

  goals: 34,
  penaltyGoals: 0,
  isWinner: false,
};

const Cerveceros = {
  team: Team__Cerveceros,
  playingPlayers: [],

  goals: 21,
  penaltyGoals: 0,
  isWinner: false,
};

const Joeles = {
  team: Team__Joeles,
  playingPlayers: [],

  goals: 29,
  penaltyGoals: 0,
  isWinner: false,
};

const JohnsonBranders = {
  team: Team__JohnsonBranders,
  playingPlayers: [],

  goals: 37,
  penaltyGoals: 0,
  isWinner: false,
};

const Natalias = {
  team: Team__Natalias,
  playingPlayers: [],

  goals: 23,
  penaltyGoals: 0,
  isWinner: false,
};

const Thunder = {
  team: Team__Thunder,
  playingPlayers: [],

  goals: 50,
  penaltyGoals: 0,
  isWinner: false,
};

const Llamas = {
  team: Team__Llamas,
  playingPlayers: [],

  goals: 22,
  penaltyGoals: 0,
  isWinner: false,
};

const PecanBros = {
  team: Team__PecanBros,
  playingPlayers: [],

  goals: 33,
  penaltyGoals: 0,
  isWinner: false,
};

const TheTakeOvers = {
  team: Team__TheTakeOvers,
  playingPlayers: [],

  goals: 20,
  penaltyGoals: 0,
  isWinner: false,
};

const Potros = {
  team: Team__Potros,
  playingPlayers: [],

  goals: 0,
  penaltyGoals: 0,
  isWinner: false,
};

// =====================================================
// ======================== Match 2 ====================
// =====================================================

const M2__Cerveceros = {
  team: Team__Cerveceros,
  playingPlayers: [],

  goals: 31,
  penaltyGoals: 0,
  isWinner: false,
};

const M2__Joeles = {
  team: Team__Joeles,
  playingPlayers: [],

  goals: 37,
  penaltyGoals: 0,
  isWinner: false,
};

const M2__JohnsonBranders = {
  team: Team__JohnsonBranders,
  playingPlayers: [],

  goals: 25,
  penaltyGoals: 0,
  isWinner: false,
};

const M2__TheTakeOvers = {
  team: Team__TheTakeOvers,
  playingPlayers: [],

  goals: 30,
  penaltyGoals: 0,
  isWinner: false,
};

const M2__Potros = {
  team: Team__Potros,
  playingPlayers: [],

  goals: 41,
  penaltyGoals: 0,
  isWinner: false,
};

export const Jornada1 = {
  jornadaPosition: 1,
  matches: [
    {
      team1: Cerveceros,
      team2: JohnsonBranders,

      isCompleted: true,
      isPlaying: false,
      day: "Lunes",
      date: "2024-07-29",
      time: "10:00:00",
      location: "Centro Recreativo Lebaron",
    },

    {
      team1: Natalias,
      team2: Adrianes,

      isCompleted: true,
      isPlaying: false,
      day: "Lunes",
      date: "2024-07-29",
      time: "11:00:00",
      location: "Centro Recreativo Lebaron",
    },

    {
      team1: Thunder,
      team2: Joeles,

      isCompleted: true,
      isPlaying: false,
      day: "Lunes",
      date: "2024-07-29",
      time: "12:00:00",
      location: "Centro Recreativo Lebaron",
    },

    {
      team1: Llamas,
      team2: PecanBros,

      isCompleted: true,
      isPlaying: false,
      day: "Lunes",
      date: "2024-07-29",
      time: "13:00:00",
      location: "Centro Recreativo Lebaron",
    },

    {
      team1: M2__JohnsonBranders,
      team2: TheTakeOvers,

      isCompleted: true,
      isPlaying: false,
      day: "Lunes",
      date: "2024-07-29",
      time: "14:00:00",
      location: "Centro Recreativo Lebaron",
    },

    // =====================================================
    // ======================== Match 2 ====================
    // =====================================================

    {
      team1: M2__Potros,
      team2: M2__Joeles,

      isCompleted: true,
      isPlaying: false,
      day: "Lunes",
      date: "2024-07-29",
      time: "15:00:00",
      location: "Centro Recreativo Lebaron",
    },

    {
      team1: M2__Cerveceros,
      team2: M2__TheTakeOvers,

      isCompleted: true,
      isPlaying: false,
      day: "Lunes",
      date: "2024-07-29",
      time: "16:00:00",
      location: "Centro Recreativo Lebaron",
    },
  ],
};
