import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import "../../styles/pages/AuthenticationPages/LoginPage.scss";

import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/actions/userActions";

function LoginPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const redirect = location.search ? location.search.split("=")[1] : "/";

  const userLogin = useSelector((state) => state.userLogin);
  const { error, message, userInfo } = userLogin;

  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
  }, [navigate, userInfo, redirect]);

  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(login(username, password));
  };

  return (
    <div className="loginPage">
      <h1>Login</h1>

      {message && <h1>{message}</h1>}
      {error && <h1>{error}</h1>}

      <form action="post" className="loginForm" onSubmit={submitHandler}>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />

        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <div className="submitBtnContainer">
          <button className="submitBtn" type="submit">
            Sign Up
          </button>
        </div>
      </form>

      <div className="dontHaveAccountContainer">
        Dont have an account?{" "}
        <Link
          to={redirect ? `/create-account?redirect=${redirect}` : "/register"}
        >
          Create Account Here
        </Link>
      </div>
    </div>
  );
}

export default LoginPage;
