import { Team__Nogaleras } from "../teams/Nogaleras";
import { Team__NogalerasJR } from "../teams/NogalerasJR";
import { Team__Panthers } from "../teams/Panthers";
import { Team__Raptors } from "../teams/Raptors";

const Panthers = {
  team: Team__Panthers,
  playingPlayers: [],

  goals: 23,
  penaltyGoals: 0,
  isWinner: false,
};

const NogalerasJR = {
  team: Team__NogalerasJR,
  playingPlayers: [],

  goals: 22,
  penaltyGoals: 0,
  isWinner: false,
};

const Raptors = {
  team: Team__Raptors,
  playingPlayers: [],

  goals: 20,
  penaltyGoals: 0,
  isWinner: false,
};

const Nogaleras = {
  team: Team__Nogaleras,
  playingPlayers: [],

  goals: 34,
  penaltyGoals: 0,
  isWinner: false,
};

export const Jornada2 = {
  jornadaPosition: 2,
  matches: [
    {
      team1: Panthers,
      team2: Raptors,

      isCompleted: true,
      isPlaying: false,
      day: "Martes",
      date: "2024-07-30",
      time: "09:00:00",
      location: "Centro Recreativo Lebaron",
    },
    {
      team1: Nogaleras,
      team2: NogalerasJR,

      isCompleted: true,
      isPlaying: false,
      day: "Martes",
      date: "2024-07-30",
      time: "10:00:00",
      location: "Centro Recreativo Lebaron",
    },
  ],
};
