import { Team__Leonas } from "./teams/Leonas";
import { Team__Pumbas } from "./teams/Pumbas";
import { Team__Valleras } from "./teams/Valleras";
import { Team__Vaqueras } from "./teams/Vaqueras";

const Vaqueras = {
  team: Team__Vaqueras,
  playingPlayers: [
    {
      player: {
        firstName: "Elisa",
        lastName: "Erives",
        nickName: null,
        yearBorn: 2008,
        number: null,
      },

      goals: 0,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Iris",
        lastName: "Macario",
        nickName: null,
        yearBorn: 2008,
      },

      goals: 1,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Mayte",
        lastName: "Xalate",
        nickName: null,
        yearBorn: 2009,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Fatima",
        lastName: "Miramontes",
        nickName: null,
        yearBorn: 2009,
        number: null,
      },

      goals: 1,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Gabriela",
        lastName: "Rosario",
        nickName: "Gaby",
        yearBorn: 1991,
      },

      goals: 1,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Fernanda",
        lastName: "Núñez",
        nickName: null,
        yearBorn: 2008,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Elena",
        lastName: "Lujan",
        nickName: null,
        yearBorn: 2010,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Lucy",
        lastName: "Xalate",
        nickName: null,
        yearBorn: 2007,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 3,
  penaltyGoals: 0,
  isWinner: false,
};

const Leonas = {
  team: Team__Leonas,
  playingPlayers: [
    {
      player: {
        firstName: "Julia",
        lastName: "Carbajal", // Romero
        nickName: null,
        yearBorn: 2007,
        number: null,
      },

      goals: 1,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Julissa",
        lastName: "Arreola",
        nickName: null,
        yearBorn: 2007,
      },

      goals: 2,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Johana",
        lastName: "Murillo",
        nickName: null,
        yearBorn: 2002,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Reyna",
        lastName: "Macario",
        nickName: null,
        yearBorn: 1990,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Miranda",
        lastName: "Rodríguez",
        nickName: null,
        yearBorn: 2011,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Jaqueline",
        lastName: "Arreola",
        nickName: "Jacky",
        yearBorn: 2010,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Citlali",
        lastName: "Hernández",
        nickName: null,
        yearBorn: 2007,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Elisa",
        lastName: "",
        nickName: "",
        yearBorn: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Jessica",
        lastName: "",
        nickName: "",
        yearBorn: null,
        number: null,
      },

      goals: 1,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 4,
  penaltyGoals: 0,
  isWinner: false,
};

const Pumbas = {
  team: Team__Pumbas,
  playingPlayers: [
    {
      player: {
        firstName: "Adri",
        lastName: "",
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Karina",
        lastName: "",
      },

      goals: 1,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Veronica",
        lastName: "",
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Yiomara",
        lastName: "",
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Gaby",
        lastName: "",
      },

      goals: 4,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Magaly",
        lastName: "",
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Denise",
        lastName: "",
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Karime",
        lastName: "Dominguez",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 5,
  penaltyGoals: 0,
  isWinner: false,
};

const Valleras = {
  team: Team__Valleras,
  playingPlayers: [
    {
      player: {
        firstName: "Miriam",
        lastName: "Serrano",
        nickName: null,
        yearBorn: null,
        number: 1,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Claudia",
        lastName: "Marquez",
        nickName: null,
        yearBorn: 1984,
        number: 9,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Luisa", // Fernanda
        lastName: "Salas",
        nickName: null,
        yearBorn: 2006,
        number: 20,
      },

      goals: 0,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Maricela",
        lastName: "Rodríguez",
        nickName: null,
        yearBorn: 1987,
        number: 13,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Adriana",
        lastName: "Avaloz",
      },

      goals: 1,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Idali",
        lastName: "Córdova",
        nickName: null,
        yearBorn: null,
        number: 7,
      },

      goals: 0,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Leslie",
        lastName: "Vega", // Ochoa
        nickName: null,
        yearBorn: null,
        number: 11,
      },

      goals: 0,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Alexa",
        lastName: "Quezada",
        nickName: null,
        yearBorn: 2007,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Marce",
        lastName: "Chalaca",
        nickName: null,
        yearBorn: null,
        number: 19,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 1,
  penaltyGoals: 0,
  isWinner: false,
};

const FINALIST_1 = {
  team: Team__Vaqueras,
  playingPlayers: [
    {
      player: {
        firstName: "",
        lastName: "",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 0,
  penaltyGoals: 0,
  isWinner: false,
};

const FINALIST_2 = {
  team: Team__Pumbas,
  playingPlayers: [
    {
      player: {
        firstName: "",
        lastName: "",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 0,
  penaltyGoals: 0,
  isWinner: false,
};

const ThirdPlacement_1 = {
  team: Team__Valleras,
  playingPlayers: [
    {
      player: {
        firstName: "",
        lastName: "",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 0,
  penaltyGoals: 0,
  isWinner: false,
};

const ThirdPlacement_2 = {
  team: Team__Leonas,
  playingPlayers: [
    {
      player: {
        firstName: "",
        lastName: "",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 0,
  penaltyGoals: 0,
  isWinner: false,
};

export const playoffs_feminil = [
  {
    playoffName: "Semifinal",

    matches: [
      {
        team1: Vaqueras,
        team2: Valleras,

        isCompleted: true,
        isPlaying: false,
        day: "Jueves",
        date: "2024-08-15",
        time: "19:00:00",
        location: "Cancha Gerardo Gonzales",
      },
      {
        team1: Leonas,
        team2: Pumbas,

        isCompleted: true,
        isPlaying: false,
        day: "Jueves",
        date: "2024-08-15",
        time: "20:00:00",
        location: "Cancha Gerardo Gonzales",
      },
    ],
  },

  {
    playoffName: "Gran final",

    matches: [
      {
        matchName: "Gran final",

        team1: FINALIST_1,
        team2: FINALIST_2,

        isCompleted: false,
        isPlaying: false,
        day: "Sabado",
        date: "2024-08-17",
        time: "21:00:00",
        location: "Cancha Gerardo Gonzales",
      },

      {
        matchName: "Tercer lugar",

        team1: ThirdPlacement_1,
        team2: ThirdPlacement_2,

        isCompleted: false,
        isPlaying: false,
        day: "Sabado",
        date: "2024-08-17",
        time: "19:00:00",
        location: "Cancha Gerardo Gonzales",
      },
    ],
  },
];
