import React from "react";

import "../styles/components/PromoteMySelf.scss";

function PromoteMySelf() {
  return (
    <div className="promoteMySelf">
      Este sitio web fue creado por Martín Arreola Jr.
      <div className="socialMediaSections">
        <a
          href="https://youtube.com/@ThatDudeMartin?si=B68VnkCVJIgbN8wL"
          className="socialLink"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fa-brands fa-youtube"></i>
        </a>
        {/* <a
          href="https://instagram.com/that_dude_martin?igshid=NzZlODBkYWE4Ng=="
          className="socialLink"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fa-brands fa-instagram coloredInstaIcon"></i>
        </a> */}
        <a
          href="https://api.whatsapp.com/send/?phone=%2B526361104576"
          className="socialLink"
          target="_blank"
          rel="noreferrer"
        >
          <i class="fa-brands fa-whatsapp"></i>
        </a>
      </div>
    </div>
  );
}

export default PromoteMySelf;
