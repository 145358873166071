import { GroupA, GroupB, Groups } from "./varonil/GroupsList";
import { ActiveJornada, JornadaSchedule } from "./varonil/JornadaSchedule";
import { teamList } from "./varonil/TeamsList";

// ===============================================
import {
  ActiveJornada as ActiveJornada_F,
  JornadaSchedule as JornadaSchedule_F,
} from "./feminil/JornadaSchedule";
import { teamList as teamList_F } from "./feminil/TeamsList";
import { playoffs_varonil } from "./varonil/Playoffs";
import { playoffs_feminil } from "./feminil/Playoffs";

// ========================================================
// ==DDDDDD========AAAA=====TTTTTTTTTTTT========AAAA=======
// ==DD===DD======AA==AA=========TT============AA==AA======
// ==DD====DD=====A====A=========TT============A====A======
// ==DD=====DD===AA====AA========TT===========AA====AA=====
// ==DD=====DD===AAAAAAAA========TT===========AAAAAAAA=====
// ==DD====DD====A======A========TT===========A======A=====
// ==DD===DD====AA======AA=======TT==========AA======AA====
// ==DDDDDD=====AA======AA=======TT==========AA======AA====
// ========================================================

export const tournaments_data = [
  {
    // Info
    name: "Torneo corto de Futbol Rapido: Varonil",
    URL_name: "torneo-futbol-rapido-varonil",
    thumbnailImage: "/MessiRonaldo.jpg",
    // tournamentLogoImage: "/MunicipioLogo.jpg",
    tournamentLogoImage: "/MunicipioTransparent.png",
    heroSectionImage: "/Brazil-2014.webp",

    location: "Lagunitas",

    // Host
    host: "Municipio de Galeana",
    hostLogoImage: "/MunicipioLogo.jpg",

    // Config
    sex: "male",
    pageColorVersion: "white",

    tournamentType: "groups_and_playoff",
    teamsToSurviveGroup: 2,
    pointsPerWin: 3,
    pointsPerDraw: 1,
    pointsPerDrawWin: 1,
    pointsPerLoss: 0,

    isCompleted: false,
    isLocalData: true,

    teams: teamList,
    groups: Groups,
    jornadas: JornadaSchedule,
    activeJornadas: ActiveJornada,

    playoffs: playoffs_varonil,
  },
  {
    // Info
    name: "Torneo corto de Futbol Rapido: Feminil",
    URL_name: "torneo-futbol-rapido-feminil",
    thumbnailImage: "/LIGAMX_FEM.jpg",
    // tournamentLogoImage: "/MunicipioLogo.jpg",
    tournamentLogoImage: "/MunicipioTransparent.png",
    heroSectionImage: "/World_Cup_Oceaunz_final_match_ball_.webp",

    location: "Lagunitas",

    // Host
    host: "Municipio de Galeana",
    hostLogoImage: "/MunicipioLogo.jpg",

    // Config
    sex: "female",
    pageColorVersion: "purple",

    tournamentType: "season_and_playoff",
    teamsToSurviveGroup: 4,
    pointsPerWin: 3,
    pointsPerDraw: 1,
    pointsPerDrawWin: 1,
    pointsPerLoss: 0,

    isCompleted: false,
    isLocalData: true,

    teams: teamList_F,
    groups: teamList_F,
    jornadas: JornadaSchedule_F,
    activeJornadas: ActiveJornada_F,

    playoffs: playoffs_feminil,
  },
];
