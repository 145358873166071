import { Team__Nogaleras } from "../teams/Nogaleras";
import { Team__NogalerasJR } from "../teams/NogalerasJR";
import { Team__Panthers } from "../teams/Panthers";
import { Team__Raptors } from "../teams/Raptors";

const Panthers = {
  team: Team__Panthers,
  playingPlayers: [],

  goals: 25,
  penaltyGoals: 0,
  isWinner: false,
};

const NogalerasJR = {
  team: Team__NogalerasJR,
  playingPlayers: [],

  goals: 23,
  penaltyGoals: 0,
  isWinner: false,
};

const Raptors = {
  team: Team__Raptors,
  playingPlayers: [],

  goals: 15,
  penaltyGoals: 0,
  isWinner: false,
};

const Nogaleras = {
  team: Team__Nogaleras,
  playingPlayers: [],

  goals: 25,
  penaltyGoals: 0,
  isWinner: false,
};

const PLACEMENT_1 = {
  team: {
    name: "1er lugar",
    logoImage: null,
    teamImage: null,
    teamLogoColor: null,
    teamJerseyColor: "#ffffff",
  },
  playingPlayers: [],

  goals: 0,
  penaltyGoals: 0,
  isWinner: false,
};

const PLACEMENT_2 = {
  team: {
    name: "2do lugar",
    logoImage: null,
    teamImage: null,
    teamLogoColor: null,
    teamJerseyColor: "#ffffff",
  },
  playingPlayers: [],

  goals: 0,
  penaltyGoals: 0,
  isWinner: false,
};

const PLACEMENT_3 = {
  team: {
    name: "3er lugar",
    logoImage: null,
    teamImage: null,
    teamLogoColor: null,
    teamJerseyColor: "#ffffff",
  },
  playingPlayers: [],

  goals: 0,
  penaltyGoals: 0,
  isWinner: false,
};

const PLACEMENT_4 = {
  team: {
    name: "4to lugar",
    logoImage: null,
    teamImage: null,
    teamLogoColor: null,
    teamJerseyColor: "#ffffff",
  },
  playingPlayers: [],

  goals: 0,
  penaltyGoals: 0,
  isWinner: false,
};

export const Jornada5 = {
  jornadaPosition: 5,
  matches: [
    {
      team1: Nogaleras,
      team2: Raptors,

      isCompleted: true,
      isPlaying: false,
      day: "Viernes",
      date: "2024-08-02",
      time: "10:00:00",
      location: "Centro Recreativo Lebaron",
    },
    {
      team1: Panthers,
      team2: NogalerasJR,

      isCompleted: true,
      isPlaying: false,
      day: "Viernes",
      date: "2024-08-02",
      time: "11:00:00",
      location: "Centro Recreativo Lebaron",
    },
  ],
};
