import { Jornada1 } from "./Jornadas/Jornada_1";
import { Jornada2 } from "./Jornadas/Jornada_2";
import { Jornada3 } from "./Jornadas/Jornada_3";
import { Jornada4 } from "./Jornadas/Jornada_4";
import { Jornada5 } from "./Jornadas/Jornada_5";

export const ActiveJornada = [Jornada5];

export const JornadaSchedule = [
  Jornada1,
  Jornada2,
  Jornada3,
  Jornada4,
  Jornada5,
];
