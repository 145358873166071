import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

// scss
import "../../styles/pages/TournamentHomePage.scss";

// Components
import Bracket from "../../components/Bracket";
import TeamRankingsTable, {
  BetaGroupTable,
  BetaTeamRankingsTable,
} from "../../components/tables/TeamRankingsTable";

import WeekSchedule from "../../components/WeekSchedule";

import BetaTournamentNavigationComponents from "../../components/BetaComponents/Tournament/TournamentNavigationComponents";
import { basketball_tournaments_data } from "../../__fakeBasketballDatabase/Tournaments_data";
import { JornadaBox } from "../BetaPages/BetaSchedulePage";
import BasketballTournamentNavigationComponents from "../../components/BetaComponents/TournamentBasketball/TournamentNavigationComponents";
// import { JornadaBox } from "./BetaSchedulePage";

function getWindowDimensions() {
  const { innerWidth: screenWidth, innerHeight: screenHeight } = window;
  return {
    screenWidth,
    screenHeight,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

function BasketballTournamentHomePage() {
  const tournamentParams = useParams();
  const tournament = basketball_tournaments_data.find(
    (p) => p.URL_name === tournamentParams.id
  );

  const { screenHeight, screenWidth } = useWindowDimensions();

  // if (!tournament && !tournamentParams.isLocalData) {
  //   return (
  //     <>
  //       <h1 style={{ textAlign: "center" }}>Torneo No Encontrado</h1>
  //     </>
  //   );
  // }

  return (
    <>
      <BasketballTournamentNavigationComponents tournament={tournament}>
        <div className="tournamentHomePage">
          <div className="heroSectionAndTableContainer">
            <div
              className="heroSection"
              style={{
                backgroundImage: `${
                  tournament?.heroSectionImage
                    ? `url(${tournament?.heroSectionImage})`
                    : "none"
                }`,
              }}
            >
              {tournament?.heroSectionImage != null && (
                <div className="backgroundGradient"></div>
              )}
            </div>

            <div
              className="logoAndTableContainer"
              style={{ marginTop: -((screenHeight / 100) * 90) + 60 + 20 }}
            >
              <div className="logoAndNameContainer">
                <div className="logoAndName">
                  {tournament?.tournamentLogoImage && (
                    <div className="tournamentLogoContainer">
                      <img
                        src={tournament?.tournamentLogoImage}
                        alt=""
                        className="tournamentLogo"
                      />
                    </div>
                  )}
                  <div className="tournamentNameContainer">
                    {tournament?.name}
                  </div>
                </div>
              </div>

              <div className="tableTeamRankingsSection">
                {tournament.tournamentType === "season_and_playoff" ? (
                  <BetaTeamRankingsTable
                    tournament={tournament}
                    teams={tournament?.teams}
                  />
                ) : tournament.tournamentType === "groups_and_playoff" ? (
                  <>
                    {tournament.groups.map((group, index) => (
                      <BetaGroupTable
                        tournament={tournament}
                        group={group}
                        index={index}
                        key={index}
                        isBasketball={true}
                      />
                    ))}
                  </>
                ) : (
                  <BetaTeamRankingsTable
                    tournament={tournament}
                    teams={tournament?.teams}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="jornadaSchedule">
            {tournament.activeJornadas.map((jornada, index) => (
              <JornadaBox
                jornada={jornada}
                index={index}
                tournament={tournament}
                key={index}
                isBasketball={true}
              />
            ))}
          </div>
        </div>
      </BasketballTournamentNavigationComponents>

      {/* ======================================================= */}
      {/* ======================================================= */}
      {/* ======================================================= */}
      {/* ======================================================= */}
      {/* ======================================================= */}

      {/* <TournamentNavigationComponents tournament={tournament}>
        <div className="tournamentHomePage">
          {tournament.warnings ? (
            <div className="warningTextContainer">
              {tournament.warnings.map((warning, index) => (
                <div
                  className={`warningText warningText__${warning.color}`}
                  key={index}
                >
                  {warning.text}
                </div>
              ))}
            </div>
          ) : null}

          <div className="contentWrapper-mobile">
            {tournament.inBracketStages ? (
              <div className="finalsAndTableContainer">
                <div className="header">
                  <div
                    className={
                      headerPage === "bracket"
                        ? `activeOption activeOption__${tournament.color}`
                        : "option"
                    }
                    onClick={() => setHeaderPage("bracket")}
                  >
                    Finales
                  </div>
                  <div
                    className={
                      headerPage === "table"
                        ? `activeOption activeOption__${tournament.color}`
                        : "option"
                    }
                    onClick={() => setHeaderPage("table")}
                  >
                    Tabla
                  </div>
                </div>

                <>
                  {headerPage === "bracket" ? (
                    <Bracket tournament={tournament} />
                  ) : headerPage === "table" ? (
                    <>
                      <TeamRankingsTable tournament={tournament} />
                    </>
                  ) : null}
                </>
              </div>
            ) : (
              <div className="teamsTableContainer">
                <TeamRankingsTable tournament={tournament} />
              </div>
            )}

            <WeekSchedule tournament={tournament} slice={splitWeekMatches} />
            {splitWeekMatches === true ? (
              <Link
                to={`/torneo/${tournament.URL_name}/jornadas`}
                className={`pastMatchesLink pastMatchesLink__${tournament.color}`}
              >
                Partidos pasados
              </Link>
            ) : null}
          </div>

          <PromoteMySelf />
        </div>
      </TournamentNavigationComponents> */}
    </>
  );
}

export default BasketballTournamentHomePage;
