import { Team__Leonas } from "../teams/Leonas";
import { Team__Pumbas } from "../teams/Pumbas";
import { Team__SuperNovas } from "../teams/SuperNovas";
import { Team__Valleras } from "../teams/Valleras";
import { Team__Vaqueras } from "../teams/Vaqueras";

const Leonas = {
  team: Team__Leonas,
  playingPlayers: [
    {
      player: {
        firstName: null,
        lastName: null,
        nickName: null,
        image: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 0,
  penaltyGoals: 0,
  isWinner: false,
};

const Pumbas = {
  team: Team__Pumbas,
  playingPlayers: [
    {
      player: {
        firstName: "Paulina",
        lastName: "",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 1,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Karina",
        lastName: "",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Adri",
        lastName: "",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Gaby",
        lastName: "",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 1,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Dominguez",
        lastName: "",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Veronica",
        lastName: "",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Yiomara",
        lastName: "",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Denise",
        lastName: "",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 2,
  penaltyGoals: 0,
  isWinner: false,
};

const SuperNovas = {
  team: Team__SuperNovas,
  playingPlayers: [
    {
      player: {
        firstName: "Idania",
        lastName: "Palacio",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },

    {
      player: {
        firstName: "Brenda",
        lastName: "Acevedo",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Karla",
        lastName: "Angiano",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 1,
      fouls_commited: 2,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Lupita",
        lastName: "Mendoza",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Lupita",
        lastName: "Ontiveros",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 1,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Camila",
        lastName: "Erives",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Camila",
        lastName: "Zapata",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Diana",
        lastName: "Gonzalez",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 2,
  penaltyGoals: 0,
  isWinner: false,
};

const Valleras = {
  team: Team__Valleras,
  playingPlayers: [
    {
      player: {
        firstName: "Idali",
        lastName: "Córdova",
        nickName: null,
        yearBorn: null,
        number: 7,
      },

      goals: 1,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Marce",
        lastName: "Chalaca",
        nickName: null,
        yearBorn: null,
        number: 19,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Claudia",
        lastName: "Marquez",
        nickName: null,
        yearBorn: 1984,
        number: 9,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Maricela",
        lastName: "Rodríguez",
        nickName: null,
        yearBorn: 1987,
        number: 13,
      },

      goals: 0,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Leslie",
        lastName: "Vega", // Ochoa
        nickName: null,
        yearBorn: null,
        number: 11,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Alexa",
        lastName: "Quezada",
        nickName: null,
        yearBorn: 2007,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Anaricka",
        lastName: "Delgado",
        nickName: null,
        yearBorn: 1989,
        number: 4,
      },

      goals: 0,
      fouls_commited: 2,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Miriam",
        lastName: "Serrano",
        nickName: null,
        yearBorn: null,
        number: 1,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Adriana",
        lastName: "Avaloz",
        nickName: null,
        yearBorn: null,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 1,
  penaltyGoals: 0,
  isWinner: false,
};

const Vaqueras = {
  team: Team__Vaqueras,
  playingPlayers: [
    {
      player: {
        firstName: "Gabriela",
        lastName: "Rosario",
        nickName: "Gaby",
        yearBorn: 1991,
        number: null,
      },

      goals: 1,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Fernanda",
        lastName: "Núñez",
        nickName: null,
        yearBorn: 2008,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Lucy",
        lastName: "Xalate",
        nickName: null,
        yearBorn: 2007,
        number: null,
      },

      goals: 1,
      fouls_commited: 2,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Iris",
        lastName: "Macario",
        nickName: null,
        yearBorn: 2008,
        number: null,
      },

      goals: 0,
      fouls_commited: 1,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Elisa",
        lastName: "Erives",
        nickName: null,
        yearBorn: 2008,
        number: null,
      },

      goals: 0,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Fatima",
        lastName: "Miramontes",
        nickName: null,
        yearBorn: 2009,
        number: null,
      },

      goals: 3,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
    {
      player: {
        firstName: "Mayte",
        lastName: "Xalate",
        nickName: null,
        yearBorn: 2009,
        number: null,
      },

      goals: 3,
      fouls_commited: 0,
      yellow_card: 0,
      red_card: 0,
    },
  ],

  goals: 8,
  penaltyGoals: 0,
  isWinner: false,
};

export const Jornada3 = {
  jornadaPosition: 3,
  matches: [
    {
      team1: Vaqueras,
      team2: SuperNovas,

      isCompleted: true,
      isPlaying: false,
      day: "Jueves",
      date: "2024-07-25",
      time: "20:00:00",
      location: "Cancha Gerardo Gonzales",
    },
    {
      team1: Pumbas,
      team2: Valleras,

      isCompleted: true,
      isPlaying: false,
      day: "Viernes",
      date: "2024-07-26",
      time: "20:00:00",
      location: "Cancha Gerardo Gonzales",
    },
  ],
};
