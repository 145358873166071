import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

// scss
import "../../styles/pages/StatsPage.scss";

import { tournaments_data } from "../../__FakeDatabase/Tournaments_data";

// Images
import DefaultImage2 from "../../images/PlayerCards/_DefaultImage2.jpeg";

// Components
import PlayerStatsCard from "../../components/Cards/PlayerStatsCard";
import TeamLogoIcon from "../../components/TeamLogoIcon";
import PlayerStatRanking from "../../components/StatsComponents/PlayerStatRanking";
import BetaTournamentNavigationComponents from "../../components/BetaComponents/Tournament/TournamentNavigationComponents";

function BetaStatsPageTeams() {
  const tournamentParams = useParams();
  const tournament = tournaments_data.find(
    (p) => p.URL_name === tournamentParams.id
  );

  const teams__mostFoulsCommited = tournament.teams.sort((a, b) => {
    const aTotal = a.players.reduce(
      (total, player) => total + player.foulsCommited,
      0
    );

    const bTotal = b.players.reduce(
      (total, player) => total + player.foulsCommited,
      0
    );

    return bTotal - aTotal;
  });

  const [activePlayerStatOption, setActivePlayerStatOption] = useState({
    title: "Goles",
    value: "goalsScored",
  });

  const teamStatsOptions = [
    {
      title: "Goles",
      value: "goalsScored",
    },
    {
      title: "Goles Sub 18",
      value: "goals-u18",
    },
    {
      title: "Faltas",
      value: "foulsCommited",
    },
    {
      title: "Tarjetas Amarillas",
      value: "yellowCards",
    },
    {
      title: "Tarjetas Rojas",
      value: "redCards",
    },
  ];

  return (
    <BetaTournamentNavigationComponents tournament={tournament}>
      <div className={`statsPage statsPage__${tournament.pageColorVersion}`}>
        <div className="statsPageNavbarContainer">
          <div className="statsPageNavbar">
            <Link
              to={`/torneo-beta/${tournament.URL_name}/estadisticas`}
              className="statsPageNavbarLink "
            >
              Individual
            </Link>
            <Link
              to={`/torneo-beta/${tournament.URL_name}/estadisticas/equipos`}
              className="statsPageNavbarLink statsPageNavbarLink__active"
            >
              Equipos
            </Link>
          </div>
        </div>

        <div className="statOptionsChipbarContainer">
          <div className="statOptionsChipbar">
            {teamStatsOptions.map((option, index) => (
              <div
                className={`statOptionChip statOptionChip__${
                  activePlayerStatOption.value === option.value && "active"
                } `}
                key={index}
                onClick={() =>
                  setActivePlayerStatOption({
                    title: option.title,
                    value: option.value,
                    database_url: option.database_url,
                    playersList: option.playersList,
                  })
                }
              >
                {option.title}
              </div>
            ))}
          </div>
        </div>

        <div className="allPlayerStatsContainer">
          {activePlayerStatOption.value === "goalsScored" ? (
            <SortGoals
              tournament={tournament}
              activeStat={activePlayerStatOption}
            />
          ) : activePlayerStatOption.value === "goals-u18" ? (
            <SortU18Goals
              tournament={tournament}
              activeStat={activePlayerStatOption}
            />
          ) : activePlayerStatOption.value === "foulsCommited" ? (
            <SortFouls
              tournament={tournament}
              activeStat={activePlayerStatOption}
            />
          ) : activePlayerStatOption.value === "yellowCards" ? (
            <SortYellowCards
              tournament={tournament}
              activeStat={activePlayerStatOption}
            />
          ) : (
            activePlayerStatOption.value === "redCards" && (
              <SortRedCards
                tournament={tournament}
                activeStat={activePlayerStatOption}
              />
            )
          )}
        </div>
      </div>
    </BetaTournamentNavigationComponents>
  );
}

export default BetaStatsPageTeams;

function SortGoals({ tournament }) {
  const teams__mostGoals = tournament.teams.sort((a, b) => {
    const aTotalGoalsScored = a.players.reduce(
      (total, player) => total + player.goalsScored,
      0
    );

    const bTotalGoalsScored = b.players.reduce(
      (total, player) => total + player.goalsScored,
      0
    );

    return bTotalGoalsScored - aTotalGoalsScored;
  });

  return (
    <TopPlayersStatBox
      tournament={tournament}
      activeStat={teams__mostGoals}
      title="Goles"
      value={"goalsScored"}
    />
  );
}

function SortU18Goals({ tournament }) {
  const maxYearBorn = 18;

  const teams__mostU18Goals = tournament.teams.sort((a, b) => {
    const aTotal = a.players.reduce((total, player) => {
      if (new Date().getFullYear() - player.yearBorn <= maxYearBorn) {
        return total + player.goalsScored;
      }
      return total;
    }, 0);

    const bTotal = b.players.reduce((total, player) => {
      if (new Date().getFullYear() - player.yearBorn <= maxYearBorn) {
        return total + player.goalsScored;
      }
      return total;
    }, 0);

    return bTotal - aTotal;
  });

  return (
    <TopPlayersStatBox
      tournament={tournament}
      activeStat={teams__mostU18Goals}
      title="Goles"
      value={"goals"}
      isSub18={true}
    />
  );
}

function SortFouls({ tournament }) {
  const teams__mostGoals = tournament.teams.sort((a, b) => {
    const aTotal = a.players.reduce(
      (total, player) => total + player.foulsCommited,
      0
    );

    const bTotal = b.players.reduce(
      (total, player) => total + player.foulsCommited,
      0
    );

    return bTotal - aTotal;
  });

  return (
    <TopPlayersStatBox
      tournament={tournament}
      activeStat={teams__mostGoals}
      title="Faltas"
      value={"foulsCommited"}
    />
  );
}

function SortYellowCards({ tournament }) {
  const teams__mostGoals = tournament.teams.sort((a, b) => {
    const aTotal = a.players.reduce(
      (total, player) => total + player.yellowCards,
      0
    );

    const bTotal = b.players.reduce(
      (total, player) => total + player.yellowCards,
      0
    );

    return bTotal - aTotal;
  });

  return (
    <TopPlayersStatBox
      tournament={tournament}
      activeStat={teams__mostGoals}
      title="Tarjetas Amarillas"
      value={"yellowCards"}
    />
  );
}

function SortRedCards({ tournament }) {
  const teams__mostGoals = tournament.teams.sort((a, b) => {
    const aTotal = a.players.reduce(
      (total, player) => total + player.redCards,
      0
    );

    const bTotal = b.players.reduce(
      (total, player) => total + player.redCards,
      0
    );

    return bTotal - aTotal;
  });

  return (
    <TopPlayersStatBox
      tournament={tournament}
      activeStat={teams__mostGoals}
      title="Tarjetas Rojas"
      value={"redCards"}
    />
  );
}

function TopPlayersStatBox({ activeStat, title, value, isSub18 }) {
  function teamTotalPoints(team) {
    if (isSub18) {
      const i = team.players.reduce((total, player) => {
        if (new Date().getFullYear() - player.yearBorn <= 18) {
          return total + player.goalsScored;
        }
        return total;
      }, 0);

      return i;
    } else {
      const i = team.players.reduce(
        (total, player) => total + player[value],
        0
      );
      return i;
    }
  }

  const PlayerPodium = ({ index }) => {
    return (
      <div className="playerPodium teamPodium">
        <div className="playerImgContainer">
          <TeamLogoIcon
            team={activeStat[index]}
            iconSize={"90%"}
            fillSpace={true}
            letterSize={25}
          />
        </div>

        <div className="statsSection">
          <div className="teamName">{activeStat[index].name}</div>

          <div className="playerPointsContainer">
            <div className="playerPoints">
              {teamTotalPoints(activeStat[index])}
            </div>
            <div className="statTitleContainer">{title}</div>
          </div>

          <div className="teamPlacement">#{index + 1}</div>
        </div>
      </div>
    );
  };

  return (
    <div className="statsPagePlayerRanking">
      <div className="statsSectionWrapper">
        <div className="statsSection">
          <div className="podiumContainer">
            {activeStat.length > 0 && (
              <>
                <PlayerPodium index={1} />
                <PlayerPodium index={0} />
                <PlayerPodium index={2} />
              </>
            )}
          </div>

          {activeStat.slice(3).map((team, index) => {
            return (
              <div className="topPlayerCard teamStatsCard" key={index}>
                <div className="placementBanner">
                  <div className="background"></div>
                  <div className="placement">{index + 4}</div>
                </div>
                <div className="playerInfoSide">
                  <div className="imgContainer">
                    <TeamLogoIcon team={team} iconSize={60} letterSize={25} />
                  </div>

                  <div className="teamName">{team.name}</div>
                </div>
                <div className="playerPointsContainer">
                  <div className="playerPoints">{teamTotalPoints(team)}</div>
                  <div className="statTitleContainer">{title}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
