import {
  TOURNAMENT_LIST_REQUEST,
  TOURNAMENT_LIST_SUCCESS,
  TOURNAMENT_LIST_FAIL,
  //
  TOURNAMENT_DETAILS_REQUEST,
  TOURNAMENT_DETAILS_SUCCESS,
  TOURNAMENT_DETAILS_FAIL,
  //
  MY_TOURNAMENTS_LIST_REQUEST,
  MY_TOURNAMENTS_LIST_SUCCESS,
  MY_TOURNAMENTS_LIST_FAIL,
} from "../constants/tournamentConstants";

export const tournamentListReducer = (state = { tournaments: [] }, action) => {
  switch (action.type) {
    case TOURNAMENT_LIST_REQUEST:
      return { loading: true, tournaments: [] };

    case TOURNAMENT_LIST_SUCCESS:
      return { loading: false, tournaments: action.payload };

    case TOURNAMENT_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const tournamentDetailsReducer = (
  state = { tournament: {} },
  action
) => {
  switch (action.type) {
    case TOURNAMENT_DETAILS_REQUEST:
      return { loading: true, ...state };

    case TOURNAMENT_DETAILS_SUCCESS:
      return { loading: false, tournament: action.payload };

    case TOURNAMENT_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const myTournamentsListReducer = (
  state = { tournaments: [] },
  action
) => {
  switch (action.type) {
    case MY_TOURNAMENTS_LIST_REQUEST:
      return { loadingMyTournaments: true };

    case MY_TOURNAMENTS_LIST_SUCCESS:
      return { loadingMyTournaments: false, myTournaments: action.payload };

    case MY_TOURNAMENTS_LIST_FAIL:
      return {
        loadingMyTournaments: false,
        errorMyTournaments: action.payload,
      };

    default:
      return state;
  }
};
